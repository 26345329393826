<template>
  <div>
    <div class="privacy-container">
      <div class="privacy-content">
        <div class="col-sm-12 col-md-12 privacy-container">
          Risk Adjustment Toolkit Add-in for Excel never collects, stores, or
          transmits any information neither in the client site nor in the server
          side.
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Footer from '../common/MainFooter.vue'
export default {
  components: {
    Footer: Footer,
  },
}
</script>

<style scoped>
.heading-box {
  background: #008c50;
  font-size: 24px;
  font-weight: 400;
  color: white;
  padding: 1px 1px 1px 20px;
  text-align: center;
}
li {
  margin: 10px;
}
.privacy-container {
  height: auto;
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: column;
  padding: 2%;
}
</style>
