<template>
  <v-layout>
    <div class="datepicker__input">
      <datepicker
        :value="dateOfBirthValue"
        placeholder="Select Date"
        format="d MMMM yyyy"
        @input="emitDob"
        input-class="calendar-input"
      ></datepicker>
    </div>
  </v-layout>
</template>

<script>
import Datepicker from 'vuejs-datepicker'
export default {
  components: {
    Datepicker,
  },
  props: {
    calculationYear: Number,
    dateOfBirthValue: String,
  },

  data() {
    return {
      dob: '',
      errorMessage: null,
    }
  },

  methods: {
    emitDob(dob) {
      let dateString = new Date(dob)
      let formattedDate = `${dateString.getDate()}/${dateString.getMonth() +
        1}/${dateString.getFullYear()}`
      let dateForMoment = `${dateString.getFullYear()}-${dateString.getMonth() +
        1}-${dateString.getDate()}`

      this.$emit('dateOfBirthUpdate', formattedDate, dateForMoment)
    },
  },
}
</script>
