<template>
  <div class="container">
    <div class="row">
      <div class="col-md-1 col-sm-1 col-1"></div>
      <div class="col-md-5 col-sm-5 col-5">
        <div class="style-404">404</div>
        <div class="page-text-style">
          The page you were looking for
          <b>doesn’t exist.</b>
        </div>
      </div>
      <div class="col-md-5 col-sm-5 col-5">
        <img class="image-404" src="../../assets/img/group-7@2x-1.png" />
      </div>
    </div>
  </div>
</template>
<style>
.image-404 {
  max-width: 90%;
  height: auto;
}
.style-404 {
  font-size: 150px;
  color: #008c50;
}
.button-style {
  text-decoration: none;
  background-color: #0099d9;
  color: #fff !important;
  font-size: 18px;
  padding: 17px 50px 15px;
}
.page-text-style {
  font-size: 36px;
  font-weight: 500;
  line-height: 1.5;
  padding-bottom: 25px;
  color: rgb(84, 84, 84);
}
</style>
<script>
export default {}
</script>
