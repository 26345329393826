import axios from 'axios'
const hoverAPI = '/api/code_detail'
const memberProfileApiEndpoint = '/api/member'
const compareCodeApiEndpoint = '/api/compare_conditions'
var api = {
  async getHoverData(parameter) {
    return await axios.post(hoverAPI, parameter)
  },
  async getMemberProfileResult(parameter) {
    return await axios.post(memberProfileApiEndpoint, parameter)
  },
  async getCompareCodeResult(parameter) {
    return await axios.post(compareCodeApiEndpoint, parameter)
  },
}

export default api
