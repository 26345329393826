<template>
  <div>
    <div
      style="display: flex; justify-content: space-between; margin-bottom: 18px;"
    >
      <h2>Toolkit versions as CMS Models</h2>
      <v-btn
        v-bind:style="{
          display: 'block',
          marginTop: '-17px',
          marginRight: '-15px',
          position: 'relative',
        }"
        small
        class="close-btn-search"
        @click="removeModal()"
        fab
        elevation="0"
        plain
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </div>
    <div class="cms-table-container">
      <div class="cms-table">
        <h3>Medicare Part - C</h3>
        <Table :table_data="table_data['medicare_part_c']"></Table>
      </div>
      <div class="cms-table">
        <h3>Medicare Part - D</h3>
        <Table :table_data="table_data['medicare_part_d']"></Table>
      </div>
      <div class="cms-table">
        <h3>Affordable Care Act</h3>
        <Table :table_data="table_data['aca']"></Table>
      </div>
      <div class="cms-table">
        <h3>Medicare Part - E (ESRD)</h3>
        <Table :table_data="table_data['esrd']"></Table>
      </div>
    </div>
  </div>
</template>

<script>
import Table from './Table.vue'
import table_data from '../../data/cmsModelsData.json'
export default {
  data() {
    return {
      table_data: table_data,
    }
  },
  components: {
    Table: Table,
  },
  methods: {
    removeModal() {
      this.$store.commit('removeCmsModal')
    },
  },
}
</script>

<style>
.cms-table-container {
  display: flex;
  justify-content: space-around;
}

.cms-table {
  margin: 7px;
  text-align: center;
}

.cms-table .tr-style th {
  width: 10vw !important;
}

h3 {
  margin-bottom: 8px;
}

td {
  white-space: pre-line;
}
</style>
