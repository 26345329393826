var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"main pt-5 compare-box"},[_c('div',{staticClass:"compare-box-between"},[_c('div',{staticClass:"compare-box-label condition-text"},[_vm._v(" Base Conditions "),_c('v-icon',{directives:[{name:"tippy",rawName:"v-tippy",value:({
            theme: 'epicc',
            arrow: true,
            content: _vm.tooltipDataForBaseCondition,
          }),expression:"{\n            theme: 'epicc',\n            arrow: true,\n            content: tooltipDataForBaseCondition,\n          }"}],staticClass:"p-1",attrs:{"color":"black","size":"12","dark":""}},[_vm._v("fas fa-question-circle")])],1),_c('div',{staticClass:"conditioninput-1"},[_c('div',{staticStyle:{"position":"relative"}},[_c('codeinput',{ref:"revalidateCodeInput",attrs:{"id":"code-input-1","tagList":_vm.compareCodeData.inputDict.baseConditions,"searchBox":"tag1","tagstring":_vm.tag1,"componentDataIndex":_vm.componentDataIndex,"riskModel":_vm.compareCodeData.inputDict.riskModel,"dosYearModel":_vm.compareCodeData.inputDict.dosYearModel,"isClearTag":_vm.isClear,"isEmptyTagList":_vm.isBaseTagListEmpty,"commandType":_vm.compareCodeData.inputDict.commandType},on:{"getTag":function($event){return _vm.updateTagLists($event, 'baseConditions')},"removeTag":function($event){return _vm.updateTagLists($event, 'baseConditions')},"clearTagFlag":function($event){_vm.isClear = false},"invalidChips":function($event){return _vm.getInvalidChips($event, _vm.baseInvalidChips)},"emptyTagsFlag":function($event){_vm.isBaseTagListEmpty = false}}}),(this.$store.state.search_box == 'tag1')?_c('div',{staticClass:"member-search-suggestion",class:[
              this.$store.state.search_member.length > 0 &&
              this.$store.state.search_box == 'tag1'
                ? 'suggestion-box'
                : '',
              (this.$store.state.search_suggestion_loader &&
                this.$store.state.search_box == 'tag1') == true
                ? 'show-box'
                : '',
            ]},[(this.$store.state.search_suggestion_loader == true)?_c('v-progress-circular',{staticClass:"compare-code-loader",attrs:{"indeterminate":"","color":"red","size":"32"}}):_vm._e(),(this.$store.state.search_box == 'tag1')?_c('ul',_vm._l((this.$store.state.search_member),function(item){return _c('li',{key:item,on:{"click":function($event){return _vm.addTagFromList(item, 'tag1')}}},[_vm._v(" "+_vm._s(item)+" ")])}),0):_vm._e()],1):_vm._e()],1)])]),_c('div',{staticClass:"compare-box-between"},[_c('div',{staticClass:"compare-box-label condition-text"},[_vm._v(" New Conditions "),_c('v-icon',{directives:[{name:"tippy",rawName:"v-tippy",value:({
            theme: 'epicc',
            arrow: true,
            content: _vm.tooltipDataForNewCondition,
          }),expression:"{\n            theme: 'epicc',\n            arrow: true,\n            content: tooltipDataForNewCondition,\n          }"}],staticClass:"p-1",attrs:{"tabindex":"-1","color":"black","size":"12","dark":""}},[_vm._v("fas fa-question-circle")])],1),_c('div',{staticClass:"conditioninput-2"},[_c('div',{staticStyle:{"position":"relative"}},[_c('codeinput',{ref:"revalidateCodeInput",attrs:{"tagList":_vm.compareCodeData.inputDict.newConditions,"searchBox":"tag2","tagstring":_vm.tag2,"componentDataIndex":_vm.componentDataIndex,"riskModel":_vm.compareCodeData.inputDict.riskModel,"dosYearModel":_vm.compareCodeData.inputDict.dosYearModel,"isClearTag":_vm.isClear2,"isEmptyTagList":_vm.isNewTagListEmpty,"commandType":_vm.compareCodeData.inputDict.commandType},on:{"getTag":function($event){return _vm.updateTagLists($event, 'newConditions')},"removeTag":function($event){return _vm.updateTagLists($event, 'newConditions')},"clearTagFlag":function($event){_vm.isClear2 = false},"invalidChips":function($event){return _vm.getInvalidChips($event, _vm.newInvalidChips)},"emptyTagsFlag":function($event){_vm.isNewTagListEmpty = false}}}),(this.$store.state.search_box == 'tag2')?_c('div',{staticClass:"member-search-suggestion",class:[
              this.$store.state.search_member.length > 0 &&
              this.$store.state.search_box == 'tag2'
                ? 'suggestion-box'
                : '',
              (this.$store.state.search_suggestion_loader &&
                this.$store.state.search_box == 'tag2') == true
                ? 'show-box'
                : '',
              this.$store.state.componentData[0].search_box,
            ]},[(this.$store.state.search_suggestion_loader == true)?_c('v-progress-circular',{staticClass:"compare-code-loader",attrs:{"indeterminate":"","color":"red","size":"32"}}):_vm._e(),_c('ul',_vm._l((this.$store.state.search_member),function(item){return _c('li',{key:item,on:{"click":function($event){return _vm.addTagFromList(item, 'tag2')}}},[_vm._v(" "+_vm._s(item)+" ")])}),0)],1):_vm._e()],1),_c('v-dialog',{attrs:{"max-width":"80%"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('search',{on:{"confirmAction":function($event){return _vm.toggleModal(false)}},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}})],1)],1)]),_c('div',{staticClass:"compare-box-between"},[_c('div',{staticClass:"compare-box-label agelabel"},[_vm._v(" Age: "),_c('v-icon',{directives:[{name:"tippy",rawName:"v-tippy",value:({
            theme: 'epicc',
            arrow: true,
            content: _vm.tooltipDataForAge,
          }),expression:"{\n            theme: 'epicc',\n            arrow: true,\n            content: tooltipDataForAge,\n          }"}],staticClass:"p-1",attrs:{"color":"black","size":"12","dark":""}},[_vm._v("fas fa-question-circle")])],1),_c('div',{staticClass:"ageinput"},[_c('ageField',{attrs:{"componentDataIndex":_vm.componentDataIndex,"age":_vm.compareCodeData.inputDict.age,"isAgeInvalid":_vm.isAgeInvalid},on:{"onAge":_vm.getAge,"validAge":function($event){_vm.isAgeInvalid = false}}})],1),_c('div',{staticClass:"dosyearinput compare-box-sec-row"},[_c('div',{staticClass:"compare-box-label"},[_vm._v(" Risk Model: "),_c('v-icon',{directives:[{name:"tippy",rawName:"v-tippy",value:({
              theme: 'epicc',
              arrow: true,
              content: _vm.tooltipDataForRiskModel,
            }),expression:"{\n              theme: 'epicc',\n              arrow: true,\n              content: tooltipDataForRiskModel,\n            }"}],staticClass:"p-1",attrs:{"color":"black","size":"12","dark":""}},[_vm._v("fas fa-question-circle")])],1),_c('div',{staticClass:"dRiskinput"},[_c('riskModelField',{attrs:{"dosYear":_vm.compareCodeData.inputDict.dosYearModel,"componentDataIndex":_vm.componentDataIndex,"riskModel":_vm.compareCodeData.inputDict.riskModel},on:{"onRiskModel":_vm.getRiskModel}})],1)])]),_c('div',{staticClass:"compare-box-between compare-code-dos-year"},[_c('div',{staticClass:"compare-box-label"},[_vm._v("DOS Year/Model:")]),_c('dosYearModel',{attrs:{"componentDataIndex":_vm.componentDataIndex,"dosYearModel":_vm.compareCodeData.inputDict.dosYearModel},on:{"onDosYear":_vm.getDosYear}}),_c('div',{staticClass:"baserateinput compare-box-sec-row"},[_c('div',{staticClass:"compare-box-label"},[_vm._v(" Base Rate : "),_c('v-icon',{directives:[{name:"tippy",rawName:"v-tippy",value:({
              theme: 'epicc',
              arrow: true,
              content: _vm.tooltipDataForBaserate,
            }),expression:"{\n              theme: 'epicc',\n              arrow: true,\n              content: tooltipDataForBaserate,\n            }"}],staticClass:"p-1",attrs:{"color":"black","size":"12","dark":""}},[_vm._v("fas fa-question-circle")])],1),_c('baseRateInput',{attrs:{"componentDataIndex":_vm.componentDataIndex,"baseRate":_vm.compareCodeData.inputDict.baseRate,"isBaseRateInvalid":_vm.isBaseRateInvalid},on:{"onBaseRate":_vm.getBaseRate,"validBaseRate":function($event){_vm.isBaseRateInvalid = false}}})],1)],1),_c('div',{staticClass:"compare-box-between"},[_c('div',{staticClass:"compare-box-label agelabel"},[_vm._v("Gender:")]),_c('div',{staticClass:"ageinput"},[_c('gender',{attrs:{"gender":_vm.compareCodeData.inputDict.gender,"componentDataIndex":_vm.componentDataIndex},on:{"onGender":_vm.setGender}})],1)]),_c('div',{staticClass:"compare-box-between"},[_c('div',{staticClass:"compare-box-label platelabel"},[_vm._v(" Plate: "),_c('v-icon',{directives:[{name:"tippy",rawName:"v-tippy",value:({
            theme: 'epicc',
            arrow: true,
            content: _vm.tooltipDataForPlate,
          }),expression:"{\n            theme: 'epicc',\n            arrow: true,\n            content: tooltipDataForPlate,\n          }"}],staticClass:"p-1",attrs:{"color":"black","size":"12","dark":""}},[_vm._v("fas fa-question-circle")])],1),_c('div',{staticClass:"box dosyearinput"},[_c('plateInput',{attrs:{"plateValue":_vm.compareCodeData.inputDict.plate},on:{"updatePlateValue":_vm.setPlateValue}})],1)]),_c('div',{staticClass:"compare-box-between"},[_c('div',{staticClass:"compare-box-label platelabel"},[_vm._v("Enrollment Duration:")]),_c('div',{staticClass:"box enrollmentinput"},[_c('enrollmentInput',{attrs:{"enrollmentDuration":_vm.compareCodeData.inputDict.enrollmentDuration},on:{"updateEnrollmentValue":_vm.setEnrollmentValue}})],1)]),_c('div',{staticClass:"compare-box-between"},[_c('div',{staticClass:"compare-box-label platelabel"},[_vm._v(" Date Of Birth: "),_c('v-icon',{directives:[{name:"tippy",rawName:"v-tippy",value:({
            theme: 'epicc',
            arrow: true,
            content: _vm.tooltipDataForDob,
          }),expression:"{\n            theme: 'epicc',\n            arrow: true,\n            content: tooltipDataForDob,\n          }"}],staticClass:"p-1",attrs:{"color":"black","size":"12","dark":""}},[_vm._v("fas fa-question-circle")])],1),_c('div',{staticClass:"box enrollmentinput"},[_c('dobInput',{attrs:{"calculationYear":_vm.compareCodeData.inputDict.calculationYear,"dateOfBirthValue":_vm.compareCodeData.inputDict.DOB},on:{"dateOfBirthUpdate":_vm.setDateOfBirth}})],1)]),_c('div',{staticClass:"compare-box-between compare-code-calculation-year"},[_c('div',{staticClass:"compare-box-label agelabel"},[_vm._v(" Calculation Year: "),_c('v-icon',{directives:[{name:"tippy",rawName:"v-tippy",value:({
            theme: 'epicc',
            arrow: true,
            content: _vm.tooltipDataForCalculationYear,
          }),expression:"{\n            theme: 'epicc',\n            arrow: true,\n            content: tooltipDataForCalculationYear,\n          }"}],staticClass:"p-1",attrs:{"color":"black","size":"12","dark":""}},[_vm._v("fas fa-question-circle")])],1),_c('div',{staticClass:"ageinput"},[_c('calculationYearInput',{attrs:{"error":_vm.dobErrorMessage,"dob":_vm.compareCodeData.inputDict.DOB,"calculationYear":_vm.compareCodeData.inputDict.calculationYear,"componentDataIndex":_vm.componentDataIndex},on:{"calculationYearUpdate":_vm.setCalculationYear}})],1)]),_c('div',{staticClass:"submitclear submitclear-compare-code"},[_c('v-btn',{staticClass:"submit button",on:{"click":_vm.submit}},[_vm._v("Submit")]),_c('v-btn',{staticClass:"clear button",on:{"click":_vm.clearResult}},[_vm._v("Clear")]),_c('v-btn',{ref:"addBtn",class:[
          'add button ' +
            (_vm.componentDataIndex ==
              this.$store.state.componentData.length - 1 &&
            this.$store.state.componentData[_vm.componentDataIndex]
              .isSubmitButtonClicked
              ? 'showBtn'
              : ''),
        ],on:{"click":_vm.addRow}},[_vm._v("Add new")]),(_vm.componentDataIndex !== 0)?_c('v-btn',{staticClass:"close button",on:{"click":function($event){return _vm.deleteRow()}}},[_c('v-icon',{directives:[{name:"tippy",rawName:"v-tippy",value:({
            theme: 'epicc',
            arrow: true,
            content: 'Close',
          }),expression:"{\n            theme: 'epicc',\n            arrow: true,\n            content: 'Close',\n          }"}],staticClass:"p-1",attrs:{"color":"white","size":"20","dark":""}},[_vm._v("fas fa-trash")])],1):_vm._e()],1),_c('div',{staticClass:"compare-code-summary"},[_c('v-snackbar',{staticClass:"snackbar",model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_c('div',{staticClass:"snackbar-text"},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")]),_c('v-btn',{attrs:{"color":"pink","text":""},on:{"click":function($event){_vm.snackbar = false}}},[_vm._v("Close")])],1),_c('div',{staticClass:"progress-bar"},[_c('v-progress-circular',{directives:[{name:"show",rawName:"v-show",value:(_vm.isWaitingForResult),expression:"isWaitingForResult"}],attrs:{"color":"light-blue","indeterminate":"","rounded":"","height":"6"}})],1),(_vm.APIResponse.length != 0)?_c('resultTable',{staticClass:"compare-result",attrs:{"componentDataIndex":_vm.componentDataIndex}}):_vm._e(),(_vm.isDetailButtonVisible)?_c('v-btn',{staticClass:"output-btn",attrs:{"color":"grey"},on:{"click":function($event){;(_vm.isDetailButtonVisible = false), (_vm.isCloseButtonVisible = true)}}},[_vm._v("Detailed Output")]):_vm._e(),(_vm.isCloseButtonVisible)?_c('v-btn',{staticClass:"output-btn",attrs:{"color":"grey"},on:{"click":function($event){;(_vm.isDetailButtonVisible = true), (_vm.isCloseButtonVisible = false)}}},[_vm._v("Close")]):_vm._e()],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }