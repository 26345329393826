<template>
  <div>
    <bulk-calculation-result-table :heading="title" />
  </div>
</template>

<script>
import BulkCalculationResultTable from '../main/results/BulkCalculationResultTable.vue'

export default {
  components: {
    BulkCalculationResultTable,
  },
  data() {
    return {
      title: 'Result for Uploded Data ID: ',
    }
  },
}
</script>
