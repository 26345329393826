var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:['profile-main profile-main-' + this.componentDataIndex]},[(this.showTabs)?_c('v-row',{staticClass:"box-header show-tabs"},[_c('v-col',{class:[
        'select-button show-tabs member-tab ' +
          (_vm.$store.state.componentData[this.componentDataIndex].inputDict
            .commandType == 'member'
            ? 'active'
            : ''),
      ],attrs:{"cols":"4","sm":"2"},on:{"click":function($event){return _vm.updateCommandAndStore('member')}}},[_c('p',{staticClass:"dropdown-member"},[_vm._v("Member Profile")])]),_c('v-col',{class:[
        'select-button show-tabs compare-tab ' +
          (_vm.$store.state.componentData[this.componentDataIndex].inputDict
            .commandType == 'compare'
            ? 'active'
            : ''),
      ],attrs:{"cols":"4","sm":"2"},on:{"click":function($event){return _vm.updateCommandAndStore('compare')}}},[_c('p',{staticClass:"dropdown-member"},[_vm._v("Compare codes")])])],1):_c('v-row',{staticClass:"box-header small"},[_c('v-col',{staticClass:"select-button",attrs:{"cols":"1","sm":"3"}},[_c('v-select',{staticClass:"dropdown-member select-class-tc",attrs:{"dense":"","flat":"","outlined":"","items":_vm.items,"value":_vm.$store.state.componentData[this.componentDataIndex].inputDict
            .commandType},on:{"change":_vm.updateCommandAndStore}})],1)],1),(
      _vm.$store.state.componentData[this.componentDataIndex].inputDict
        .commandType == 'member'
    )?[_c('MemberProfile',{attrs:{"componentDataIndex":this.componentDataIndex}})]:(
      _vm.$store.state.componentData[this.componentDataIndex].inputDict
        .commandType == 'compare'
    )?[_c('CompareCode',{attrs:{"componentDataIndex":this.componentDataIndex}})]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }