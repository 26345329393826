<template>
  <div class="wrapper">
    <div class="fileupload__wrapper">
      <input
        type="file"
        accept=".csv, .xls, .xlsx"
        hidden
        id="actual-btn"
        @change="handleCSVUpload"
      />
      <label for="actual-btn">
        <p>{{ fileName }}</p>
        <span>
          <v-icon class="icon"> mdi-cloud-upload </v-icon>
        </span>
      </label>
      <button v-if="!previewed" class="btn btn__preview" @click="showPreview">
        Preview
      </button>
      <button v-if="previewed" class="btn btn__upload" @click="uploadBulkData">
        Upload
      </button>
    </div>

    <div class="error__fileUpload">
      <span>{{ error }}</span>
    </div>

    <preview-file :parsedExcel="excelData"></preview-file>
  </div>
</template>

<script>
import XLSX from 'xlsx'
import PreviewFile from './PreviewFile.vue'

export default {
  name: 'CSVUpload',
  components: {
    previewFile: PreviewFile,
  },
  data() {
    return {
      fileName: 'Upload excel sheet or csv',
      file: null,
      error: '',
      excelData: [],
      previewed: false,
    }
  },
  computed: {
    _error() {
      return this.error
    },
  },
  methods: {
    parseSheet() {
      if (this.file) {
        const reader = new FileReader()

        reader.onload = (e) => {
          /* Parse data */
          const binaryString = e.target.result
          const workobook = XLSX.read(binaryString, { type: 'binary' })
          /* Get first worksheet */
          const workSheetName = workobook.SheetNames[0]
          const worksheet = workobook.Sheets[workSheetName]
          /* Convert array of arrays */
          const data = XLSX.utils.sheet_to_json(worksheet, { header: 1 })
          this.excelData = data
        }

        reader.readAsBinaryString(this.file)
      }
    },
    handleCSVUpload(event) {
      this.fileName = event.target.files ? event.target.files[0].name : null
      this.file = event.target.files ? event.target.files[0] : null
      if (this.file) this.error = null
    },
    showPreview() {
      if (!this.file) {
        this.error = 'Please upload a file...'
        return
      }
      this.previewed = true
      this.parseSheet()
    },
    uploadBulkData() {
      //pass the files
    },
  },
}
</script>

<style>
.fileupload__wrapper {
  display: flex;
  justify-content: center;
  width: 70%;
  margin: auto;
  align-items: center;
}

.error__fileUpload {
  color: red;
  width: 70%;
  margin: 10px auto;
}

table {
  border-collapse: collapse;
  margin: auto;
}
th {
  font-weight: bold;
}

label {
  display: flex;
  justify-content: space-between;
  border: 1px solid #0099d9;
  color: #000;
  width: 100%;
  padding: 0.5rem;
  font-family: sans-serif;
  border-radius: 0.3rem;
  cursor: pointer;
}
#file-chosen {
  margin-left: 0.3rem;
  margin-top: 1rem;
  font-family: sans-serif;
}
.btn {
  margin-left: 0.4rem;
  padding: 0.6rem 1.2rem;
  color: #fff;
  font-weight: 600;
  border-radius: 5px;
}
.btn__preview {
  background-color: #0087c0;
}
.btn__upload {
  background-color: green;
}
</style>
