<template>
  <v-layout>
    <v-select
      class="genderfield"
      dense
      flat
      outlined
      :items="items"
      :value="gender"
      color="#0099d9"
      style="color: rgb(84, 84, 84) !important;"
      @change="onChange"
    ></v-select>
  </v-layout>
</template>

<script>
export default {
  props: {
    componentDataIndex: Number,
    gender: String,
  },
  data() {
    return {
      items: [
        { text: 'F', value: 'f' },
        { text: 'M', value: 'm' },
      ],
    }
  },
  methods: {
    onChange(value) {
      this.$emit('onGender', value)
    },
  },
}
</script>
