var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(Object.keys(_vm.compareCodeTableJson).length != 0)?_c('div',{staticClass:"compare-summary",attrs:{"id":'compare-result-table-' + _vm.componentDataIndex}},[_c('div',{staticClass:"summary-table"},[_c('table',{staticClass:"summary-table-2"},[_vm._m(0),_c('tr',[_c('td',{staticClass:"left-alignment"},[_vm._v(" Incremental Adds "),_c('v-icon',{directives:[{name:"tippy",rawName:"v-tippy",value:({
                theme: 'epicc',
                arrow: true,
                content:
                  'Additional RAF from adding New Conditions to Base Conditions (e.g. results of retrospective chart audit).',
              }),expression:"{\n                theme: 'epicc',\n                arrow: true,\n                content:\n                  'Additional RAF from adding New Conditions to Base Conditions (e.g. results of retrospective chart audit).',\n              }"}],staticClass:"p-1",attrs:{"color":"black","size":"12","dark":""}},[_vm._v("fas fa-question-circle")])],1),_c('td',{staticClass:"right-alignment"},[_vm._v(" "+_vm._s(_vm.compareCodeTableCalcDict.incrementRaf)+" ")]),_c('td',{staticClass:"right-alignment"},[_vm._v(" "+_vm._s(_vm._f("toCurrency")(_vm.compareCodeTableCalcDict.incrementPremium))+" ")])]),_c('tr',[_c('td',{staticClass:"left-alignment"},[_vm._v(" HCC Gaps "),_c('v-icon',{directives:[{name:"tippy",rawName:"v-tippy",value:({
                theme: 'epicc',
                arrow: true,
                content:
                  'HCCs in Base Condition list missing or downgraded in New Condition List (e.g. Year-over-year suspect list)',
              }),expression:"{\n                theme: 'epicc',\n                arrow: true,\n                content:\n                  'HCCs in Base Condition list missing or downgraded in New Condition List (e.g. Year-over-year suspect list)',\n              }"}],staticClass:"p-1",attrs:{"color":"black","size":"12","dark":""}},[_vm._v("fas fa-question-circle")])],1),_c('td',{staticClass:"right-alignment"},[_vm._v(" "+_vm._s(_vm.compareCodeTableCalcDict.gapsRaf)+" ")]),_c('td',{staticClass:"right-alignment"},[_vm._v(" "+_vm._s(_vm._f("toCurrency")(_vm.compareCodeTableCalcDict.gapsPremium))+" ")])]),_c('tr',[_c('td',{staticClass:"left-alignment"},[_vm._v(" Total Difference "),_c('v-icon',{directives:[{name:"tippy",rawName:"v-tippy",value:({
                theme: 'epicc',
                arrow: true,
                content: 'Total RAF difference between the two lists',
              }),expression:"{\n                theme: 'epicc',\n                arrow: true,\n                content: 'Total RAF difference between the two lists',\n              }"}],staticClass:"p-1",attrs:{"color":"black","size":"12","dark":""}},[_vm._v("fas fa-question-circle")])],1),_c('td',{staticClass:"right-alignment"},[_vm._v(" "+_vm._s(_vm.compareCodeTableCalcDict.diffRaf)+" ")]),_c('td',{staticClass:"right-alignment"},[_vm._v(" "+_vm._s(_vm._f("toCurrency")(_vm.compareCodeTableCalcDict.diffPremium))+" ")])])]),_c('table',{staticClass:"summary-table-1"},[_c('tr',[_c('th',{staticStyle:{"width":"23.7%"}},[_vm._v("Condition Differences")]),(_vm.compareCodeTableJson.flag == 'dx')?_c('th',{staticStyle:{"width":"22.6%"}},[_vm._v(" Dx ")]):_vm._e(),_c('th',{staticStyle:{"width":"23.3%"}},[_vm._v("HCC")]),_c('th',{staticStyle:{"width":"14.02%"}},[_vm._v("RAF")]),_c('th',{staticStyle:{"width":"15.4%"}},[_vm._v("Monthly Premium")])]),(
            Object.keys(_vm.compareCodeTableJson.Summary.adds.HCC).length == 0
          )?_c('tr',[_c('td',{staticClass:"left-alignment"},[_vm._v("Adds")]),(_vm.compareCodeTableJson.flag == 'dx')?_c('td',[_vm._v("--")]):_vm._e(),_c('td',[_vm._v("--")]),_c('td',[_vm._v("--")]),_c('td',[_vm._v("--")])]):_c('tr',[_c('td',{staticClass:"left-alignment"},[_vm._v("Adds")]),(_vm.compareCodeTableJson.flag == 'dx')?_c('td',[_c('tr',_vm._l((_vm.compareCodeTableJson
                  .Summary.adds.Dx),function(dxcode,adds_dxcode_index){return _c('div',{key:adds_dxcode_index,staticClass:"nested-td"},[(
                    adds_dxcode_index !=
                      _vm.compareCodeTableJson.Summary.adds.Dx.length - 1
                  )?_c('p',[_vm._v(" "+_vm._s(dxcode + ',')+" ")]):_c('p',[_vm._v(_vm._s(dxcode))])])}),0)]):_vm._e(),_c('td',{staticClass:"right-alignment"},[_c('tr',_vm._l((_vm.compareCodeTableJson
                  .Summary.adds.HCC),function(hcccode,adds_hcccode_index){return _c('div',{key:adds_hcccode_index,staticClass:"nested-td"},[(
                    adds_hcccode_index !=
                      _vm.compareCodeTableJson.Summary.adds.HCC.length - 1
                  )?_c('p',[_vm._v(" "+_vm._s(hcccode + ',')+" ")]):_c('p',[_vm._v(_vm._s(hcccode))])])}),0)]),_c('td',{staticClass:"right-alignment"},[_vm._v(" "+_vm._s(_vm._f("roundOff")(_vm.compareCodeTableJson.Summary.adds.raf_change))+" ")]),_c('td',{staticClass:"right-alignment"},[_vm._v(" "+_vm._s(_vm._f("toCurrency")(_vm.compareCodeTableJson.Summary.adds.premium))+" ")])]),(
            Object.keys(_vm.compareCodeTableJson.Summary.upgrades.HCC.From)
              .length == 0
          )?_c('tr',[_c('td',{staticClass:"left-alignment"},[_vm._v("Upgrades")]),(_vm.compareCodeTableJson.flag == 'dx')?_c('td',[_vm._v("--")]):_vm._e(),_c('td',[_vm._v("--")]),_c('td',[_vm._v("--")]),_c('td',[_vm._v("--")])]):_c('tr',[_c('td',{staticClass:"left-alignment"},[_vm._v("Upgrades")]),(_vm.compareCodeTableJson.flag == 'dx')?_c('td',[_c('tr',[_c('td',{staticClass:"nested-td right-alignment"},_vm._l((_vm.compareCodeTableJson.Summary
                    .upgrades.Dx.From),function(from_dxcode,upgrades_from_dxcode_index){return _c('span',{key:upgrades_from_dxcode_index},[(
                      upgrades_from_dxcode_index !=
                        _vm.compareCodeTableJson.Summary.upgrades.Dx.From.length -
                          1
                    )?_c('p',[_vm._v(" "+_vm._s(from_dxcode + ',')+" ")]):_c('p',[_vm._v(_vm._s(from_dxcode))])])}),0),_vm._m(1),_c('td',{staticClass:"nested-td left-alignment"},_vm._l((_vm.compareCodeTableJson.Summary
                    .upgrades.Dx.To),function(to_dxcode,to_dxcode_upgrades_index){return _c('span',{key:to_dxcode_upgrades_index},[(
                      to_dxcode_upgrades_index !=
                        _vm.compareCodeTableJson.Summary.upgrades.Dx.To.length - 1
                    )?_c('p',[_vm._v(" "+_vm._s(to_dxcode + ',')+" ")]):_c('p',[_vm._v(_vm._s(to_dxcode))])])}),0)])]):_vm._e(),_c('td',[_c('tr',[_c('td',{staticClass:"nested-td right-alignment"},_vm._l((_vm.compareCodeTableJson.Summary
                    .upgrades.HCC.From),function(from_hcc,from_hcc_upgrades_index){return _c('span',{key:from_hcc_upgrades_index},[(
                      from_hcc_upgrades_index !=
                        _vm.compareCodeTableJson.Summary.upgrades.HCC.From
                          .length -
                          1
                    )?_c('p',[_vm._v(" "+_vm._s(from_hcc + ',')+" ")]):_c('p',[_vm._v(_vm._s(from_hcc))])])}),0),_vm._m(2),_c('td',{staticClass:"nested-td left-alignment"},_vm._l((_vm.compareCodeTableJson.Summary
                    .upgrades.HCC.To),function(to_hcc,to_hcc_upgrades_index){return _c('span',{key:to_hcc_upgrades_index},[(
                      to_hcc_upgrades_index !=
                        _vm.compareCodeTableJson.Summary.upgrades.HCC.To.length -
                          1
                    )?_c('p',[_vm._v(" "+_vm._s(to_hcc + ',')+" ")]):_c('p',[_vm._v(_vm._s(to_hcc))])])}),0)])]),_c('td',{staticClass:"right-alignment"},[_vm._v(" "+_vm._s(_vm._f("roundOff")(_vm.compareCodeTableJson.Summary.upgrades.raf_change))+" ")]),_c('td',{staticClass:"right-alignment"},[_vm._v(" "+_vm._s(_vm._f("toCurrency")(_vm.compareCodeTableJson.Summary.upgrades.premium))+" ")])]),(
            Object.keys(_vm.compareCodeTableJson.Summary.downgrades.HCC.From)
              .length == 0
          )?_c('tr',[_c('td',{staticClass:"left-alignment"},[_vm._v("Downgrades")]),(_vm.compareCodeTableJson.flag == 'dx')?_c('td',[_vm._v("--")]):_vm._e(),_c('td',[_vm._v("--")]),_c('td',[_vm._v("--")]),_c('td',[_vm._v("--")])]):_c('tr',[_c('td',{staticClass:"left-alignment"},[_vm._v("Downgrades")]),(_vm.compareCodeTableJson.flag == 'dx')?_c('td',[_c('tr',[_c('td',{staticClass:"nested-td right-alignment"},_vm._l((_vm.compareCodeTableJson
                    .Summary.downgrades.Dx.From),function(from_dxcode,from_dxcode_downgrades_index){return _c('span',{key:from_dxcode_downgrades_index,staticClass:"nested-td"},[(
                      from_dxcode_downgrades_index !=
                        _vm.compareCodeTableJson.Summary.downgrades.Dx.From
                          .length -
                          1
                    )?_c('p',[_vm._v(" "+_vm._s(from_dxcode + ',')+" ")]):_c('p',[_vm._v(_vm._s(from_dxcode))])])}),0),_vm._m(3),_c('td',{staticClass:"nested-td left-alignment"},_vm._l((_vm.compareCodeTableJson.Summary
                    .downgrades.Dx.To),function(to_dxcode,to_dxcode_downgrades_index){return _c('span',{key:to_dxcode_downgrades_index},[(
                      to_dxcode_downgrades_index !=
                        _vm.compareCodeTableJson.Summary.downgrades.Dx.To.length -
                          1
                    )?_c('p',[_vm._v(" "+_vm._s(to_dxcode + ',')+" ")]):_c('p',[_vm._v(_vm._s(to_dxcode))])])}),0)])]):_vm._e(),_c('td',{staticClass:"right-alignment"},[_c('tr',[_c('td',{staticClass:"nested-td right-alignment"},_vm._l((_vm.compareCodeTableJson.Summary
                    .downgrades.HCC.From),function(from_hcc,from_hcc_downgrades_index){return _c('span',{key:from_hcc_downgrades_index},[(
                      from_hcc_downgrades_index !=
                        _vm.compareCodeTableJson.Summary.downgrades.HCC.From
                          .length -
                          1
                    )?_c('p',[_vm._v(" "+_vm._s(from_hcc + ',')+" ")]):_c('p',[_vm._v(_vm._s(from_hcc))])])}),0),_vm._m(4),_c('td',{staticClass:"nested-td right-alignment"},_vm._l((_vm.compareCodeTableJson.Summary
                    .downgrades.HCC.To),function(to_hcc,to_hcc_downgrades_index){return _c('span',{key:to_hcc_downgrades_index},[(
                      to_hcc_downgrades_index !=
                        _vm.compareCodeTableJson.Summary.downgrades.HCC.To
                          .length -
                          1
                    )?_c('p',[_vm._v(" "+_vm._s(to_hcc + ',')+" ")]):_c('p',[_vm._v(_vm._s(to_hcc))])])}),0)])]),_c('td',{staticClass:"right-alignment"},[_vm._v(" "+_vm._s(_vm._f("roundOff")(_vm.compareCodeTableJson.Summary.downgrades.raf_change))+" ")]),_c('td',{staticClass:"right-alignment"},[_vm._v(" "+_vm._s(_vm._f("toCurrency")(_vm.compareCodeTableJson.Summary.downgrades.premium))+" ")])]),(
            Object.keys(_vm.compareCodeTableJson.Summary.deletes.HCC).length == 0
          )?_c('tr',[_c('td',{staticClass:"left-alignment"},[_vm._v("Deletes")]),(_vm.compareCodeTableJson.flag == 'dx')?_c('td',[_vm._v("--")]):_vm._e(),_c('td',[_vm._v("--")]),_c('td',[_vm._v("--")]),_c('td',[_vm._v("--")])]):_c('tr',[_c('td',{staticClass:"left-alignment"},[_vm._v("Deletes")]),(_vm.compareCodeTableJson.flag == 'dx')?_c('td',_vm._l((_vm.compareCodeTableJson
                .Summary.deletes.Dx),function(dxcode,dxcode_deletes_index){return _c('div',{key:dxcode_deletes_index,staticClass:"right-alignment nested-td float-left"},[(
                  dxcode_deletes_index !=
                    _vm.compareCodeTableJson.Summary.deletes.Dx.length - 1
                )?_c('p',[_vm._v(" "+_vm._s(dxcode + ', ')+" ")]):_c('p',[_vm._v(_vm._s(dxcode))])])}),0):_vm._e(),_c('td',_vm._l((_vm.compareCodeTableJson
                .Summary.deletes.HCC),function(hcccode,hcccode_deletes_index){return _c('div',{key:hcccode_deletes_index,staticClass:"right-alignment nested-td float-left"},[(
                  hcccode_deletes_index !=
                    _vm.compareCodeTableJson.Summary.deletes.HCC.length - 1
                )?_c('p',[(hcccode != null)?_c('span',[_vm._v(_vm._s(hcccode + ','))]):_c('span',[_vm._v("--")])]):_c('p',[(hcccode != null)?_c('span',[_vm._v(_vm._s(hcccode))]):_c('span',[_vm._v("--")])])])}),0),_c('td',{staticClass:"right-alignment"},[_vm._v(" "+_vm._s(_vm._f("roundOff")(_vm.compareCodeTableJson.Summary.deletes.raf_change))+" ")]),_c('td',{staticClass:"right-alignment"},[_vm._v(" "+_vm._s(_vm._f("toCurrency")(_vm.compareCodeTableJson.Summary.deletes.premium))+" ")])]),(
            Object.keys(_vm.compareCodeTableJson.Summary.no_change).length == 0
          )?_c('tr',[_c('td',{staticClass:"left-alignment"},[_vm._v("No Change")]),(_vm.compareCodeTableJson.flag == 'dx')?_c('td',[_vm._v("--")]):_vm._e(),_c('td',[_vm._v("--")]),_c('td',[_vm._v("--")]),_c('td',[_vm._v("--")])]):_c('tr',[_c('td',{staticClass:"left-alignment"},[_vm._v("No change")]),(_vm.compareCodeTableJson.flag == 'dx')?_c('td',[(_vm.compareCodeTableJson.Summary.no_change.Dx.length == 0)?_c('div',[_vm._v(" -- ")]):_vm._l((_vm.compareCodeTableJson.Summary
                .no_change.Dx),function(no_change_dxcode,indexOf_no_change_dxcode){return _c('div',{key:indexOf_no_change_dxcode,staticClass:"left-alignment nested-td float-left"},[(
                  indexOf_no_change_dxcode !=
                    _vm.compareCodeTableJson.Summary.no_change.Dx.length - 1
                )?_c('p',[_vm._v(" "+_vm._s(no_change_dxcode + ', ')+" ")]):_c('p',[_vm._v(_vm._s(no_change_dxcode))])])})],2):_vm._e(),(_vm.compareCodeTableJson.Summary.no_change.HCC.length == 0)?_c('td',[_vm._v(" -- ")]):_c('td',_vm._l((_vm.compareCodeTableJson.Summary
                .no_change.HCC),function(no_change_hcc_code,indexOf_no_change_hcc_code){return _c('div',{key:indexOf_no_change_hcc_code,staticClass:"left-alignment nested-td float-left"},[(
                  indexOf_no_change_hcc_code !=
                    _vm.compareCodeTableJson.Summary.no_change.HCC.length - 1
                )?_c('p',[_vm._v(" "+_vm._s(no_change_hcc_code + ',')+" ")]):_c('p',[_vm._v(_vm._s(no_change_hcc_code))])])}),0),(_vm.compareCodeTableJson.Summary.no_change.raf_change != null)?_c('td',{staticClass:"right-alignment"},[_vm._v(" "+_vm._s(_vm._f("roundOff")(_vm.compareCodeTableJson.Summary.no_change.raf_change))+" ")]):_c('td',[_vm._v("--")]),(_vm.compareCodeTableJson.Summary.no_change.premium != null)?_c('td',{staticClass:"right-alignment"},[_vm._v(" "+_vm._s(_vm._f("toCurrency")(_vm.compareCodeTableJson.Summary.no_change.premium))+" ")]):_c('td',[_vm._v("--")])])])]),_c('div',{staticClass:"detail-box"},[_c('div',{staticClass:"detail-button"},[(_vm.isDetailButtonVisible)?_c('v-btn',{attrs:{"color":"grey"},on:{"click":function($event){;(_vm.isDetailButtonVisible = false), (_vm.isCloseButtonVisible = true)}}},[_vm._v("Detailed Output")]):_vm._e(),(_vm.isCloseButtonVisible)?_c('v-btn',{attrs:{"color":"grey"},on:{"click":function($event){;(_vm.isDetailButtonVisible = true), (_vm.isCloseButtonVisible = false)}}},[_vm._v("Close")]):_vm._e()],1),_c('br'),_c('div',{staticClass:"detail-table"},[(_vm.isCloseButtonVisible)?_c('table',{staticClass:"summary-table-3"},[_vm._m(5),(_vm.compareCodeTableJson.Detailed.adds.length != 0)?_c('tr',[_c('td',{staticClass:"left-alignment"},[_vm._v("Adds")]),_c('td',{staticClass:"left-alignment"},_vm._l((_vm.compareCodeTableJson
                  .Detailed.adds),function(baseAddCodes,baseAddIndex){return _c('div',{key:baseAddIndex},[(baseAddCodes.new_condition.length > 1)?_c('span',_vm._l((baseAddCodes.new_condition.length - 1),function(len){return _c('p',{key:len,staticClass:"hidden-block"},[_vm._v(" -- ")])}),0):_vm._e(),_vm._l((baseAddCodes.base_condition),function(baseConditions,baseConIndex){return _c('p',{key:baseConIndex},[_vm._v(" "+_vm._s(baseConditions)+" ")])}),(_vm.compareCodeTableJson.Detailed.adds.length >= 2)?_c('span',[(_vm.baseConditions != null)?_c('hr',{staticClass:"hr-style-base",style:([
                      baseAddIndex ==
                      _vm.compareCodeTableJson.Detailed.adds.length - 1
                        ? { display: 'none' }
                        : { display: 'block' },
                    ])}):_vm._e()]):_vm._e()],2)}),0),_c('td',{staticClass:"left-alignment"},_vm._l((_vm.compareCodeTableJson
                  .Detailed.adds),function(newAddCodes,newAddIndex){return _c('div',{key:newAddIndex},[_vm._l((newAddCodes.new_condition),function(newConditions,newConIndex){return _c('p',{key:newConIndex},[_vm._v(" "+_vm._s(newConditions)+" ")])}),(_vm.compareCodeTableJson.Detailed.adds.length >= 2)?_c('span',[(newAddCodes != null)?_c('hr',{staticClass:"hr-style-new-condition",style:([
                      newAddIndex ==
                      _vm.compareCodeTableJson.Detailed.adds.length - 1
                        ? { display: 'none' }
                        : { display: 'block' },
                    ])}):_vm._e()]):_vm._e()],2)}),0),_c('td',{staticClass:"right-alignment"},_vm._l((_vm.compareCodeTableJson
                  .Detailed.adds),function(addCodeRaf,addCodeRafIndex){return _c('div',{key:addCodeRafIndex},[(addCodeRaf.new_condition.length > 1)?_c('span',_vm._l((addCodeRaf.new_condition.length - 1),function(len){return _c('p',{key:len,staticClass:"hidden-block"},[_vm._v(" -- ")])}),0):_vm._e(),_c('p',[_vm._v(_vm._s(_vm._f("roundOff")(addCodeRaf.raf_change)))]),(_vm.compareCodeTableJson.Detailed.adds.length >= 2)?_c('span',[(addCodeRaf != null)?_c('hr',{staticClass:"hr-style-raf-change",style:([
                      addCodeRafIndex ==
                      _vm.compareCodeTableJson.Detailed.adds.length - 1
                        ? { display: 'none' }
                        : { display: 'block' },
                    ])}):_vm._e()]):_vm._e()])}),0),_c('td',{staticClass:"right-alignment"},_vm._l((_vm.compareCodeTableJson
                  .Detailed.adds),function(addCodePre,addCodePreIndex){return _c('div',{key:addCodePreIndex},[(addCodePre.new_condition.length > 1)?_c('span',_vm._l((addCodePre.new_condition.length - 1),function(len){return _c('p',{key:len,staticClass:"hidden-block"},[_vm._v(" -- ")])}),0):_vm._e(),_c('p',[_vm._v(_vm._s(_vm._f("toCurrency")(addCodePre.premium)))]),(_vm.compareCodeTableJson.Detailed.adds.length >= 2)?_c('span',[(addCodePre != null)?_c('hr',{staticClass:"hr-style-pre-change",style:([
                      addCodePreIndex ==
                      _vm.compareCodeTableJson.Detailed.adds.length - 1
                        ? { display: 'none' }
                        : { display: 'block' },
                    ])}):_vm._e()]):_vm._e()])}),0)]):_vm._e(),(_vm.compareCodeTableJson.Detailed.upgrades.length != 0)?_c('tr',[_c('td',{staticClass:"left-alignment"},[_vm._v("Upgrades")]),_c('td',_vm._l((_vm.compareCodeTableJson
                  .Detailed.upgrades),function(uBaseCodes,uBaseIndex){return _c('div',{key:uBaseIndex,staticClass:"left-alignment"},[(uBaseCodes.new_condition.length > 1)?_c('span',_vm._l((uBaseCodes.new_condition.length - 1),function(len){return _c('p',{key:len,staticClass:"hidden-block"},[_vm._v(" -- ")])}),0):_vm._e(),_vm._l((uBaseCodes.base_condition),function(uBaseConditions,uBaseConIndex){return _c('p',{key:uBaseConIndex},[_vm._v(" "+_vm._s(uBaseConditions)+" ")])}),(_vm.compareCodeTableJson.Detailed.upgrades.length >= 2)?_c('span',[(uBaseCodes != null)?_c('hr',{staticClass:"hr-style-base",style:([
                      uBaseIndex ==
                      _vm.compareCodeTableJson.Detailed.upgrades.length - 1
                        ? { display: 'none' }
                        : { display: 'block' },
                    ])}):_vm._e()]):_vm._e()],2)}),0),_c('td',_vm._l((_vm.compareCodeTableJson.Detailed
                  .upgrades),function(uNewCodes,uNewIndex){return _c('div',{key:uNewIndex,staticClass:"left-alignment"},[_vm._l((uNewCodes.new_condition),function(uNewConditions,uNewConIndex){return _c('p',{key:uNewConIndex},[_vm._v(" "+_vm._s(uNewConditions)+" ")])}),(_vm.compareCodeTableJson.Detailed.upgrades.length >= 2)?_c('span',[(uNewCodes != null)?_c('hr',{staticClass:"hr-style-new-condition",style:([
                      uNewIndex ==
                      _vm.compareCodeTableJson.Detailed.upgrades.length - 1
                        ? { display: 'none' }
                        : { display: 'block' },
                    ])}):_vm._e()]):_vm._e()],2)}),0),_c('td',_vm._l((_vm.compareCodeTableJson.Detailed
                  .upgrades),function(upgradeCodeRaf,upgradeCodeRafIndex){return _c('div',{key:upgradeCodeRafIndex,staticClass:"right-alignment"},[(upgradeCodeRaf.new_condition.length > 1)?_c('span',_vm._l((upgradeCodeRaf.new_condition.length - 1),function(len){return _c('p',{key:len,staticClass:"hidden-block"},[_vm._v(" -- ")])}),0):_vm._e(),_c('p',[_vm._v(_vm._s(_vm._f("roundOff")(upgradeCodeRaf.raf_change)))]),(_vm.compareCodeTableJson.Detailed.upgrades.length >= 2)?_c('span',[(upgradeCodeRaf != null)?_c('hr',{staticClass:"hr-style-raf-change",style:([
                      upgradeCodeRafIndex ==
                      _vm.compareCodeTableJson.Detailed.upgrades.length - 1
                        ? { display: 'none' }
                        : { display: 'block' },
                    ])}):_vm._e()]):_vm._e()])}),0),_c('td',_vm._l((_vm.compareCodeTableJson.Detailed
                  .upgrades),function(upgradeCodePre,upgradeCodePreIndex){return _c('div',{key:upgradeCodePreIndex,staticClass:"right-alignment"},[(upgradeCodePre.new_condition.length > 1)?_c('span',_vm._l((upgradeCodePre.new_condition.length - 1),function(len){return _c('p',{key:len,staticClass:"hidden-block"},[_vm._v(" -- ")])}),0):_vm._e(),_c('p',[_vm._v(_vm._s(_vm._f("toCurrency")(upgradeCodePre.premium)))]),(_vm.compareCodeTableJson.Detailed.upgrades.length >= 2)?_c('span',[(upgradeCodePre != null)?_c('hr',{staticClass:"hr-style-pre-change",style:([
                      upgradeCodePreIndex ==
                      _vm.compareCodeTableJson.Detailed.upgrades.length - 1
                        ? { display: 'none' }
                        : { display: 'block' },
                    ])}):_vm._e()]):_vm._e()])}),0)]):_vm._e(),(_vm.compareCodeTableJson.Detailed.downgrades.length != 0)?_c('tr',[_c('td',{staticClass:"left-alignment"},[_vm._v("Downgrades")]),_c('td',_vm._l((_vm.compareCodeTableJson
                  .Detailed.downgrades),function(dBaseCodes,dBaseIndex){return _c('div',{key:dBaseIndex,staticClass:"left-alignment"},[(dBaseCodes.new_condition.length > 1)?_c('span',_vm._l((dBaseCodes.new_condition.length - 1),function(len){return _c('p',{key:len,staticClass:"hidden-block"},[_vm._v(" -- ")])}),0):_vm._e(),_vm._l((dBaseCodes.base_condition),function(dBaseConditions,dBaseConIndex){return _c('p',{key:dBaseConIndex},[_vm._v(" "+_vm._s(dBaseConditions)+" ")])}),(_vm.compareCodeTableJson.Detailed.downgrades.length >= 2)?_c('span',[(dBaseCodes != null)?_c('hr',{staticClass:"hr-style-base",style:([
                      dBaseIndex ==
                      _vm.compareCodeTableJson.Detailed.downgrades.length - 1
                        ? { display: 'none' }
                        : { display: 'block' },
                    ])}):_vm._e()]):_vm._e()],2)}),0),_c('td',_vm._l((_vm.compareCodeTableJson.Detailed
                  .downgrades),function(dNewCodes,dNewIndex){return _c('div',{key:dNewIndex,staticClass:"left-alignment"},[_vm._l((dNewCodes.new_condition),function(dNewConditions,dNewConIndex){return _c('p',{key:dNewConIndex},[_vm._v(" "+_vm._s(dNewConditions)+" ")])}),(_vm.compareCodeTableJson.Detailed.downgrades.length >= 2)?_c('span',[(dNewCodes != null)?_c('hr',{staticClass:"hr-style-new-condition",style:([
                      dNewIndex ==
                      _vm.compareCodeTableJson.Detailed.downgrades.length - 1
                        ? { display: 'none' }
                        : { display: 'block' },
                    ])}):_vm._e()]):_vm._e()],2)}),0),_c('td',_vm._l((_vm.compareCodeTableJson
                  .Detailed.downgrades),function(downCodeRaf,downCodeRafIndex){return _c('div',{key:downCodeRafIndex,staticClass:"right-alignment"},[(downCodeRaf.new_condition.length > 1)?_c('span',_vm._l((downCodeRaf.new_condition.length - 1),function(len){return _c('p',{key:len,staticClass:"hidden-block"},[_vm._v(" -- ")])}),0):_vm._e(),_c('p',[_vm._v(_vm._s(_vm._f("roundOff")(downCodeRaf.raf_change)))]),(_vm.compareCodeTableJson.Detailed.downgrades.length >= 2)?_c('span',[(downCodeRaf != null)?_c('hr',{staticClass:"hr-style-raf-change",style:([
                      downCodeRafIndex ==
                      _vm.compareCodeTableJson.Detailed.downgrades.length - 1
                        ? { display: 'none' }
                        : { display: 'block' },
                    ])}):_vm._e()]):_vm._e()])}),0),_c('td',_vm._l((_vm.compareCodeTableJson
                  .Detailed.downgrades),function(downCodePre,downCodePreIndex){return _c('div',{key:downCodePreIndex,staticClass:"right-alignment"},[(downCodePre.new_condition.length > 1)?_c('span',_vm._l((downCodePre.new_condition.length - 1),function(len){return _c('p',{key:len,staticClass:"hidden-block"},[_vm._v(" -- ")])}),0):_vm._e(),_c('p',[_vm._v(_vm._s(_vm._f("toCurrency")(downCodePre.premium)))]),(_vm.compareCodeTableJson.Detailed.downgrades.length >= 2)?_c('span',[(downCodePre != null)?_c('hr',{staticClass:"hr-style-pre-change",style:([
                      downCodePreIndex ==
                      _vm.compareCodeTableJson.Detailed.downgrades.length - 1
                        ? { display: 'none' }
                        : { display: 'block' },
                    ])}):_vm._e()]):_vm._e()])}),0)]):_vm._e(),(_vm.compareCodeTableJson.Detailed.deletes.length != 0)?_c('tr',[_c('td',{staticClass:"left-alignment"},[_vm._v("Deletes")]),_c('td',_vm._l((_vm.compareCodeTableJson
                  .Detailed.deletes),function(delBaseCodes,delBaseIndex){return _c('div',{key:delBaseIndex,staticClass:"left-alignment"},[(delBaseCodes.new_condition.length > 1)?_c('span',_vm._l((delBaseCodes.new_condition.length - 1),function(len){return _c('p',{key:len,staticClass:"hidden-block"},[_vm._v(" -- ")])}),0):_vm._e(),_vm._l((delBaseCodes.base_condition),function(delBaseConditions,delBaseConIndex){return _c('p',{key:delBaseConIndex},[_vm._v(" "+_vm._s(delBaseConditions)+" ")])}),(_vm.compareCodeTableJson.Detailed.deletes.length >= 2)?_c('span',[(delBaseCodes != null)?_c('hr',{staticClass:"hr-style-base",style:([
                      delBaseIndex ==
                      _vm.compareCodeTableJson.Detailed.deletes.length - 1
                        ? { display: 'none' }
                        : { display: 'block' },
                    ])}):_vm._e()]):_vm._e()],2)}),0),_c('td',_vm._l((_vm.compareCodeTableJson
                  .Detailed.deletes),function(delNewCodes,delNewIndex){return _c('div',{key:delNewIndex,staticClass:"left-alignment"},[_vm._l((delNewCodes.new_condition),function(delNewConditions,delNewConIndex){return _c('p',{key:delNewConIndex},[_vm._v(" "+_vm._s(delNewConditions)+" ")])}),(_vm.compareCodeTableJson.Detailed.deletes.length >= 2)?_c('span',[(_vm.delNewConditions != null)?_c('hr',{staticClass:"hr-style-new-condition",style:([
                      delNewIndex ==
                      _vm.compareCodeTableJson.Detailed.deletes.length - 1
                        ? { display: 'none' }
                        : { display: 'block' },
                    ])}):_vm._e()]):_vm._e()],2)}),0),_c('td',_vm._l((_vm.compareCodeTableJson.Detailed.deletes),function(deleteCodeRaf,deleteCodeRafIndex){return _c('div',{key:deleteCodeRafIndex,staticClass:"right-alignment"},[(deleteCodeRaf.new_condition.length > 1)?_c('span',_vm._l((deleteCodeRaf.new_condition.length - 1),function(len){return _c('p',{key:len,staticClass:"hidden-block"},[_vm._v(" -- ")])}),0):_vm._e(),_c('p',[_vm._v(_vm._s(_vm._f("roundOff")(deleteCodeRaf.raf_change)))]),(_vm.compareCodeTableJson.Detailed.deletes.length >= 2)?_c('span',[(deleteCodeRaf != null)?_c('hr',{staticClass:"hr-style-raf-change",style:([
                      deleteCodeRafIndex ==
                      _vm.compareCodeTableJson.Detailed.deletes.length - 1
                        ? { display: 'none' }
                        : { display: 'block' },
                    ])}):_vm._e()]):_vm._e()])}),0),_c('td',_vm._l((_vm.compareCodeTableJson.Detailed.deletes),function(deleteCodePre,deleteCodePreIndex){return _c('div',{key:deleteCodePreIndex,staticClass:"right-alignment"},[(deleteCodePre.new_condition.length > 1)?_c('span',_vm._l((deleteCodePre.new_condition.length - 1),function(len){return _c('p',{key:len,staticClass:"hidden-block"},[_vm._v(" -- ")])}),0):_vm._e(),_c('p',[_vm._v(_vm._s(_vm._f("toCurrency")(deleteCodePre.premium)))]),(_vm.compareCodeTableJson.Detailed.deletes.length >= 2)?_c('span',[(deleteCodePre != null)?_c('hr',{staticClass:"hr-style-pre-change",style:([
                      deleteCodePreIndex ==
                      _vm.compareCodeTableJson.Detailed.deletes.length - 1
                        ? { display: 'none' }
                        : { display: 'block' },
                    ])}):_vm._e()]):_vm._e()])}),0)]):_vm._e(),(_vm.compareCodeTableJson.Detailed.no_change.length != 0)?_c('tr',[_c('td',{staticClass:"left-alignment"},[_vm._v("No Change")]),_c('td',_vm._l((_vm.compareCodeTableJson.Detailed.no_change),function(noChangeBase,noChangeBaseIndex){return _c('div',{key:noChangeBaseIndex,staticClass:"left-alignment"},[(noChangeBase.new_condition.length > 1)?_c('span',_vm._l((noChangeBase.new_condition.length - 1),function(len){return _c('p',{key:len,staticClass:"hidden-block"},[_vm._v(" -- ")])}),0):_vm._e(),_vm._l((noChangeBase.base_condition),function(ncBaseConditions,ncBaseConIndex){return _c('p',{key:ncBaseConIndex},[_vm._v(" "+_vm._s(ncBaseConditions)+" ")])}),(_vm.compareCodeTableJson.Detailed.no_change.length >= 2)?_c('span',[(noChangeBase != null)?_c('hr',{staticClass:"hr-style-base",style:([
                      noChangeBaseIndex ==
                      _vm.compareCodeTableJson.Detailed.no_change.length - 1
                        ? { display: 'none' }
                        : { display: 'block' },
                    ])}):_vm._e()]):_vm._e()],2)}),0),_c('td',_vm._l((_vm.compareCodeTableJson
                  .Detailed.no_change),function(noChangeNew,noChangeNewIndex){return _c('div',{key:noChangeNewIndex,staticClass:"left-alignment"},[_vm._l((noChangeNew.new_condition),function(ncNewConditions,ncNewConIndex){return _c('p',{key:ncNewConIndex},[_vm._v(" "+_vm._s(ncNewConditions)+" ")])}),(_vm.compareCodeTableJson.Detailed.no_change.length >= 2)?_c('span',[(noChangeNew != null)?_c('hr',{staticClass:"hr-style-new-condition",style:([
                      noChangeNewIndex ==
                      _vm.compareCodeTableJson.Detailed.no_change.length - 1
                        ? { display: 'none' }
                        : { display: 'block' },
                    ])}):_vm._e()]):_vm._e()],2)}),0),_c('td',_vm._l((_vm.compareCodeTableJson
                  .Detailed.no_change),function(noChangeRaf,noChangeRafIndex){return _c('div',{key:noChangeRafIndex,staticClass:"right-alignment"},[(noChangeRaf.new_condition.length > 1)?_c('span',_vm._l((noChangeRaf.new_condition.length - 1),function(len){return _c('p',{key:len,staticClass:"hidden-block"},[_vm._v(" -- ")])}),0):_vm._e(),_c('p',[_vm._v(_vm._s(_vm._f("roundOff")(noChangeRaf.raf_change)))]),(_vm.compareCodeTableJson.Detailed.no_change.length >= 2)?_c('span',[(noChangeRaf != null)?_c('hr',{staticClass:"hr-style-raf-change",style:([
                      noChangeRafIndex ==
                      _vm.compareCodeTableJson.Detailed.no_change.length - 1
                        ? { display: 'none' }
                        : { display: 'block' },
                    ])}):_vm._e()]):_vm._e()])}),0),_c('td',_vm._l((_vm.compareCodeTableJson
                  .Detailed.no_change),function(noChangePre,noChangePreIndex){return _c('div',{key:noChangePreIndex,staticClass:"right-alignment"},[(noChangePre.new_condition.length > 1)?_c('span',_vm._l((noChangePre.new_condition.length - 1),function(len){return _c('p',{key:len,staticClass:"hidden-block"},[_vm._v(" -- ")])}),0):_vm._e(),_c('p',[_vm._v(_vm._s(_vm._f("toCurrency")(noChangePre.premium)))]),(_vm.compareCodeTableJson.Detailed.no_change.length >= 2)?_c('span',[(noChangePre != null)?_c('hr',{staticClass:"hr-style-pre-change",style:([
                      noChangePreIndex ==
                      _vm.compareCodeTableJson.Detailed.no_change.length - 1
                        ? { display: 'none' }
                        : { display: 'block' },
                    ])}):_vm._e()]):_vm._e()])}),0)]):_vm._e(),_c('tr',[_c('td',{staticClass:"left-alignment"},[_vm._v("Total")]),_c('td',{attrs:{"colspan":"2"}}),_c('td',{staticClass:"right-alignment"},[_vm._v(" "+_vm._s(_vm.compareCodeTableCalcDict.totalRafChange)+" ")]),_c('td',{staticClass:"right-alignment"},[_vm._v(" "+_vm._s(_vm._f("toCurrency")(_vm.compareCodeTableCalcDict.totalPremium))+" ")])])]):_vm._e()])])]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('th',{staticStyle:{"width":"67.3%"}},[_vm._v("Net Summary")]),_c('th',{staticStyle:{"width":"15.5%"}},[_vm._v("RAF")]),_c('th',{staticStyle:{"width":"16.8%"}},[_vm._v("Monthly Premium")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"nested-td table-arrow"},[_c('i',{staticClass:"fas fa-2x fa-long-arrow-alt-right"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"nested-td table-arrow"},[_c('i',{staticClass:"fas fa-2x fa-long-arrow-alt-right"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"nested-td table-arrow"},[_c('i',{staticClass:"fas fa-2x fa-long-arrow-alt-right"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"nested-td table-arrow"},[_c('i',{staticClass:"fas fa-2x fa-long-arrow-alt-right"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('th',{staticStyle:{"width":"24.3%"}},[_vm._v("Condition Differences")]),_c('th',{staticStyle:{"width":"13.2%"}},[_vm._v("Base Condition")]),_c('th',{staticStyle:{"width":"21.7%"}},[_vm._v("New Condition")]),_c('th',{staticStyle:{"width":"17.6%"}},[_vm._v("RAF Change")]),_c('th',{staticStyle:{"width":"22.2%"}},[_vm._v("Monthly Premium")])])
}]

export { render, staticRenderFns }