var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{class:[
      'header-main' +
        ' ' +
        (this.current_path == '/privacy'
          ? 'support header-change privacy'
          : this.current_path == '/eula'
          ? 'support eula header-change'
          : this.current_path.replace('/', '')) +
        ' ' +
        _vm.source,
    ],style:(_vm.getImgUrl)},[_c('div',{class:[
        'header-main-block' +
          ' ' +
          this.current_path.replace('/', '') +
          ' ' +
          _vm.source,
      ],style:(_vm.headerLogoStyle)},[(this.source !== 'patch')?_c('a',{class:['header-main-img ' + _vm.source],style:(_vm.logoStyle),attrs:{"href":"https://episource.com"}},[(this.source !== 'patch')?_c('img',{class:['header-main-img ' + _vm.source],attrs:{"src":require("../../assets/img/epi-logo.svg")}}):_vm._e()]):_vm._e(),_c('div',{class:['header-main-block-title ' + _vm.source]},[_c('a',{class:[
            'hexader-main-block-title ' +
              this.current_path.replace('/eula', 'eula'),
          ],attrs:{"href":_vm.current_path}},[_vm._v(" "+_vm._s(_vm.getImgUrl.hcc_text)+" ")])])]),(this.source !== 'patch')?_c('div',{class:['side-nav ' + this.current_path.replace('/', '')]},[(this.source !== 'patch')?_c('nav',{class:[
          'header-main-block-link' + ' ' + this.current_path.replace('/', ''),
        ]},[(
            this.source !== 'patch' &&
              this.current_path != '/reference' &&
              this.current_path != '/reference/' &&
              _vm.reference_host != 'www.hccreference.com' &&
              _vm.reference_host != 'hccreference.com'
          )?_c('a',{staticClass:"episource-home-link",attrs:{"href":_vm.reference_host == 'hccexplorer.com'
              ? 'https://www.hccreference.com/'
              : '/reference',"target":"_blank"}},[_c('p',{staticStyle:{"color":"#545454"},on:{"mouseover":function($event){return _vm.showGif('show_reference_gif')},"mouseleave":function($event){return _vm.hideGif('show_reference_gif')}}},[_vm._v(" HCC Reference ")])]):_vm._e(),_c('img',{class:[
            (_vm.show_reference_gif &&
            this.source !== 'patch' &&
            this.current_path != '/reference' &&
            _vm.reference_host != 'www.hccreference.com' &&
            _vm.reference_host != 'hccreference.com'
              ? 'tool-gif'
              : '') +
              ' gif reference-gif ' +
              this.current_path.replace('/', ''),
          ],staticStyle:{"opacity":"0"},attrs:{"src":require("../../assets/img/hcc_reference.gif"),"alt":"HCC Reference"}}),(
            this.source !== 'patch' &&
              (this.current_path != '/' ||
                _vm.reference_host == 'www.hccreference.com' ||
                _vm.reference_host == 'hccreference.com')
          )?_c('a',{staticClass:"episource-home-link",attrs:{"href":_vm.explorer_host == 'localhost'
              ? '/'
              : 'https://www.hccexplorer.com/',"target":"_blank"}},[_c('p',{staticStyle:{"color":"#545454"},on:{"mouseover":function($event){return _vm.showGif('show_explorer_gif')},"mouseleave":function($event){return _vm.hideGif('show_explorer_gif')}}},[_vm._v(" HCC Explorer ")])]):_vm._e(),_c('img',{class:[
            (_vm.show_explorer_gif &&
            this.source !== 'patch' &&
            (this.current_path != '/' ||
              _vm.reference_host == 'www.hccreference.com' ||
              _vm.reference_host == 'hccreference.com')
              ? 'tool-gif'
              : '') + ' gif explorer-gif',
          ],staticStyle:{"opacity":"0"},attrs:{"src":require("../../assets/img/hcc_explorer.gif"),"alt":"HCC Explorer"}}),(this.source !== 'patch')?_c('a',{staticClass:"episource-home-link",attrs:{"href":"https://appsource.microsoft.com/en-in/product/office/WA200001947","target":"_blank"}},[_c('p',{staticClass:"p-gif1",staticStyle:{"color":"#545454"},on:{"mouseover":function($event){return _vm.showGif('show_toolkit_gif')},"mouseleave":function($event){return _vm.hideGif('show_toolkit_gif')}}},[_vm._v(" RA Toolkit ")])]):_vm._e(),_c('img',{class:[
            (_vm.show_toolkit_gif && this.source !== 'patch' ? 'tool-gif' : '') +
              ' gif ' +
              this.current_path.replace('/', ''),
          ],staticStyle:{"opacity":"0"},attrs:{"src":require("../../assets/img/ra_toolkit.gif"),"alt":"RA Toolkit"}}),(this.current_path == '/support')?_c('span',{staticClass:"release-notes-title",on:{"click":function($event){return _vm.handleToggleModal()}}},[_vm._v("Release Notes")]):_vm._e()]):_vm._e()]):_vm._e(),(this.source !== 'patch')?_c('c-menu',{staticClass:"navbar-hamburger"},[_c('c-menu-button',{staticClass:"hamburger-button",attrs:{"px":"4","py":"2","transition":"all 0.2s","rounded":"md","border-width":"1px","_hover":{ bg: 'gray.100' },"_expanded":{ bg: 'red.100' },"_focus":{ outline: 0, boxShadow: 'outline' }}},[_c('v-app-bar-nav-icon')],1),_c('c-menu-list',{staticClass:"hamburger-list"},[_c('c-menu-item',{staticClass:"hamburger-item",style:([
            this.source !== 'patch' && this.current_path != '/reference'
              ? { display: 'flex' }
              : { display: 'none' },
          ])},[(
              this.source !== 'patch' && this.current_path != '/reference'
            )?_c('a',{staticClass:"episource-home-link",attrs:{"href":_vm.reference_host == 'localhost'
                ? '/reference'
                : 'https://www.hccreference.com/',"target":"_blank"}},[_c('p',{staticStyle:{"color":"#545454"}},[_vm._v(" HCC Reference ")])]):_vm._e()]),_c('c-menu-item',{staticClass:"hamburger-item",style:([
            this.source !== 'patch'
              ? { display: 'flex' }
              : { display: 'none' },
          ])},[(this.source !== 'patch')?_c('a',{staticClass:"episource-home-link",attrs:{"href":"https://appsource.microsoft.com/en-in/product/office/WA200001947","target":"_blank"}},[_c('p',{staticStyle:{"color":"#545454"}},[_vm._v(" RA Toolkit ")])]):_vm._e()]),_c('c-menu-item',{staticClass:"hamburger-item",style:([
            this.source !== 'patch' && this.current_path != '/'
              ? { display: 'flex' }
              : { display: 'none' },
          ])},[(this.source !== 'patch' && this.current_path != '/')?_c('a',{staticClass:"episource-home-link",attrs:{"href":_vm.explorer_host == 'localhost'
                ? '/'
                : 'https://www.hccexplorer.com/',"target":"_blank"}},[_c('p',{staticStyle:{"color":"#545454"}},[_vm._v(" HCC Explorer ")])]):_vm._e()]),_c('c-menu-item',{staticClass:"hamburger-item",style:([
            this.current_path == '/support'
              ? { display: 'flex' }
              : { display: 'none' },
          ])},[(this.current_path == '/support')?_c('span',{staticClass:"episource-home-link",on:{"click":function($event){return _vm.handleToggleModal()}}},[_vm._v("Release Notes")]):_vm._e()])],1)],1):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }