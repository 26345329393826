<template>
  <div
    class="summary"
    v-if="Object.keys(memberProfileTableJson).length != 0"
    :id="'member-result-table-' + componentDataIndex"
  >
    <div class="summary-table">
      <p class="summary-table-header">Summary</p>
      <table id="summary-table-1">
        <tr>
          <td class="summary-table-td-main">Demographic Risk Factor</td>
          <td class="summary-table-td">
            {{ memberProfileTableJson.raf.demo_score | roundOff }}
          </td>
        </tr>
        <tr>
          <td class="summary-table-td-main">HCC Risk factor</td>
          <td class="summary-table-td">
            {{ memberProfileTableJson.raf.hcc_score | roundOff }}
          </td>
        </tr>
        <tr>
          <td class="summary-table-td-main">Total Risk Score</td>
          <td class="summary-table-td">
            {{ memberProfileTableJson.raf.score | roundOff }}
          </td>
        </tr>
        <tr>
          <td class="summary-table-td-main">Total Monthly Premium</td>
          <td class="summary-table-td">
            {{ memberProfileTableJson.raf.premium | toCurrency }}
          </td>
        </tr>
      </table>
    </div>
    <div>
      <table id="summary-table-2">
        <tr>
          <th
            v-if="memberProfileTableJson.flag == 'dx'"
            class="member-header-th-left member-inner-th-individual"
          >
            Dx Code
          </th>
          <th
            v-if="memberProfileTableJson.flag == 'dx'"
            class="member-inner-th-individual"
            style="width:24%"
          >
            Dx Description
          </th>
          <th
            class="member-inner-th-individual"
            style="width:10%;height: 37px;"
            v-bind:class="{
              'member-header-th-left': memberProfileTableJson.flag != 'dx',
            }"
          >
            HCC
          </th>
          <th class="member-inner-th-individual" style="width:24%">
            HCC Description
          </th>
          <th class="member-inner-th-individual" style="width:11%">
            Trumped By
          </th>
          <th class="member-inner-th-individual" style="width:10%">RAF</th>
          <th class="member-header-th-right member-inner-th-individual">
            Monthly Premium
          </th>
        </tr>
        <tr
          v-for="(codes, index) in memberProfileTableJson.dx_hccs"
          :key="index"
        >
          <td v-if="memberProfileTableJson.flag == 'dx'">
            <div>{{ codes.dx_name }}</div>
          </td>
          <td v-if="memberProfileTableJson.flag == 'dx'">
            <div>{{ codes.dx_desc }}</div>
          </td>
          <td>
            <div
              v-for="(hcc, index_col_head) in codes.hcc_list"
              :key="index_col_head"
            >
              <p class="member-inner-left-td">{{ hcc.hcc_name }}</p>
              <span v-if="codes.dx_desc !== null && codes.hcc_list.length > 1">
                <hr class="hr-style-base" />
              </span>
            </div>
          </td>

          <td>
            <div
              v-for="(hcc, index_col_head) in codes.hcc_list"
              :key="index_col_head"
            >
              <p class="member-inner-left-td">{{ hcc.hcc_desc }}</p>
              <span v-if="codes.dx_desc !== null && codes.hcc_list.length > 1">
                <hr class="hr-style-base" />
              </span>
            </div>
          </td>

          <td>
            <div
              v-for="(hcc, index_col_head) in codes.hcc_list"
              :key="index_col_head"
            >
              <div
                class="member-inner-center-td"
                v-if="hcc.trumped_by.length == 0"
              >
                <p>--</p>
              </div>
              <div
                else
                class="member-inner-left-td"
                v-for="(trumping_hcc, index_of_tr) in hcc.trumped_by"
                :key="index_of_tr"
              >
                <p v-if="index_of_tr != hcc.trumped_by.length - 1">
                  {{ trumping_hcc + ',' }}
                </p>
                <p v-else>{{ trumping_hcc }}</p>
              </div>
              <span v-if="codes.dx_desc !== null && codes.hcc_list.length > 1">
                <hr class="hr-style-base" />
              </span>
            </div>
          </td>

          <td>
            <div
              v-for="(hcc, index_col_head) in codes.hcc_list"
              :key="index_col_head"
            >
              <p class="member-inner-right-td" v-if="hcc.hcc_raf != null">
                {{ hcc.hcc_raf | roundOff }}
              </p>
              <p class="member-inner-right-td" v-else>NA</p>
              <span v-if="codes.dx_desc !== null && codes.hcc_list.length > 1">
                <hr class="hr-style-base" />
              </span>
            </div>
          </td>

          <td>
            <div
              v-for="(hcc, index_col_head) in codes.hcc_list"
              :key="index_col_head"
            >
              <p class="member-inner-right-td" v-if="hcc.premium != null">
                {{ hcc.premium | toCurrency }}
              </p>
              <p class="member-inner-right-td" v-else>NA</p>
              <span v-if="codes.dx_desc !== null && codes.hcc_list.length > 1">
                <hr class="hr-style-base" />
              </span>
            </div>
          </td>
        </tr>
        <tr v-if="memberProfileTableJson.dx_hccs.length < 1">
          <td class="member-no-code-td" colspan="7" align="center">
            No Codes Entered
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>
<style scoped src="../../../assets/css/membertable.css"></style>

<script>
import store from '../../../store'
export default {
  props: {
    componentDataIndex: Number,
  },
  computed: {
    memberProfileTableJson: function() {
      return store.getters.getComponentDataByIndex(this.componentDataIndex)
        .responseData.data
    },
  },
  data() {
    return {}
  },
}
</script>
