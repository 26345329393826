var render = function render(){var _vm=this,_c=_vm._self._c;return _c('table',{staticClass:"table-style-main"},[(_vm.headers_required == true)?_c('tr',[_c('td',{staticClass:"excel-notations-cell"}),_vm._l((_vm.table_data[0]),function(data,key,index){return _c('td',{key:index,staticClass:"excel-notations-cell excel-notations-cell-alphabetical"},[_vm._v(" "+_vm._s(_vm.columnHeaders[index + Number(_vm.start_index)])+" ")])})],2):_vm._e(),_c('tr',{staticClass:"tr-style"},[(_vm.headers_required == true)?_c('td',{staticClass:"excel-notations-cell"},[_vm._v("1")]):_vm._e(),_vm._l((_vm.table_data[0]),function(data,key,index){return _c('th',{key:index,staticClass:"table-titles"},[_vm._v(" "+_vm._s(key === 'command' ? 'Functions' : key === 'argument' ? 'Arguments' : key)+" ")])})],2),_vm._l((_vm.table_data),function(data,index){return _c('tr',{key:data.name},[(_vm.headers_required == true)?_c('td',{staticClass:"excel-notations-cell excel-notations-cell-numeric"},[_vm._v(" "+_vm._s(index + 2)+" ")]):_vm._e(),_vm._l((_vm.table_data[0]),function(d,key,ind){return _c('td',{directives:[{name:"tippy",rawName:"v-tippy",value:(
        data[key] != null &&
        typeof data[key] == 'string' &&
        data[key].indexOf('=epi.') !== -1
          ? {
              theme: 'epicc',
              arrow: true,
              content:
                _vm.showCopyToolTip == false
                  ? 'Copy command to clipboard'
                  : 'Copied',
            }
          : {
              onshow: () => false,
            }
      ),expression:"\n        data[key] != null &&\n        typeof data[key] == 'string' &&\n        data[key].indexOf('=epi.') !== -1\n          ? {\n              theme: 'epicc',\n              arrow: true,\n              content:\n                showCopyToolTip == false\n                  ? 'Copy command to clipboard'\n                  : 'Copied',\n            }\n          : {\n              onshow: () => false,\n            }\n      "}],key:ind,class:[
        data[key] != null &&
        typeof data[key] == 'string' &&
        data[key].indexOf('=epi.') !== -1
          ? 'hover-cells'
          : '',
      ],on:{"click":function($event){;(_vm.showCopyToolTip = _vm.showCopyToolTip == false ? true : false) &&
          data[key] != null &&
          typeof data[key] == 'string' &&
          data[key].indexOf('=epi.') !== -1 &&
          _vm.copyToClipboard(data[key])},"mouseleave":function($event){_vm.showCopyToolTip = false}}},[_vm._v(" "+_vm._s(data[key])+" ")])})],2)})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }