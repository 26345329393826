<template>
  <v-layout>
    <v-select
      class="plateInput"
      dense
      flat
      outlined
      full-width
      :items="items"
      :v-model="enrollmentDuration"
      :value="enrollmentDuration"
      color="#0099d9"
      style="color: rgb(84, 84, 84) !important;"
      @change="enrollmentDurationUpdate"
    ></v-select>
  </v-layout>
</template>

<script>
import enrollmentData from '../../data/enrollmentData.json'

export default {
  props: {
    componentDataIndex: Number,
    enrollmentDuration: String,
  },

  data() {
    return {
      items: enrollmentData,
      enrollmentValue: this.enrollmentDuration,
    }
  },
  methods: {
    enrollmentDurationUpdate(value) {
      this.$emit('updateEnrollmentValue', value)
    },
  },
}
</script>
