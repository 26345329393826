<template>
  <div class="bulkResult__wrapper">
    <h2>{{ _heading }}</h2>
    <table class="bulkResult__table">
      <thead class="bulkResult__head">
        <th class="bulkResult__headers">Commands</th>
        <th class="bulkResult__headers">Arguments</th>
        <th class="bulkResult__headers">Result</th>
      </thead>
      <tbody class="bulkResult__body">
        <tr
          class="bulkResult__row"
          v-for="(result, idx) in bulkResultData"
          :key="idx"
        >
          <td class="bulkResult__data">
            {{ result.commands }}
          </td>
          <td class="bulkResult__data">
            {{ result.arguments }}
          </td>
          <td class="bulkResult__data">
            {{ result.result }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<style src="../../../assets/css/BulkResult.css" scoped></style>

<script>
import bulkResultData from '../../../data/bulkResultData.json'

export default {
  props: {
    heading: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      bulkResultData: bulkResultData,
    }
  },
  computed: {
    _heading() {
      return `${this.heading} ${this.$route.params.id}`
    },
  },
  created() {
    //create module
  },
}
</script>
