<template>
  <div>
    <div class="main pt-5 compare-box">
      <div class="compare-box-between">
        <div class="compare-box-label condition-text">
          Base Conditions
          <v-icon
            color="black"
            class="p-1"
            size="12"
            dark
            v-tippy="{
              theme: 'epicc',
              arrow: true,
              content: tooltipDataForBaseCondition,
            }"
            >fas fa-question-circle</v-icon
          >
        </div>
        <div class="conditioninput-1">
          <div style="position: relative">
            <codeinput
              id="code-input-1"
              ref="revalidateCodeInput"
              :tagList="compareCodeData.inputDict.baseConditions"
              searchBox="tag1"
              :tagstring="tag1"
              :componentDataIndex="componentDataIndex"
              :riskModel="compareCodeData.inputDict.riskModel"
              :dosYearModel="compareCodeData.inputDict.dosYearModel"
              v-on:getTag="updateTagLists($event, 'baseConditions')"
              v-on:removeTag="updateTagLists($event, 'baseConditions')"
              :isClearTag="isClear"
              :isEmptyTagList="isBaseTagListEmpty"
              :commandType="compareCodeData.inputDict.commandType"
              v-on:clearTagFlag="isClear = false"
              v-on:invalidChips="getInvalidChips($event, baseInvalidChips)"
              v-on:emptyTagsFlag="isBaseTagListEmpty = false"
            ></codeinput>
            <div
              v-if="this.$store.state.search_box == 'tag1'"
              class="member-search-suggestion"
              :class="[
                this.$store.state.search_member.length > 0 &&
                this.$store.state.search_box == 'tag1'
                  ? 'suggestion-box'
                  : '',
                (this.$store.state.search_suggestion_loader &&
                  this.$store.state.search_box == 'tag1') == true
                  ? 'show-box'
                  : '',
              ]"
            >
              <v-progress-circular
                indeterminate
                color="red"
                size="32"
                class="compare-code-loader"
                v-if="this.$store.state.search_suggestion_loader == true"
              ></v-progress-circular>
              <ul v-if="this.$store.state.search_box == 'tag1'">
                <li
                  v-for="item in this.$store.state.search_member"
                  :key="item"
                  @click="addTagFromList(item, 'tag1')"
                >
                  {{ item }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="compare-box-between">
        <div class="compare-box-label condition-text">
          New Conditions
          <v-icon
            tabindex="-1"
            color="black"
            class="p-1"
            size="12"
            dark
            v-tippy="{
              theme: 'epicc',
              arrow: true,
              content: tooltipDataForNewCondition,
            }"
            >fas fa-question-circle</v-icon
          >
        </div>
        <div class="conditioninput-2">
          <!-- New Condition input field -->
          <div style="position: relative;">
            <codeinput
              ref="revalidateCodeInput"
              :tagList="compareCodeData.inputDict.newConditions"
              searchBox="tag2"
              :tagstring="tag2"
              :componentDataIndex="componentDataIndex"
              :riskModel="compareCodeData.inputDict.riskModel"
              :dosYearModel="compareCodeData.inputDict.dosYearModel"
              v-on:getTag="updateTagLists($event, 'newConditions')"
              v-on:removeTag="updateTagLists($event, 'newConditions')"
              :isClearTag="isClear2"
              v-on:clearTagFlag="isClear2 = false"
              :isEmptyTagList="isNewTagListEmpty"
              :commandType="compareCodeData.inputDict.commandType"
              v-on:invalidChips="getInvalidChips($event, newInvalidChips)"
              v-on:emptyTagsFlag="isNewTagListEmpty = false"
            ></codeinput>
            <div
              v-if="this.$store.state.search_box == 'tag2'"
              class="member-search-suggestion"
              :class="[
                this.$store.state.search_member.length > 0 &&
                this.$store.state.search_box == 'tag2'
                  ? 'suggestion-box'
                  : '',
                (this.$store.state.search_suggestion_loader &&
                  this.$store.state.search_box == 'tag2') == true
                  ? 'show-box'
                  : '',
                this.$store.state.componentData[0].search_box,
              ]"
            >
              <v-progress-circular
                indeterminate
                color="red"
                size="32"
                class="compare-code-loader"
                v-if="this.$store.state.search_suggestion_loader == true"
              ></v-progress-circular>
              <ul>
                <li
                  v-for="item in this.$store.state.search_member"
                  :key="item"
                  @click="addTagFromList(item, 'tag2')"
                >
                  {{ item }}
                </li>
              </ul>
            </div>
          </div>

          <v-dialog v-model="dialog" max-width="80%">
            <search v-model="dialog" v-on:confirmAction="toggleModal(false)" />
          </v-dialog>
        </div>
      </div>
      <div class="compare-box-between">
        <div class="compare-box-label agelabel">
          Age:
          <v-icon
            color="black"
            class="p-1"
            size="12"
            dark
            v-tippy="{
              theme: 'epicc',
              arrow: true,
              content: tooltipDataForAge,
            }"
            >fas fa-question-circle</v-icon
          >
        </div>
        <div class="ageinput">
          <ageField
            v-on:onAge="getAge"
            :componentDataIndex="componentDataIndex"
            :age="compareCodeData.inputDict.age"
            :isAgeInvalid="isAgeInvalid"
            v-on:validAge="isAgeInvalid = false"
          />
        </div>
        <div class="dosyearinput compare-box-sec-row">
          <div class="compare-box-label">
            Risk Model:
            <v-icon
              color="black"
              class="p-1"
              size="12"
              dark
              v-tippy="{
                theme: 'epicc',
                arrow: true,
                content: tooltipDataForRiskModel,
              }"
              >fas fa-question-circle</v-icon
            >
          </div>
          <div class="dRiskinput">
            <riskModelField
              :dosYear="compareCodeData.inputDict.dosYearModel"
              v-on:onRiskModel="getRiskModel"
              :componentDataIndex="componentDataIndex"
              :riskModel="compareCodeData.inputDict.riskModel"
            />
          </div>
        </div>
      </div>
      <div class="compare-box-between compare-code-dos-year">
        <div class="compare-box-label">DOS Year/Model:</div>
        <dosYearModel
          v-on:onDosYear="getDosYear"
          :componentDataIndex="componentDataIndex"
          :dosYearModel="compareCodeData.inputDict.dosYearModel"
        />
        <div class="baserateinput compare-box-sec-row">
          <div class="compare-box-label">
            Base Rate :
            <v-icon
              color="black"
              class="p-1"
              size="12"
              dark
              v-tippy="{
                theme: 'epicc',
                arrow: true,
                content: tooltipDataForBaserate,
              }"
              >fas fa-question-circle</v-icon
            >
          </div>
          <baseRateInput
            v-on:onBaseRate="getBaseRate"
            :componentDataIndex="componentDataIndex"
            :baseRate="compareCodeData.inputDict.baseRate"
            v-on:validBaseRate="isBaseRateInvalid = false"
            :isBaseRateInvalid="isBaseRateInvalid"
          />
        </div>
      </div>
      <div class="compare-box-between">
        <div class="compare-box-label agelabel">Gender:</div>
        <div class="ageinput">
          <gender
            :gender="compareCodeData.inputDict.gender"
            :componentDataIndex="componentDataIndex"
            v-on:onGender="setGender"
          />
        </div>
      </div>
      <div class="compare-box-between">
        <div class="compare-box-label platelabel">
          Plate:
          <v-icon
            color="black"
            class="p-1"
            size="12"
            dark
            v-tippy="{
              theme: 'epicc',
              arrow: true,
              content: tooltipDataForPlate,
            }"
            >fas fa-question-circle</v-icon
          >
        </div>
        <div class="box dosyearinput">
          <plateInput
            :plateValue="compareCodeData.inputDict.plate"
            v-on:updatePlateValue="setPlateValue"
          />
        </div>
      </div>

      <div class="compare-box-between">
        <div class="compare-box-label platelabel">Enrollment Duration:</div>
        <div class="box enrollmentinput">
          <enrollmentInput
            :enrollmentDuration="compareCodeData.inputDict.enrollmentDuration"
            v-on:updateEnrollmentValue="setEnrollmentValue"
          />
        </div>
      </div>
      <div class="compare-box-between">
        <div class="compare-box-label platelabel">
          Date Of Birth:
          <v-icon
            color="black"
            class="p-1"
            size="12"
            dark
            v-tippy="{
              theme: 'epicc',
              arrow: true,
              content: tooltipDataForDob,
            }"
            >fas fa-question-circle</v-icon
          >
        </div>
        <div class="box enrollmentinput">
          <dobInput
            :calculationYear="compareCodeData.inputDict.calculationYear"
            :dateOfBirthValue="compareCodeData.inputDict.DOB"
            v-on:dateOfBirthUpdate="setDateOfBirth"
          />
        </div>
      </div>
      <div class="compare-box-between compare-code-calculation-year">
        <div class="compare-box-label agelabel">
          Calculation Year:
          <v-icon
            color="black"
            class="p-1"
            size="12"
            dark
            v-tippy="{
              theme: 'epicc',
              arrow: true,
              content: tooltipDataForCalculationYear,
            }"
            >fas fa-question-circle</v-icon
          >
        </div>
        <div class="ageinput">
          <calculationYearInput
            :error="dobErrorMessage"
            :dob="compareCodeData.inputDict.DOB"
            :calculationYear="compareCodeData.inputDict.calculationYear"
            :componentDataIndex="componentDataIndex"
            v-on:calculationYearUpdate="setCalculationYear"
          />
        </div>
      </div>

      <div class="submitclear submitclear-compare-code">
        <v-btn class="submit button" @click="submit">Submit</v-btn>
        <v-btn class="clear button" @click="clearResult">Clear</v-btn>
        <v-btn
          ref="addBtn"
          :class="[
            'add button ' +
              (componentDataIndex ==
                this.$store.state.componentData.length - 1 &&
              this.$store.state.componentData[componentDataIndex]
                .isSubmitButtonClicked
                ? 'showBtn'
                : ''),
          ]"
          @click="addRow"
          >Add new</v-btn
        >
        <v-btn
          v-if="componentDataIndex !== 0"
          class="close button"
          @click="deleteRow()"
        >
          <v-icon
            color="white"
            class="p-1"
            size="20"
            dark
            v-tippy="{
              theme: 'epicc',
              arrow: true,
              content: 'Close',
            }"
            >fas fa-trash</v-icon
          ></v-btn
        >
      </div>
      <div class="compare-code-summary">
        <v-snackbar v-model="snackbar" class="snackbar">
          <div class="snackbar-text">
            {{ errorMessage }}
          </div>
          <v-btn color="pink" text @click="snackbar = false">Close</v-btn>
        </v-snackbar>
        <div class="progress-bar">
          <v-progress-circular
            v-show="isWaitingForResult"
            color="light-blue"
            indeterminate
            rounded
            height="6"
          ></v-progress-circular>
        </div>

        <resultTable
          v-if="APIResponse.length != 0"
          class="compare-result"
          :componentDataIndex="componentDataIndex"
        />
        <v-btn
          class="output-btn"
          color="grey"
          @click="
            ;(isDetailButtonVisible = false), (isCloseButtonVisible = true)
          "
          v-if="isDetailButtonVisible"
          >Detailed Output</v-btn
        >
        <v-btn
          class="output-btn"
          color="grey"
          @click="
            ;(isDetailButtonVisible = true), (isCloseButtonVisible = false)
          "
          v-if="isCloseButtonVisible"
          >Close</v-btn
        >
      </div>
    </div>
  </div>
</template>
<style scoped src="../../../assets/css/compare.css"></style>
<script>
import CodeInput from '../../common/CodeInput.vue'
import AgeInput from '../../common/AgeInput.vue'
import RiskModel from '../../common/RiskModel.vue'
import DosYearModel from '../../common/DosYearModel.vue'
import BaseRateInput from '../../common/BaseRateInput.vue'
import SearchComponent from '../../common/SearchComponent.vue'
import store from '../../../store'
import CompareCodeResultTable from '../results/CompareCodeResultTable.vue'
import CalculationYearInput from '../../common/CalculationYearInput'
import DOBInput from '../../common/DOBInput'
import Gender from '../../common/Gender.vue'
import PlateInput from '../../common/PlateInput'
import EnrollmentInput from '../../common/EnrollmentInput'
import moment from 'moment'
import {
  scrollToBottom,
  scrollToBottomContainer,
} from '../../common/helperFunctions'
export default {
  props: {
    componentDataIndex: Number,
  },
  computed: {
    compareCodeData: function() {
      return store.getters.getComponentDataByIndex(this.componentDataIndex)
    },
    APIResponse: function() {
      return this.compareCodeData.responseData.data
    },
  },
  data() {
    return {
      tag1: 'asda',
      tag2: 'asdsadsd',
      isClear: false,
      isClear2: false,
      calculationYear: Number,
      gender: String,
      DOB: String,
      plate: String,
      dosYear: String,
      enrollmentDuration: String,
      newConditionTagList: [],
      baseConditionTagList: [],
      dialog: false,
      isDetailButtonVisible: false,
      isCloseButtonVisible: false,
      tooltipDataForBaseCondition:
        'Conditions eligible as per selected dos year model.',
      tooltipDataForNewCondition:
        'List of codes against which comparison is to be made',
      tooltipDataForBaserate: 'Base reimbursement premium for member',
      snackbar: false,
      tooltipDataForCalculationYear:
        '1st Jan of the input calculation year will be used along with DOB to calculate age',
      tooltipDataForRiskModel:
        'List of risk models eligible as per selected dos year model',
      tooltipDataForDob:
        'DOB will not be considered in any calculation(s) if age is provided',
      tooltipDataForAge: 'Acceptable age values are from 0 to 110',
      errorMessage: '',
      baseInvalidChips: [],
      newInvalidChips: [],
      invalidTags: [],
      isBaseTagListEmpty: false,
      isNewTagListEmpty: false,
      isAgeInvalid: false,
      isBaseRateInvalid: false,
      isWaitingForResult: false,
      HHS_DEFAULT_RISK_MODEL: 'ADULT',
      V05_DEFAULT_RISK_MODEL: 'D1',
      V_DEFAULT_RISK_MODEL: 'CN',
      V24_ESRD_DEFAULT_RISK_MODEL: 'C3',
      V21_ESRD_DEFAULT_RISK_MODEL: 'C1',
      PACE_V21_ESRD_DEFAULT_RISK_MODEL: 'PA',
      PACE_V_DEFAULT_RISK_MODEL: 'PK',
      PACE_V05_DEFAULT_RISK_MODEL: 'P7',
      tooltipDataForPlate:
        'Applicable only when the Commercial Model is in use',
      DobMoment: String,
      dobErrorMessage: null,
      tag: '',
    }
  },
  components: {
    codeinput: CodeInput,
    ageField: AgeInput,
    gender: Gender,
    riskModelField: RiskModel,
    dosYearModel: DosYearModel,
    baseRateInput: BaseRateInput,
    search: SearchComponent,
    resultTable: CompareCodeResultTable,
    calculationYearInput: CalculationYearInput,
    dobInput: DOBInput,
    plateInput: PlateInput,
    enrollmentInput: EnrollmentInput,
  },
  methods: {
    updateTagLists(newTagList, listName) {
      if (listName === 'baseConditions') {
        this.baseConditionTagList = newTagList
        store.dispatch('updateStateData', {
          index: this.componentDataIndex,
          data: { name: 'baseConditions', value: this.baseConditionTagList },
        })
      } else {
        this.newConditionTagList = newTagList
        store.dispatch('updateStateData', {
          index: this.componentDataIndex,
          data: { name: 'newConditions', value: this.newConditionTagList },
        })
      }
    },
    getInvalidChips(Chips, chipType) {
      Chips.forEach((chip) => {
        chipType.push(chip)
      })
    },
    setFieldValue(fieldValue, fieldName) {
      let currentIndex = this.componentDataIndex
      let dateOfBirthData = {
        name: fieldName,
        value: fieldValue,
      }
      this.updateCommandInputValue(currentIndex, dateOfBirthData)
      let nextIndex = currentIndex + 1
      if (this.isNextPickCommand(nextIndex)) {
        this.updateCommandInputValue(nextIndex, dateOfBirthData)
      }
    },
    setEnrollmentValue(value) {
      this.enrollmentDuration = value
      this.setFieldValue(value, 'enrollmentDuration')
    },
    calculateAge(date, calculationYear) {
      let parseDate = new Date(date)
      let dobYear = parseDate.getFullYear() // get the year of date of birth
      let dobMonth = parseDate.getMonth() + 1 // get the month of DOB
      let dobDay = parseDate.getDate() // get the Date of DOB
      var dobDate = moment([dobYear, dobMonth, dobDay])

      let currYear = new Date().getFullYear()
      let currMonth = new Date().getMonth() + 1
      let currDay = new Date().getDate()
      /*
       * if difference between Dob and calculation year is 0
       * then substract DOB to current time
       * else substract DOB to 1st of january of the calculation year
       */
      var calculationYearDate = moment([calculationYear, '01', '01'])
      let diffYear = Math.abs(calculationYearDate.diff(dobDate, 'year', false))
      if (dobYear === calculationYear) {
        calculationYearDate = moment([currYear, currMonth, currDay])
      }
      let diffMonth = Math.abs(
        calculationYearDate.diff(dobDate, 'month', false)
      )
      /**
       * year greater than 1 should not have decimal values.
       * if year is equal to 0 then month should be returned having two decimal places.
       */
      if (diffYear > 0) {
        return Math.trunc(diffYear)
      } else if (diffMonth > 0) {
        return (diffMonth / 12).toFixed(2)
      } else return
    },
    setCalculatedAge(calculatedAge, calculationYear) {
      let yearInDob = new Date(this.DobMoment).getFullYear()
      if (calculatedAge && yearInDob < calculationYear) {
        this.dobErrorMessage = null
        this.getAge(calculatedAge)
      } else if (yearInDob >= calculationYear) {
        this.dobErrorMessage =
          'Calculation year should be greater than DOB year.'
        this.getAge(0)
      }
    },
    setDateOfBirth(date, dateForMoment) {
      this.DOB = date
      this.DobMoment = dateForMoment
      this.setFieldValue(date, 'DOB')
      let calculatedAge = this.calculateAge(
        dateForMoment,
        this.compareCodeData.inputDict.calculationYear
      )
      this.setCalculatedAge(
        calculatedAge,
        this.compareCodeData.inputDict.calculationYear
      )
    },
    setGender(value) {
      this.gender = value
      this.setFieldValue(value, 'gender')
    },
    setPlateValue(value) {
      this.plate = value
      this.setFieldValue(value, 'plate')
    },
    setCalculationYear(value) {
      this.calculationYear = value
      let calculatedAge = this.calculateAge(this.DobMoment, value)
      this.setCalculatedAge(calculatedAge, value)
      this.setFieldValue(value, 'calculationYear')
    },
    getAge(value) {
      this.age = value
      let currentIndex = this.componentDataIndex
      let ageData = { name: 'age', value: this.age }
      this.updateCommandInputValue(currentIndex, ageData)
      let nextIndex = currentIndex + 1
      if (this.isNextPickCommand(nextIndex)) {
        this.updateCommandInputValue(nextIndex, ageData)
      }
    },
    getBaseRate(value) {
      this.baseRate = value
      let currentIndex = this.componentDataIndex
      let baseRateData = { name: 'baseRate', value: this.baseRate }
      this.updateCommandInputValue(currentIndex, baseRateData)
      let nextIndex = currentIndex + 1
      if (this.isNextPickCommand(nextIndex)) {
        this.updateCommandInputValue(nextIndex, baseRateData)
      }
    },
    setDefaultRiskModel(value) {
      var defaultRiskModelValue = ''
      if (store.getters.hasHhsData(value)) {
        defaultRiskModelValue = this.HHS_DEFAULT_RISK_MODEL
      } else if (store.getters.hasV05Data(value)) {
        defaultRiskModelValue = this.V05_DEFAULT_RISK_MODEL
      } else if (store.getters.hasv21ESRDData(value)) {
        defaultRiskModelValue = this.V21_ESRD_DEFAULT_RISK_MODEL
      } else if (store.getters.hasv24ESRDData(value)) {
        defaultRiskModelValue = this.V24_ESRD_DEFAULT_RISK_MODEL
      } else if (store.getters.hasPaceV21ESRDData(value)) {
        defaultRiskModelValue = this.PACE_V21_ESRD_DEFAULT_RISK_MODEL
      } else if (store.getters.hasPaceVData(value)) {
        defaultRiskModelValue = this.PACE_V_DEFAULT_RISK_MODEL
      } else if (store.getters.hasPaceV05Data(value)) {
        defaultRiskModelValue = this.PACE_V05_DEFAULT_RISK_MODEL
      } else {
        defaultRiskModelValue = this.V_DEFAULT_RISK_MODEL
      }
      this.getRiskModel(defaultRiskModelValue)
    },
    getDosYear(value) {
      this.dosYear = value
      this.setDefaultRiskModel(value)
      let currentIndex = this.componentDataIndex
      let dosYearData = { name: 'dosYearModel', value: this.dosYear }
      this.updateCommandInputValue(currentIndex, dosYearData)
      let nextIndex = currentIndex + 1
      if (this.isNextPickCommand(nextIndex)) {
        this.updateCommandInputValue(nextIndex, dosYearData)
      }
      this.$refs.revalidateCodeInput.revalidateTags(this.dosYear)
    },
    getRiskModel(value) {
      this.riskModel = value
      let currentIndex = this.componentDataIndex
      let riskModelData = { name: 'riskModel', value: this.riskModel }
      this.updateCommandInputValue(currentIndex, riskModelData)
      let nextIndex = currentIndex + 1
      if (this.isNextPickCommand(nextIndex)) {
        this.updateCommandInputValue(nextIndex, riskModelData)
      }
    },
    isNextPickCommand(nextIndex) {
      let nextComponentData = store.getters.getComponentDataByIndex(nextIndex)
      if (typeof nextComponentData != 'undefined') {
        return nextComponentData.inputDict.commandType == 'pick' ? true : false
      }
    },
    updateCommandInputValue(index, InputData) {
      store.dispatch('updateStateData', {
        index: index,
        data: InputData,
      })
    },
    isTagInvalid(tag) {
      this.invalidTags = [...this.baseInvalidChips, ...this.newInvalidChips]
      return this.invalidTags.includes(tag) ? true : false
    },
    submit() {
      if (this.areAllTagsInvalid()) {
        this.errorMessage = 'All condition codes are invalid'
        this.snackbar = true
      } else if (!this.areInputsInvalid()) {
        this.isWaitingForResult = true
        store
          .dispatch('handleSubmitRequest', {
            index: this.componentDataIndex,
          })
          .then((this.isWaitingForResult = false))
      }
      setTimeout(() => scrollToBottom('compare', this.componentDataIndex), 500)
    },
    addRow() {
      store.dispatch('addRowToComponentData', {
        index: this.componentDataIndex,
      })
      setTimeout(
        () => scrollToBottomContainer(this.componentDataIndex + 1),
        300
      )
    },
    areInputsInvalid() {
      this.isAgeInvalid = this.age < 0 || this.age > 110 ? true : false
      this.isBaseRateInvalid =
        this.baseRate < 0 || isNaN(this.baseRate) ? true : false
      this.isTagListEmpty()
      return this.isAgeInvalid ||
        this.isBaseRateInvalid ||
        this.isTagListEmpty()
        ? true
        : false
    },
    areAllTagsInvalid() {
      return !this.areInputsInvalid() &&
        !this.newConditionTagList.every(this.isTagInvalid) &&
        !this.baseConditionTagList.every(this.isTagInvalid)
        ? false
        : true
    },
    isTagListEmpty() {
      this.isBaseTagListEmpty =
        this.baseConditionTagList.length == 0 ? true : false
      this.isNewTagListEmpty =
        this.newConditionTagList.length == 0 ? true : false
      return this.isBaseTagListEmpty || this.isNewTagListEmpty ? true : false
    },
    clearResult() {
      this.isClear = true
      this.isClear2 = true
      this.isDetailButtonVisible = false
      this.newConditionTagList = []
      this.baseConditionTagList = []
      this.isBaseTagListEmpty = false
      this.isNewTagListEmpty = false
      this.isAgeInvalid = false
      this.isBaseRateInvalid = false
      this.isWaitingForResult = false

      store.dispatch('updateStateData', {
        index: this.componentDataIndex,
        data: { name: 'baseConditions', value: this.baseConditionTagList },
      })

      store.dispatch('updateStateData', {
        index: this.componentDataIndex,
        data: { name: 'newConditions', value: this.newConditionTagList },
      })

      store.dispatch('clearResult', {
        index: this.componentDataIndex,
        commandType: 'compare',
      })
      this.$refs.revalidateCodeInput.resetInvalidChips()
    },
    deleteRow() {
      store.dispatch('deleteRow', {
        index: this.componentDataIndex,
      })
    },
    toggleModal(value) {
      this.dialog = value
      let SearchedCodeString = (this.searchData = localStorage.getItem(
        'selectedSearchData'
      ))
      if (SearchedCodeString != null) {
        let SearchedCodeList = SearchedCodeString.split(',')
        SearchedCodeList.forEach(
          function(item) {
            if (!this.tags.includes(item) && item.length > 0) {
              this.tags.push(item)
            }
          }.bind(this)
        )
        // reset localstorage here
        localStorage.removeItem('selectedSearchData')
        this.updateNewConditionsData()
      }
    },
    splitAndGetNewConditionValue(textvalue, separators = [' ', ',', ':', ';']) {
      if (textvalue != null) {
        let tempChar = separators[0] // We can use the first token as a temporary join character
        for (let i = 1; i < separators.length; i++) {
          textvalue = textvalue.split(separators[i]).join(tempChar)
        }
        this.newConditionsList = textvalue.split(tempChar)
      }
    },
    addTagFromList(item, tagNum) {
      if (tagNum == 'tag1') {
        this.$root.$refs.CodeInput1.tag = item
        let keyevent = {
          key: 'Enter',
        }
        this.$root.$refs.CodeInput1.addTag(keyevent)
      } else {
        this.$root.$refs.CodeInput2.tag = item
        let keyevent = {
          key: 'Enter',
        }
        this.$root.$refs.CodeInput2.addTag(keyevent)
      }
    },
  },
}
</script>

<style>
.snackbar > div > div {
  display: flex;
  align-content: center;
  justify-content: space-around;
}
.snackbar-text {
  display: flex;
  align-items: center;
  justify-content: center;
}
.compare-code-summary {
  grid-column-start: 1;
  grid-column-end: 5;
}
.submitclear-compare-code {
  grid-column-start: 1;
  grid-column-end: 5;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 9px;
}
@media only screen and (max-device-width: 1024px) {
  .compare-result {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .summary-table {
    margin: 0px !important;
    padding: 0px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    flex-direction: column !important;
    gap: 10px;
  }
  .summary-table-2 {
    margin: 0px !important;
    width: 100% !important;
  }
  .detail-box {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    flex-direction: column !important;
    margin-top: 15px !important;
  }
  .detail-table {
    width: 100%;
    overflow-x: scroll;
  }
  .submitclear-compare-code {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 9px;
  }
  .submitclear-compare-code > button {
    margin: 0px !important;
    font-size: 12px !important;
    width: 75px !important;
  }
  .detail-button > button {
    min-width: 85px !important;
    font-size: 12px !important;
    height: 40px !important;
  }
  .summary-table-header {
    width: 100% !important;
  }
}
</style>
