<template>
  <v-layout>
    <v-text-field
      class="agetextfield"
      dense
      flat
      outlined
      :value="age"
      @input="getAge"
      color="#0099d9"
      type="number"
      :error-messages="invalidAge ? ['Enter valid age'] : []"
      autocomplete="off"
    ></v-text-field>
  </v-layout>
</template>
<script>
export default {
  props: {
    age: Number,
    isAgeInvalid: Boolean,
  },
  computed: {
    invalidAge: {
      get: function() {
        this.age >= 0 && this.age <= 110 ? this.$emit('validAge', true) : false
        return this.isAgeInvalid
      },
      set: function(value) {
        this.isAgeInvalid = value
      },
    },
  },
  mounted() {
    this.$emit('onAge', parseInt(this.age))
  },
  data() {
    return {
      errorMessage: 'Enter valid age',
    }
  },
  methods: {
    getAge(value) {
      this.$emit('onAge', parseInt(value))
      if (!isNaN(parseInt(value))) {
        this.age =
          !isNaN(parseInt(value)) &&
          parseInt(value) >= 0 &&
          parseInt(value) <= 110
            ? parseInt(value)
            : 0
        value = this.age
        this.$emit('onAge', parseInt(value))
      }
    },
  },
}
</script>
