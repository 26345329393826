import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import BootstrapVue from 'bootstrap-vue'
import vuetify from './plugins/vuetify'
import VueTippy, { TippyComponent } from 'vue-tippy'
import Vuex from 'vuex'
import VueMoment from 'vue-moment'
import moment from 'moment'
import Chakra, { CThemeProvider } from '@chakra-ui/vue'
import 'tippy.js/themes/light.css'
import 'tippy.js/themes/light-border.css'
import 'tippy.js/themes/google.css'
import 'tippy.js/themes/translucent.css'
import '../src/assets/css/__global__.css'
import _ from 'lodash'
import '@mdi/font/css/materialdesignicons.css'
// Sentry
import * as Sentry from '@sentry/browser'
import * as Integrations from '@sentry/integrations'

Vue.use(Chakra)

Sentry.init({
  dsn: process.env.VUE_APP_SENTRY_DSN,
  integrations: [new Integrations.Vue({ Vue, attachProps: true })],
})

Object.defineProperty(Vue.prototype, '$_', {
  value: _,
})
Vue.use(VueAxios, axios)
Vue.use(BootstrapVue)
Vue.use(VueMoment, {
  moment,
})

Vue.use(Vuex)
Vue.config.productionTip = false

Vue.filter('toCurrency', function(value) {
  /* filter to convert premium to currency format
  Example : input : 2500000, output: $2,500,000 
  */
  value = Number(value)
  var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
  })
  return formatter.format(value)
})
Vue.filter('roundOff', function(number) {
  return Number.isInteger(number) ? number : Number(number).toFixed(3)
})
Vue.component('tippy', TippyComponent)
Vue.use(VueTippy, {
  directive: 'tippy', // => v-tippy
})

Vue.component('tippy', TippyComponent)

new Vue({
  router,
  store,
  CThemeProvider,
  vuetify,
  render: (h) => h(App),
}).$mount('#app')
