<template>
  <div>
    <v-snackbar v-model="snackbar" :timeout="timeout" class="snackbar">
      <div class="snackbar-text">{{ errorMessage }}</div>
      <v-btn color="pink" text @click="updateAPIErrorFlag">Close</v-btn>
    </v-snackbar>
    <div v-for="(i, index) in $store.state.componentData" :key="index">
      <pickCommand :componentDataIndex="index" />
    </div>
    <!-- footer here -->
    <MainFooter />
  </div>
</template>

<script>
import PickCommand from '../main/PickCommand.vue'
import MainFooter from '../common/MainFooter.vue'
export default {
  data() {
    return {
      errorMessage: 'Something went wrong! Please try later',
      timeout: 4000,
    }
  },
  components: {
    pickCommand: PickCommand,
    MainFooter: MainFooter,
  },
  computed: {
    snackbar: {
      get: function() {
        return this.$store.state.APIErrorFlag
      },
      set: function(value) {
        return value
      },
    },
  },
  methods: {
    updateAPIErrorFlag() {
      this.$store.commit('setAPIErrorFlag', false)
    },
  },
}
</script>

<style>
.tippy-tooltip.epicc-theme {
  background-color: #0099d9;
  /* border: 2px solid orange; */
  font-family: 'sofia-pro';
  color: white;
}
.tippy-tooltip.epicc-theme .tippy-arrow {
  border-top: 7px solid #0099d9;
}

.tippy-tooltip.epicc-green-theme {
  background-color: #008c50;
  font-family: 'sofia-pro';
}

.tippy-tooltip.epicc-green-theme .tippy-arrow {
  border-top: 7px solid#008C50;
  font-family: 'sofia-pro';
}

.dx-hcc-reference {
  /* display: grid; */
  grid-template-columns: repeat(10, 10%);
  grid-template-rows: repeat(3, 10px);
}

.close-btn {
  padding-bottom: -100px;
}
.snackbar > div > div {
  display: flex;
  align-content: center;
  justify-content: space-between;
  padding-right: 0px !important;
}
.snackbar-text {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
