<template>
  <div :class="['profile-main profile-main-' + this.componentDataIndex]">
    <v-row class="box-header show-tabs" v-if="this.showTabs">
      <v-col
        cols="4"
        sm="2"
        :class="[
          'select-button show-tabs member-tab ' +
            ($store.state.componentData[this.componentDataIndex].inputDict
              .commandType == 'member'
              ? 'active'
              : ''),
        ]"
        @click="updateCommandAndStore('member')"
      >
        <p class="dropdown-member">Member Profile</p>
      </v-col>

      <v-col
        cols="4"
        sm="2"
        :class="[
          'select-button show-tabs compare-tab ' +
            ($store.state.componentData[this.componentDataIndex].inputDict
              .commandType == 'compare'
              ? 'active'
              : ''),
        ]"
        @click="updateCommandAndStore('compare')"
      >
        <p class="dropdown-member">Compare codes</p>
      </v-col>
    </v-row>
    <v-row class="box-header small" v-else>
      <v-col cols="1" sm="3" class="select-button">
        <v-select
          dense
          flat
          outlined
          :items="items"
          class="dropdown-member select-class-tc"
          :value="
            $store.state.componentData[this.componentDataIndex].inputDict
              .commandType
          "
          @change="updateCommandAndStore"
        ></v-select>
      </v-col>
    </v-row>
    <template
      v-if="
        $store.state.componentData[this.componentDataIndex].inputDict
          .commandType == 'member'
      "
    >
      <MemberProfile :componentDataIndex="this.componentDataIndex" />
    </template>
    <template
      v-else-if="
        $store.state.componentData[this.componentDataIndex].inputDict
          .commandType == 'compare'
      "
    >
      <CompareCode :componentDataIndex="this.componentDataIndex" />
    </template>
  </div>
</template>

<style>
.dropdown-member,
.dropdown-member * {
  color: #fff !important;
}

.profile-main {
  margin-bottom: 120px;
}
.profile-main.profile-main-0 {
  margin-top: 200px !important;
}

.select-button:hover {
  cursor: pointer;
}
</style>

<script>
import CompareCodeVue from './functions/CompareCode.vue'
import MemberProfileVue from './functions/MemberProfile.vue'
import store from '../../store'

export default {
  props: {
    componentDataIndex: Number,
  },
  computed: {
    componentAData: function() {
      return store.getters.getComponentDataByIndex(this.componentDataIndex)
    },
  },
  data() {
    return {
      selectedCommand: '',
      items: [
        { text: 'Pick a command', value: 'pick', disabled: true },
        { text: 'Member Profile', value: 'member' },
        { text: 'Compare Codes', value: 'compare' },
      ],
      showTabs: window.innerWidth < 495 ? false : true,
    }
  },
  components: {
    CompareCode: CompareCodeVue,
    MemberProfile: MemberProfileVue,
  },
  mounted() {
    this.changeCommandTitle()
  },
  methods: {
    updateCommandAndStore(value) {
      this.selectedCommand = value
      let propertyNames = [
        'memberProfileConditions',
        'baseConditions',
        'newConditions',
        'commandType',
      ]

      propertyNames.forEach((name) => {
        let propertyValue = name != 'commandType' ? [] : this.selectedCommand
        store.dispatch('updateStateData', {
          index: this.componentDataIndex,
          data: { name: name, value: propertyValue },
        })
      })
    },
    changeCommandTitle() {
      if (this.componentDataIndex > 0) {
        this.$set(this.items[0], 'text', 'Pick another command')
      }
    },
  },
}
</script>
