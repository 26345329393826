<template>
  <v-layout>
    <v-select
      class="plateInput"
      dense
      flat
      outlined
      full-width
      :disabled="stringIncludesHHS"
      :items="items"
      :value="plateValue"
      :v-model="plateValue"
      color="#0099d9"
      style="color: rgb(84, 84, 84) !important;"
      @change="plateValueUpdate"
    ></v-select>
  </v-layout>
</template>

<script>
import plateData from '../../data/plateData.json'
import store from '../../store'

export default {
  props: {
    componentDataIndex: Number,
    plateValue: String,
  },

  data() {
    return {
      items: plateData,
    }
  },
  methods: {
    plateValueUpdate(value) {
      this.$emit('updatePlateValue', value)
    },
  },
  computed: {
    stringIncludesHHS() {
      // Check if the string includes 'hhs' in case of Plate field (case insensitive)
      return !store.state.componentData[0].inputDict.dosYearModel
        .toLowerCase()
        .includes('hhs')
    },
  },
}
</script>
