<template>
  <div>
    <div
      :class="[
        'header-main' +
          ' ' +
          (this.current_path == '/privacy'
            ? 'support header-change privacy'
            : this.current_path == '/eula'
            ? 'support eula header-change'
            : this.current_path.replace('/', '')) +
          ' ' +
          source,
      ]"
      :style="getImgUrl"
    >
      <div
        :class="[
          'header-main-block' +
            ' ' +
            this.current_path.replace('/', '') +
            ' ' +
            source,
        ]"
        :style="headerLogoStyle"
      >
        <a
          href="https://episource.com"
          :style="logoStyle"
          :class="['header-main-img ' + source]"
          v-if="this.source !== 'patch'"
        >
          <img
            v-if="this.source !== 'patch'"
            :class="['header-main-img ' + source]"
            src="../../assets/img/epi-logo.svg"
          />
        </a>
        <div :class="['header-main-block-title ' + source]">
          <a
            :href="current_path"
            :class="[
              'hexader-main-block-title ' +
                this.current_path.replace('/eula', 'eula'),
            ]"
          >
            {{ getImgUrl.hcc_text }}
          </a>
        </div>
      </div>

      <div
        v-if="this.source !== 'patch'"
        :class="['side-nav ' + this.current_path.replace('/', '')]"
      >
        <nav
          :class="[
            'header-main-block-link' + ' ' + this.current_path.replace('/', ''),
          ]"
          v-if="this.source !== 'patch'"
        >
          <a
            v-if="
              this.source !== 'patch' &&
                this.current_path != '/reference' &&
                this.current_path != '/reference/' &&
                reference_host != 'www.hccreference.com' &&
                reference_host != 'hccreference.com'
            "
            :href="
              reference_host == 'hccexplorer.com'
                ? 'https://www.hccreference.com/'
                : '/reference'
            "
            class="episource-home-link"
            target="_blank"
          >
            <p
              style="color: #545454;"
              @mouseover="showGif('show_reference_gif')"
              @mouseleave="hideGif('show_reference_gif')"
            >
              HCC Reference
            </p>
          </a>
          <img
            style="opacity: 0;"
            :class="[
              (show_reference_gif &&
              this.source !== 'patch' &&
              this.current_path != '/reference' &&
              reference_host != 'www.hccreference.com' &&
              reference_host != 'hccreference.com'
                ? 'tool-gif'
                : '') +
                ' gif reference-gif ' +
                this.current_path.replace('/', ''),
            ]"
            src="../../assets/img/hcc_reference.gif"
            alt="HCC Reference"
          />
          <a
            v-if="
              this.source !== 'patch' &&
                (this.current_path != '/' ||
                  reference_host == 'www.hccreference.com' ||
                  reference_host == 'hccreference.com')
            "
            :href="
              explorer_host == 'localhost'
                ? '/'
                : 'https://www.hccexplorer.com/'
            "
            class="episource-home-link"
            target="_blank"
          >
            <p
              style="color: #545454;"
              @mouseover="showGif('show_explorer_gif')"
              @mouseleave="hideGif('show_explorer_gif')"
            >
              HCC Explorer
            </p>
          </a>
          <img
            style="opacity: 0;"
            :class="[
              (show_explorer_gif &&
              this.source !== 'patch' &&
              (this.current_path != '/' ||
                reference_host == 'www.hccreference.com' ||
                reference_host == 'hccreference.com')
                ? 'tool-gif'
                : '') + ' gif explorer-gif',
            ]"
            src="../../assets/img/hcc_explorer.gif"
            alt="HCC Explorer"
          />
          <a
            v-if="this.source !== 'patch'"
            href="https://appsource.microsoft.com/en-in/product/office/WA200001947"
            class="episource-home-link"
            target="_blank"
          >
            <p
              style="color: #545454;"
              @mouseover="showGif('show_toolkit_gif')"
              @mouseleave="hideGif('show_toolkit_gif')"
              class="p-gif1"
            >
              RA Toolkit
            </p>
          </a>
          <img
            style="opacity: 0;"
            :class="[
              (show_toolkit_gif && this.source !== 'patch' ? 'tool-gif' : '') +
                ' gif ' +
                this.current_path.replace('/', ''),
            ]"
            src="../../assets/img/ra_toolkit.gif"
            alt="RA Toolkit"
          />
          <span
            v-if="this.current_path == '/support'"
            class="release-notes-title"
            @click="handleToggleModal()"
            >Release Notes</span
          >
        </nav>
      </div>
      <c-menu class="navbar-hamburger" v-if="this.source !== 'patch'">
        <c-menu-button
          px="4"
          py="2"
          transition="all 0.2s"
          rounded="md"
          border-width="1px"
          :_hover="{ bg: 'gray.100' }"
          :_expanded="{ bg: 'red.100' }"
          :_focus="{ outline: 0, boxShadow: 'outline' }"
          class="hamburger-button"
        >
          <v-app-bar-nav-icon></v-app-bar-nav-icon>
        </c-menu-button>
        <c-menu-list class="hamburger-list">
          <c-menu-item
            class="hamburger-item"
            v-bind:style="[
              this.source !== 'patch' && this.current_path != '/reference'
                ? { display: 'flex' }
                : { display: 'none' },
            ]"
          >
            <a
              v-if="
                this.source !== 'patch' && this.current_path != '/reference'
              "
              :href="
                reference_host == 'localhost'
                  ? '/reference'
                  : 'https://www.hccreference.com/'
              "
              class="episource-home-link"
              target="_blank"
            >
              <p style="color: #545454;">
                HCC Reference
              </p>
            </a>
          </c-menu-item>
          <c-menu-item
            class="hamburger-item"
            v-bind:style="[
              this.source !== 'patch'
                ? { display: 'flex' }
                : { display: 'none' },
            ]"
          >
            <a
              v-if="this.source !== 'patch'"
              href="https://appsource.microsoft.com/en-in/product/office/WA200001947"
              class="episource-home-link"
              target="_blank"
            >
              <p style="color: #545454;">
                RA Toolkit
              </p>
            </a>
          </c-menu-item>
          <c-menu-item
            class="hamburger-item"
            v-bind:style="[
              this.source !== 'patch' && this.current_path != '/'
                ? { display: 'flex' }
                : { display: 'none' },
            ]"
          >
            <a
              v-if="this.source !== 'patch' && this.current_path != '/'"
              :href="
                explorer_host == 'localhost'
                  ? '/'
                  : 'https://www.hccexplorer.com/'
              "
              class="episource-home-link"
              target="_blank"
            >
              <p style="color: #545454;">
                HCC Explorer
              </p>
            </a>
          </c-menu-item>
          <c-menu-item
            class="hamburger-item"
            v-bind:style="[
              this.current_path == '/support'
                ? { display: 'flex' }
                : { display: 'none' },
            ]"
          >
            <span
              v-if="this.current_path == '/support'"
              class="episource-home-link"
              @click="handleToggleModal()"
              >Release Notes</span
            >
          </c-menu-item>
        </c-menu-list>
      </c-menu>
    </div>
  </div>
</template>
<style src="../../assets/css/patchNotes.css"></style>
<style>
.side-nav.support {
  margin-right: 5%;
  vertical-align: center;
}
.side-nav {
  margin-right: 10%;
}
.side-nav > nav {
  display: flex;
  float: right;
}

.side-nav > nav > a {
  padding-left: 30px;
}

.header-main.patch {
  display: flex;
  height: auto;
  width: 100%;
}

.header-main-block.patch {
  justify-content: space-between;
  width: 100%;
}

.header-main-img.patch {
  margin-left: 0 !important;
}

.header-main-block-title.patch {
  /* margin-right: 30px; */
  padding: 0;
  margin-left: -5px;
}

.hexader-main-block-title.eula {
  font-size: 27px !important;
}

.gif {
  display: none !important;
  position: absolute;
  top: 60px;
  width: 400px;
  transition: 0.3s ease;
  right: 50px;
  box-shadow: rgba(0, 140, 80, 0.15) 0 5px 10px;
  border-radius: 7px;
  border: 1px solid rgba(0, 140, 80, 0.3);
  opacity: 0;
  z-index: -1000000200;
}

.tool-gif {
  opacity: 1 !important;
  display: inline !important;
}

.toolkit-gif.support {
  margin-left: 100px;
}

.reference-gif.support {
  display: block;
  right: 170px;
}
.navbar-hamburger {
  display: none !important;
}
.css-1xyqtup {
  display: none !important;
}
#menu-MUz {
  display: none;
}
.hamburger-item:focus {
  background-color: white !important;
}
.hamburger-button:focus {
  box-shadow: none !important;
}
.close-notif {
  position: absolute !important;
  top: 20% !important;
  right: 10px !important;
}
@media only screen and (max-device-width: 767px) {
  .css-1xyqtup {
    display: block !important;
    width: 50px;
    height: 50px;
  }
  .side-nav {
    display: none !important;
  }
  .header-main {
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
  }
  .hamburger-button {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important ;
    background-color: #f3f1ee !important;
    margin-right: 10px;
  }
  .hamburger-item {
    background-color: white !important;
    border-bottom: 2px solid #f3f1ee !important;
    padding: 10px;
  }
  .hamburger-list {
    padding: 0px !important;
  }
  .header-main-block-title {
    min-width: 200px !important;
  }
  .hexader-main-block-title {
    font-size: 27px !important;
  }
  .header-main-img {
    margin-left: 0% !important;
  }
  .hamburger-list {
    min-width: 10rem !important ;
  }

  .header-main.privacy {
    margin-top: 0px !important;
  }
  .header-main.eula {
    margin-top: 0px !important;
  }
  .search-menu {
    display: block !important;
  }
  .filter-field-container {
    padding: 5px !important;
  }
  .search-dos-choice {
    margin-left: 0px !important;
  }
  .hexader-main-block-title.eula {
    font-size: 27px !important;
  }
  .eula-container {
    width: 100%;
    margin-top: 50px !important ;
    margin-bottom: 40px;
  }
  .search-result-box {
    margin-left: 25px !important;
  }
  .search-menu {
    margin-left: 10px !important;
    width: 100% !important;
    padding-right: 22px !important;
  }
  .top-review-bar {
    align-items: start !important;
  }
  .selected_dx_box {
    position: static !important;
  }
  .search-box {
    display: block !important;
  }
  .selectedDxBox {
    margin-top: 0px !important;
    padding-bottom: 16px !important;
    padding-left: 16px !important;
    padding-right: 16px !important;
  }
  .search-result-box {
    min-height: 250px !important;
    margin-left: 16px !important;
  }
  .support-page-container {
    padding-top: 50px !important;
  }
  .compare-box {
    display: block !important;
    padding-left: 8% !important;
    padding-right: 8% !important;
    margin: 0% 5% 0% 5% !important;
  }
  .compare-code-calculation-year {
    margin-top: 14px !important;
  }
  .compare-code-dos-year {
    margin-top: 14px !important;
  }
  .header-main-img {
    width: 140px !important;
  }
  .header-main-block-title {
    margin-left: 0px !important;
    padding-right: 0px !important;
  }
  .hexader-main-block-title {
    font-size: 20px !important;
  }
  .hamburger-button {
    position: absolute !important;
    top: 18px !important;
    right: 0px !important;
    margin-right: 0px !important;
    padding: 0px !important;
  }
  .search-inner-box {
    width: 100% !important;
  }
  .header-main-block-title.patch > a {
    font-size: 20px !important;
  }
  .summary {
    display: block !important;
  }
  .summary-table-header {
    padding-bottom: 2.5% !important;
  }
  .loaderStyleRef {
    margin-left: 40% !important;
    margin-top: 40% !important;
  }
  .header-main-block.support {
    margin-left: 0px !important;
    width: 24%;
  }
  .heading-box {
    margin-left: 25px !important;
  }
  .each-topic-container {
    margin-left: 25px !important;
  }
  .confirm-selected-button {
    color: #fff;
    font-weight: 500;
    border: none;
    width: 150px;
    background-color: #008c50 !important;
    height: 48px !important;
  }
  .submitclear-member {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 9px;
  }
  .submitclear-member > button {
    margin: 0px !important;
    font-size: 12px !important;
    width: 75px !important;
  }
  .confirm-selected-button {
    width: 85px !important;
    font-size: 12px !important;
    height: 40px !important;
  }
}
@media only screen and (max-device-width: 1100px) {
  .patch-notes-model {
    max-width: 90% !important;
  }
  .patch-notes-title {
    font-size: 16px !important;
  }
  .patch-notes-points {
    font-size: 10px !important;
  }
  .patch-notes-date {
    font-size: 8px !important;
  }
  .rating-msg {
    font-size: 12px !important;
  }
  .dont-show-input {
    font-size: 8px !important;
  }
  .submitclear {
    margin-top: 10px !important;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .css-1xyqtup {
    display: block !important;
    width: 50px;
    height: 50px;
  }
  .hamburger-item {
    background-color: white !important;
    border-bottom: 2px solid #f3f1ee !important;
    padding: 10px;
  }

  .side-nav {
    display: none !important;
  }
  .hamburger-button {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important ;
    background-color: #f3f1ee !important;
    margin-right: 10px;
  }

  .header-main {
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
  }
  .header-main.privacy {
    margin-top: 0px !important;
  }
  .header-main.eula {
    margin-top: 0px !important;
  }
  .hexader-main-block-title.eula {
    font-size: 27px !important;
  }

  .eula-container {
    width: 100%;
    margin-top: 50px !important ;
    margin-bottom: 40px;
  }
  .hamburger-list {
    padding: 0px !important;
  }
  .hamburger-list {
    min-width: 10rem !important ;
  }
  .hamburger-item:focus {
    background-color: white !important;
  }
  .header-main-block.support {
    margin-left: 45px !important;
  }
  .compare-box {
    display: block !important;
    padding-left: 10% !important;
    padding-right: 10% !important;
    margin: 0% 5% 0% 5% !important;
  }
  .compare-code-calculation-year {
    margin-top: 14px !important;
  }
  .compare-code-dos-year {
    margin-top: 14px !important;
  }
  .hexader-main-block-title {
    font-size: 27px !important;
  }
  .header-main-block-title {
    width: 240px !important;
  }
}
</style>
<script>
export default {
  name: 'Header',
  props: {
    source: String,
  },
  data() {
    return {
      current_path: window.location.pathname,
      host_name: window.location.host,
      sec_domain: process.env.VUE_APP_SEC_DOMAIN,
      sec_domain_1: process.env.VUE_APP_SEC_DOMAIN_1,
      explorer_host: window.location.host,
      reference_host: window.location.host,
      show_explorer_gif: false,
      show_reference_gif: false,
      show_toolkit_gif: false,
      marginValue: this.$store.state.topMargin,
      window: {
        width: 0,
        height: 0,
      },
    }
  },
  computed: {
    headerLogoStyle() {
      if (
        ((this.current_path == '/' || this.current_path == '/reference') &&
          this.host_name != this.sec_domain &&
          this.host_name != this.sec_domain_1) ||
        this.source === 'patch'
      ) {
        return { backgroundColor: '#F3F1EE', marginLeft: '5%' }
      } else return {}
    },
    logoStyle() {
      if (
        ((this.current_path == '/' || this.current_path == '/reference') &&
          this.host_name != this.sec_domain &&
          this.host_name != this.sec_domain_1) ||
        this.source === 'patch'
      ) {
        return { backgroundColor: '#F3F1EE', display: 'flex', marginLeft: '5%' }
      } else if (this.current_path == '/support') {
        return { backgroundColor: '#F3F1EE', display: 'flex' }
      } else if (this.current_path == '/eula') {
        return {
          backgroundColor: '#F3F1EE',
          display: 'flex',
          marginLeft: '2.5%',
        }
      } else {
        return { backgroundColor: '#F3F1EE', display: 'flex', marginLeft: '5%' }
      }
    },
    getImgUrl() {
      if (
        (this.current_path == '/' ||
          this.current_path == '/reference' ||
          this.current_path == '/reference/') &&
        this.host_name != this.sec_domain &&
        this.host_name != this.sec_domain_1
      ) {
        return {
          hcc_text:
            window.location.host === 'hccreference.com' ||
            window.location.host === 'www.hccreference.com' ||
            window.location.href === 'http://localhost/reference' ||
            this.current_path == '/reference' ||
            this.current_path == '/reference/' ||
            window.location.href.includes('reference')
              ? 'HCC Reference'
              : 'HCC Explorer',
        }
      } else if (this.current_path == '/eula') {
        var eula_text =
          this.window.width < 1453 ? 'EULA' : 'END USER LICENSE AGREEMENT'
        return {
          hcc_text: eula_text,
          position: 'fixed',
          top: '0px',
          zIndex: '100',
          width: '100%',
        }
      } else if (this.current_path == '/privacy') {
        return {
          hcc_text: 'Privacy',
        }
      } else if (this.current_path == '/support') {
        if (this.source === 'patch') {
          return {
            hcc_text: 'Patch Notes',
            height: '250px',
          }
        }

        return {
          hcc_text: 'Support',
          // url: 'support.epicc@episource.com',
          marginTop: this.$store.state.topMargin,
          position: 'fixed',
          top: '0px',
          zIndex: '100',
          width: '100%',
        }
      } else {
        return {}
      }
    },
  },
  methods: {
    handleToggleModal() {
      this.$store.commit('togglePatchNotesModal')
    },
    showGif(value) {
      this[value] = true
    },
    hideGif(value) {
      this[value] = false
    },
    handleResize() {
      this.window.width = window.innerWidth
      this.window.height = window.innerHeight
    },
  },
  created() {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize)
  },
}
</script>
