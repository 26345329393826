<template>
  <div>
    <div
      class="compare-summary"
      v-if="Object.keys(compareCodeTableJson).length != 0"
      :id="'compare-result-table-' + componentDataIndex"
    >
      <!-- HCC column table starts here -->
      <div class="summary-table">
        <!-- calculation table -->
        <table class="summary-table-2">
          <tr>
            <th style="width:67.3%">Net Summary</th>
            <th style="width:15.5%">RAF</th>
            <th style="width:16.8%">Monthly Premium</th>
          </tr>
          <tr>
            <td class="left-alignment">
              Incremental Adds
              <v-icon
                color="black"
                class="p-1"
                size="12"
                dark
                v-tippy="{
                  theme: 'epicc',
                  arrow: true,
                  content:
                    'Additional RAF from adding New Conditions to Base Conditions (e.g. results of retrospective chart audit).',
                }"
                >fas fa-question-circle</v-icon
              >
            </td>
            <td class="right-alignment">
              {{ compareCodeTableCalcDict.incrementRaf }}
            </td>
            <td class="right-alignment">
              {{ compareCodeTableCalcDict.incrementPremium | toCurrency }}
            </td>
          </tr>
          <tr>
            <td class="left-alignment">
              HCC Gaps
              <v-icon
                color="black"
                class="p-1"
                size="12"
                dark
                v-tippy="{
                  theme: 'epicc',
                  arrow: true,
                  content:
                    'HCCs in Base Condition list missing or downgraded in New Condition List (e.g. Year-over-year suspect list)',
                }"
                >fas fa-question-circle</v-icon
              >
            </td>
            <td class="right-alignment">
              {{ compareCodeTableCalcDict.gapsRaf }}
            </td>
            <td class="right-alignment">
              {{ compareCodeTableCalcDict.gapsPremium | toCurrency }}
            </td>
          </tr>
          <tr>
            <td class="left-alignment">
              Total Difference
              <v-icon
                color="black"
                class="p-1"
                size="12"
                dark
                v-tippy="{
                  theme: 'epicc',
                  arrow: true,
                  content: 'Total RAF difference between the two lists',
                }"
                >fas fa-question-circle</v-icon
              >
            </td>
            <td class="right-alignment">
              {{ compareCodeTableCalcDict.diffRaf }}
            </td>
            <td class="right-alignment">
              {{ compareCodeTableCalcDict.diffPremium | toCurrency }}
            </td>
          </tr>
        </table>

        <table class="summary-table-1">
          <tr>
            <th style="width: 23.7%;">Condition Differences</th>
            <th v-if="compareCodeTableJson.flag == 'dx'" style="width: 22.6%">
              Dx
            </th>
            <th style="width: 23.3%">HCC</th>
            <th style="width: 14.02%">RAF</th>
            <th style="width: 15.4%">Monthly Premium</th>
          </tr>
          <!-- adds row starts here -->
          <tr
            v-if="
              Object.keys(compareCodeTableJson.Summary.adds.HCC).length == 0
            "
          >
            <td class="left-alignment">Adds</td>
            <td v-if="compareCodeTableJson.flag == 'dx'">--</td>
            <td>--</td>
            <td>--</td>
            <td>--</td>
          </tr>
          <tr v-else>
            <td class="left-alignment">Adds</td>
            <td v-if="compareCodeTableJson.flag == 'dx'">
              <tr>
                <div
                  class="nested-td"
                  v-for="(dxcode, adds_dxcode_index) in compareCodeTableJson
                    .Summary.adds.Dx"
                  :key="adds_dxcode_index"
                >
                  <p
                    v-if="
                      adds_dxcode_index !=
                        compareCodeTableJson.Summary.adds.Dx.length - 1
                    "
                  >
                    {{ dxcode + ',' }}
                  </p>
                  <p v-else>{{ dxcode }}</p>
                </div>
              </tr>
            </td>

            <td class="right-alignment">
              <tr>
                <div
                  class="nested-td"
                  v-for="(hcccode, adds_hcccode_index) in compareCodeTableJson
                    .Summary.adds.HCC"
                  :key="adds_hcccode_index"
                >
                  <p
                    v-if="
                      adds_hcccode_index !=
                        compareCodeTableJson.Summary.adds.HCC.length - 1
                    "
                  >
                    {{ hcccode + ',' }}
                  </p>
                  <p v-else>{{ hcccode }}</p>
                </div>
              </tr>
            </td>

            <td class="right-alignment">
              {{ compareCodeTableJson.Summary.adds.raf_change | roundOff }}
            </td>
            <td class="right-alignment">
              {{ compareCodeTableJson.Summary.adds.premium | toCurrency }}
            </td>
          </tr>
          <!-- adds row ends here -->
          <tr
            v-if="
              Object.keys(compareCodeTableJson.Summary.upgrades.HCC.From)
                .length == 0
            "
          >
            <td class="left-alignment">Upgrades</td>
            <td v-if="compareCodeTableJson.flag == 'dx'">--</td>
            <td>--</td>
            <td>--</td>
            <td>--</td>
          </tr>
          <tr v-else>
            <td class="left-alignment">Upgrades</td>
            <td v-if="compareCodeTableJson.flag == 'dx'">
              <tr>
                <td class="nested-td right-alignment">
                  <span
                    v-for="(from_dxcode,
                    upgrades_from_dxcode_index) in compareCodeTableJson.Summary
                      .upgrades.Dx.From"
                    :key="upgrades_from_dxcode_index"
                  >
                    <p
                      v-if="
                        upgrades_from_dxcode_index !=
                          compareCodeTableJson.Summary.upgrades.Dx.From.length -
                            1
                      "
                    >
                      {{ from_dxcode + ',' }}
                    </p>
                    <p v-else>{{ from_dxcode }}</p>
                  </span>
                </td>

                <td class="nested-td table-arrow">
                  <i class="fas fa-2x fa-long-arrow-alt-right"></i>
                </td>
                <td class="nested-td left-alignment">
                  <span
                    v-for="(to_dxcode,
                    to_dxcode_upgrades_index) in compareCodeTableJson.Summary
                      .upgrades.Dx.To"
                    :key="to_dxcode_upgrades_index"
                  >
                    <p
                      v-if="
                        to_dxcode_upgrades_index !=
                          compareCodeTableJson.Summary.upgrades.Dx.To.length - 1
                      "
                    >
                      {{ to_dxcode + ',' }}
                    </p>
                    <p v-else>{{ to_dxcode }}</p>
                  </span>
                </td>
              </tr>
            </td>

            <td>
              <tr>
                <td class="nested-td right-alignment">
                  <span
                    v-for="(from_hcc,
                    from_hcc_upgrades_index) in compareCodeTableJson.Summary
                      .upgrades.HCC.From"
                    :key="from_hcc_upgrades_index"
                  >
                    <p
                      v-if="
                        from_hcc_upgrades_index !=
                          compareCodeTableJson.Summary.upgrades.HCC.From
                            .length -
                            1
                      "
                    >
                      {{ from_hcc + ',' }}
                    </p>
                    <p v-else>{{ from_hcc }}</p>
                  </span>
                </td>
                <td class="nested-td table-arrow">
                  <i class="fas fa-2x fa-long-arrow-alt-right"></i>
                </td>
                <td class="nested-td left-alignment">
                  <span
                    v-for="(to_hcc,
                    to_hcc_upgrades_index) in compareCodeTableJson.Summary
                      .upgrades.HCC.To"
                    :key="to_hcc_upgrades_index"
                  >
                    <p
                      v-if="
                        to_hcc_upgrades_index !=
                          compareCodeTableJson.Summary.upgrades.HCC.To.length -
                            1
                      "
                    >
                      {{ to_hcc + ',' }}
                    </p>
                    <p v-else>{{ to_hcc }}</p>
                  </span>
                </td>
              </tr>
            </td>

            <td class="right-alignment">
              {{ compareCodeTableJson.Summary.upgrades.raf_change | roundOff }}
            </td>
            <td class="right-alignment">
              {{ compareCodeTableJson.Summary.upgrades.premium | toCurrency }}
            </td>
          </tr>
          <!-- upgrades ends -->
          <tr
            v-if="
              Object.keys(compareCodeTableJson.Summary.downgrades.HCC.From)
                .length == 0
            "
          >
            <td class="left-alignment">Downgrades</td>
            <td v-if="compareCodeTableJson.flag == 'dx'">--</td>
            <td>--</td>
            <td>--</td>
            <td>--</td>
          </tr>
          <tr v-else>
            <td class="left-alignment">Downgrades</td>
            <td v-if="compareCodeTableJson.flag == 'dx'">
              <tr>
                <td class="nested-td right-alignment">
                  <span
                    class="nested-td"
                    v-for="(from_dxcode,
                    from_dxcode_downgrades_index) in compareCodeTableJson
                      .Summary.downgrades.Dx.From"
                    :key="from_dxcode_downgrades_index"
                  >
                    <p
                      v-if="
                        from_dxcode_downgrades_index !=
                          compareCodeTableJson.Summary.downgrades.Dx.From
                            .length -
                            1
                      "
                    >
                      {{ from_dxcode + ',' }}
                    </p>
                    <p v-else>{{ from_dxcode }}</p>
                  </span>
                </td>

                <td class="nested-td table-arrow">
                  <i class="fas fa-2x fa-long-arrow-alt-right"></i>
                </td>

                <td class="nested-td left-alignment">
                  <span
                    v-for="(to_dxcode,
                    to_dxcode_downgrades_index) in compareCodeTableJson.Summary
                      .downgrades.Dx.To"
                    :key="to_dxcode_downgrades_index"
                  >
                    <p
                      v-if="
                        to_dxcode_downgrades_index !=
                          compareCodeTableJson.Summary.downgrades.Dx.To.length -
                            1
                      "
                    >
                      {{ to_dxcode + ',' }}
                    </p>
                    <p v-else>{{ to_dxcode }}</p>
                  </span>
                </td>
              </tr>
            </td>

            <td class="right-alignment">
              <tr>
                <td class="nested-td right-alignment">
                  <span
                    v-for="(from_hcc,
                    from_hcc_downgrades_index) in compareCodeTableJson.Summary
                      .downgrades.HCC.From"
                    :key="from_hcc_downgrades_index"
                  >
                    <p
                      v-if="
                        from_hcc_downgrades_index !=
                          compareCodeTableJson.Summary.downgrades.HCC.From
                            .length -
                            1
                      "
                    >
                      {{ from_hcc + ',' }}
                    </p>
                    <p v-else>{{ from_hcc }}</p>
                  </span>
                </td>

                <td class="nested-td table-arrow">
                  <i class="fas fa-2x fa-long-arrow-alt-right"></i>
                </td>

                <td class="nested-td right-alignment">
                  <span
                    v-for="(to_hcc,
                    to_hcc_downgrades_index) in compareCodeTableJson.Summary
                      .downgrades.HCC.To"
                    :key="to_hcc_downgrades_index"
                  >
                    <p
                      v-if="
                        to_hcc_downgrades_index !=
                          compareCodeTableJson.Summary.downgrades.HCC.To
                            .length -
                            1
                      "
                    >
                      {{ to_hcc + ',' }}
                    </p>
                    <p v-else>{{ to_hcc }}</p>
                  </span>
                </td>
              </tr>
            </td>

            <td class="right-alignment">
              {{
                compareCodeTableJson.Summary.downgrades.raf_change | roundOff
              }}
            </td>
            <td class="right-alignment">
              {{ compareCodeTableJson.Summary.downgrades.premium | toCurrency }}
            </td>
          </tr>
          <!-- dow ends-->
          <tr
            v-if="
              Object.keys(compareCodeTableJson.Summary.deletes.HCC).length == 0
            "
          >
            <td class="left-alignment">Deletes</td>
            <td v-if="compareCodeTableJson.flag == 'dx'">--</td>
            <td>--</td>
            <td>--</td>
            <td>--</td>
          </tr>
          <tr v-else>
            <td class="left-alignment">Deletes</td>
            <td v-if="compareCodeTableJson.flag == 'dx'">
              <div
                class="right-alignment nested-td float-left"
                v-for="(dxcode, dxcode_deletes_index) in compareCodeTableJson
                  .Summary.deletes.Dx"
                :key="dxcode_deletes_index"
              >
                <p
                  v-if="
                    dxcode_deletes_index !=
                      compareCodeTableJson.Summary.deletes.Dx.length - 1
                  "
                >
                  {{ dxcode + ', ' }}
                </p>
                <p v-else>{{ dxcode }}</p>
              </div>
            </td>

            <td>
              <div
                class="right-alignment nested-td float-left"
                v-for="(hcccode, hcccode_deletes_index) in compareCodeTableJson
                  .Summary.deletes.HCC"
                :key="hcccode_deletes_index"
              >
                <p
                  v-if="
                    hcccode_deletes_index !=
                      compareCodeTableJson.Summary.deletes.HCC.length - 1
                  "
                >
                  <span v-if="hcccode != null">{{ hcccode + ',' }}</span>
                  <span v-else>--</span>
                </p>
                <p v-else>
                  <span v-if="hcccode != null">{{ hcccode }}</span>
                  <span v-else>--</span>
                </p>
              </div>
            </td>

            <td class="right-alignment">
              {{ compareCodeTableJson.Summary.deletes.raf_change | roundOff }}
            </td>
            <td class="right-alignment">
              {{ compareCodeTableJson.Summary.deletes.premium | toCurrency }}
            </td>
          </tr>
          <!-- no change row starts -->
          <tr
            v-if="
              Object.keys(compareCodeTableJson.Summary.no_change).length == 0
            "
          >
            <td class="left-alignment">No Change</td>
            <td v-if="compareCodeTableJson.flag == 'dx'">--</td>
            <td>--</td>
            <td>--</td>
            <td>--</td>
          </tr>
          <tr v-else>
            <td class="left-alignment">No change</td>
            <td v-if="compareCodeTableJson.flag == 'dx'">
              <div v-if="compareCodeTableJson.Summary.no_change.Dx.length == 0">
                --
              </div>
              <div
                v-else
                class="left-alignment nested-td float-left"
                v-for="(no_change_dxcode,
                indexOf_no_change_dxcode) in compareCodeTableJson.Summary
                  .no_change.Dx"
                :key="indexOf_no_change_dxcode"
              >
                <p
                  v-if="
                    indexOf_no_change_dxcode !=
                      compareCodeTableJson.Summary.no_change.Dx.length - 1
                  "
                >
                  {{ no_change_dxcode + ', ' }}
                </p>
                <p v-else>{{ no_change_dxcode }}</p>
              </div>
            </td>

            <td v-if="compareCodeTableJson.Summary.no_change.HCC.length == 0">
              --
            </td>
            <td v-else>
              <div
                class="left-alignment nested-td float-left"
                v-for="(no_change_hcc_code,
                indexOf_no_change_hcc_code) in compareCodeTableJson.Summary
                  .no_change.HCC"
                :key="indexOf_no_change_hcc_code"
              >
                <p
                  v-if="
                    indexOf_no_change_hcc_code !=
                      compareCodeTableJson.Summary.no_change.HCC.length - 1
                  "
                >
                  {{ no_change_hcc_code + ',' }}
                </p>
                <p v-else>{{ no_change_hcc_code }}</p>
              </div>
            </td>

            <td
              class="right-alignment"
              v-if="compareCodeTableJson.Summary.no_change.raf_change != null"
            >
              {{ compareCodeTableJson.Summary.no_change.raf_change | roundOff }}
            </td>
            <td v-else>--</td>
            <td
              class="right-alignment"
              v-if="compareCodeTableJson.Summary.no_change.premium != null"
            >
              {{ compareCodeTableJson.Summary.no_change.premium | toCurrency }}
            </td>
            <td v-else>--</td>
          </tr>
        </table>
      </div>
      <div class="detail-box">
        <div class="detail-button">
          <v-btn
            color="grey"
            @click="
              ;(isDetailButtonVisible = false), (isCloseButtonVisible = true)
            "
            v-if="isDetailButtonVisible"
            >Detailed Output</v-btn
          >
          <v-btn
            color="grey"
            @click="
              ;(isDetailButtonVisible = true), (isCloseButtonVisible = false)
            "
            v-if="isCloseButtonVisible"
            >Close</v-btn
          >
        </div>
        <br />
        <!-- detailed table starts here -->
        <div class="detail-table">
          <table v-if="isCloseButtonVisible" class="summary-table-3">
            <tr>
              <th style="width:24.3%">Condition Differences</th>
              <th style="width:13.2%">Base Condition</th>
              <th style="width:21.7%">New Condition</th>
              <th style="width:17.6%">RAF Change</th>
              <th style="width:22.2%">Monthly Premium</th>
            </tr>

            <tr v-if="compareCodeTableJson.Detailed.adds.length != 0">
              <td class="left-alignment">Adds</td>
              <td class="left-alignment">
                <div
                  v-for="(baseAddCodes, baseAddIndex) in compareCodeTableJson
                    .Detailed.adds"
                  :key="baseAddIndex"
                >
                  <span v-if="baseAddCodes.new_condition.length > 1">
                    <p
                      v-for="len in baseAddCodes.new_condition.length - 1"
                      :key="len"
                      class="hidden-block"
                    >
                      --
                    </p>
                  </span>
                  <p
                    v-for="(baseConditions,
                    baseConIndex) in baseAddCodes.base_condition"
                    :key="baseConIndex"
                  >
                    {{ baseConditions }}
                  </p>
                  <span v-if="compareCodeTableJson.Detailed.adds.length >= 2">
                    <hr
                      v-if="baseConditions != null"
                      v-bind:style="[
                        baseAddIndex ==
                        compareCodeTableJson.Detailed.adds.length - 1
                          ? { display: 'none' }
                          : { display: 'block' },
                      ]"
                      class="hr-style-base"
                    />
                  </span>
                </div>
              </td>

              <td class="left-alignment">
                <div
                  v-for="(newAddCodes, newAddIndex) in compareCodeTableJson
                    .Detailed.adds"
                  :key="newAddIndex"
                >
                  <p
                    v-for="(newConditions,
                    newConIndex) in newAddCodes.new_condition"
                    :key="newConIndex"
                  >
                    {{ newConditions }}
                  </p>
                  <span v-if="compareCodeTableJson.Detailed.adds.length >= 2">
                    <hr
                      v-if="newAddCodes != null"
                      v-bind:style="[
                        newAddIndex ==
                        compareCodeTableJson.Detailed.adds.length - 1
                          ? { display: 'none' }
                          : { display: 'block' },
                      ]"
                      class="hr-style-new-condition"
                    />
                  </span>
                </div>
              </td>

              <td class="right-alignment">
                <div
                  v-for="(addCodeRaf, addCodeRafIndex) in compareCodeTableJson
                    .Detailed.adds"
                  :key="addCodeRafIndex"
                >
                  <span v-if="addCodeRaf.new_condition.length > 1">
                    <p
                      v-for="len in addCodeRaf.new_condition.length - 1"
                      :key="len"
                      class="hidden-block"
                    >
                      --
                    </p>
                  </span>
                  <p>{{ addCodeRaf.raf_change | roundOff }}</p>
                  <span v-if="compareCodeTableJson.Detailed.adds.length >= 2">
                    <hr
                      v-if="addCodeRaf != null"
                      v-bind:style="[
                        addCodeRafIndex ==
                        compareCodeTableJson.Detailed.adds.length - 1
                          ? { display: 'none' }
                          : { display: 'block' },
                      ]"
                      class="hr-style-raf-change"
                    />
                  </span>
                </div>
              </td>

              <td class="right-alignment">
                <div
                  v-for="(addCodePre, addCodePreIndex) in compareCodeTableJson
                    .Detailed.adds"
                  :key="addCodePreIndex"
                >
                  <span v-if="addCodePre.new_condition.length > 1">
                    <p
                      v-for="len in addCodePre.new_condition.length - 1"
                      :key="len"
                      class="hidden-block"
                    >
                      --
                    </p>
                  </span>
                  <p>{{ addCodePre.premium | toCurrency }}</p>
                  <span v-if="compareCodeTableJson.Detailed.adds.length >= 2">
                    <hr
                      v-if="addCodePre != null"
                      v-bind:style="[
                        addCodePreIndex ==
                        compareCodeTableJson.Detailed.adds.length - 1
                          ? { display: 'none' }
                          : { display: 'block' },
                      ]"
                      class="hr-style-pre-change"
                    />
                  </span>
                </div>
              </td>
            </tr>
            <tr v-if="compareCodeTableJson.Detailed.upgrades.length != 0">
              <td class="left-alignment">Upgrades</td>
              <td>
                <div
                  class="left-alignment"
                  v-for="(uBaseCodes, uBaseIndex) in compareCodeTableJson
                    .Detailed.upgrades"
                  :key="uBaseIndex"
                >
                  <span v-if="uBaseCodes.new_condition.length > 1">
                    <p
                      v-for="len in uBaseCodes.new_condition.length - 1"
                      :key="len"
                      class="hidden-block"
                    >
                      --
                    </p>
                  </span>
                  <p
                    v-for="(uBaseConditions,
                    uBaseConIndex) in uBaseCodes.base_condition"
                    :key="uBaseConIndex"
                  >
                    {{ uBaseConditions }}
                  </p>
                  <span
                    v-if="compareCodeTableJson.Detailed.upgrades.length >= 2"
                  >
                    <hr
                      v-if="uBaseCodes != null"
                      v-bind:style="[
                        uBaseIndex ==
                        compareCodeTableJson.Detailed.upgrades.length - 1
                          ? { display: 'none' }
                          : { display: 'block' },
                      ]"
                      class="hr-style-base"
                    />
                  </span>
                </div>
              </td>

              <td>
                <div
                  class="left-alignment"
                  v-for="(uNewCodes, uNewIndex) in compareCodeTableJson.Detailed
                    .upgrades"
                  :key="uNewIndex"
                >
                  <p
                    v-for="(uNewConditions,
                    uNewConIndex) in uNewCodes.new_condition"
                    :key="uNewConIndex"
                  >
                    {{ uNewConditions }}
                  </p>
                  <span
                    v-if="compareCodeTableJson.Detailed.upgrades.length >= 2"
                  >
                    <hr
                      v-if="uNewCodes != null"
                      v-bind:style="[
                        uNewIndex ==
                        compareCodeTableJson.Detailed.upgrades.length - 1
                          ? { display: 'none' }
                          : { display: 'block' },
                      ]"
                      class="hr-style-new-condition"
                    />
                  </span>
                </div>
              </td>

              <td>
                <div
                  class="right-alignment"
                  v-for="(upgradeCodeRaf,
                  upgradeCodeRafIndex) in compareCodeTableJson.Detailed
                    .upgrades"
                  :key="upgradeCodeRafIndex"
                >
                  <span v-if="upgradeCodeRaf.new_condition.length > 1">
                    <p
                      v-for="len in upgradeCodeRaf.new_condition.length - 1"
                      :key="len"
                      class="hidden-block"
                    >
                      --
                    </p>
                  </span>

                  <p>{{ upgradeCodeRaf.raf_change | roundOff }}</p>
                  <span
                    v-if="compareCodeTableJson.Detailed.upgrades.length >= 2"
                  >
                    <hr
                      v-if="upgradeCodeRaf != null"
                      v-bind:style="[
                        upgradeCodeRafIndex ==
                        compareCodeTableJson.Detailed.upgrades.length - 1
                          ? { display: 'none' }
                          : { display: 'block' },
                      ]"
                      class="hr-style-raf-change"
                    />
                  </span>
                </div>
              </td>

              <td>
                <div
                  class="right-alignment"
                  v-for="(upgradeCodePre,
                  upgradeCodePreIndex) in compareCodeTableJson.Detailed
                    .upgrades"
                  :key="upgradeCodePreIndex"
                >
                  <span v-if="upgradeCodePre.new_condition.length > 1">
                    <p
                      v-for="len in upgradeCodePre.new_condition.length - 1"
                      :key="len"
                      class="hidden-block"
                    >
                      --
                    </p>
                  </span>
                  <p>{{ upgradeCodePre.premium | toCurrency }}</p>
                  <span
                    v-if="compareCodeTableJson.Detailed.upgrades.length >= 2"
                  >
                    <hr
                      v-if="upgradeCodePre != null"
                      v-bind:style="[
                        upgradeCodePreIndex ==
                        compareCodeTableJson.Detailed.upgrades.length - 1
                          ? { display: 'none' }
                          : { display: 'block' },
                      ]"
                      class="hr-style-pre-change"
                    />
                  </span>
                </div>
              </td>
            </tr>
            <tr v-if="compareCodeTableJson.Detailed.downgrades.length != 0">
              <td class="left-alignment">Downgrades</td>
              <td>
                <div
                  class="left-alignment"
                  v-for="(dBaseCodes, dBaseIndex) in compareCodeTableJson
                    .Detailed.downgrades"
                  :key="dBaseIndex"
                >
                  <span v-if="dBaseCodes.new_condition.length > 1">
                    <p
                      v-for="len in dBaseCodes.new_condition.length - 1"
                      :key="len"
                      class="hidden-block"
                    >
                      --
                    </p>
                  </span>
                  <p
                    v-for="(dBaseConditions,
                    dBaseConIndex) in dBaseCodes.base_condition"
                    :key="dBaseConIndex"
                  >
                    {{ dBaseConditions }}
                  </p>
                  <span
                    v-if="compareCodeTableJson.Detailed.downgrades.length >= 2"
                  >
                    <hr
                      v-if="dBaseCodes != null"
                      v-bind:style="[
                        dBaseIndex ==
                        compareCodeTableJson.Detailed.downgrades.length - 1
                          ? { display: 'none' }
                          : { display: 'block' },
                      ]"
                      class="hr-style-base"
                    />
                  </span>
                </div>
              </td>

              <td>
                <div
                  class="left-alignment"
                  v-for="(dNewCodes, dNewIndex) in compareCodeTableJson.Detailed
                    .downgrades"
                  :key="dNewIndex"
                >
                  <p
                    v-for="(dNewConditions,
                    dNewConIndex) in dNewCodes.new_condition"
                    :key="dNewConIndex"
                  >
                    {{ dNewConditions }}
                  </p>
                  <span
                    v-if="compareCodeTableJson.Detailed.downgrades.length >= 2"
                  >
                    <hr
                      v-if="dNewCodes != null"
                      v-bind:style="[
                        dNewIndex ==
                        compareCodeTableJson.Detailed.downgrades.length - 1
                          ? { display: 'none' }
                          : { display: 'block' },
                      ]"
                      class="hr-style-new-condition"
                    />
                  </span>
                </div>
              </td>

              <td>
                <div
                  class="right-alignment"
                  v-for="(downCodeRaf, downCodeRafIndex) in compareCodeTableJson
                    .Detailed.downgrades"
                  :key="downCodeRafIndex"
                >
                  <span v-if="downCodeRaf.new_condition.length > 1">
                    <p
                      v-for="len in downCodeRaf.new_condition.length - 1"
                      :key="len"
                      class="hidden-block"
                    >
                      --
                    </p>
                  </span>
                  <p>{{ downCodeRaf.raf_change | roundOff }}</p>
                  <span
                    v-if="compareCodeTableJson.Detailed.downgrades.length >= 2"
                  >
                    <hr
                      v-if="downCodeRaf != null"
                      v-bind:style="[
                        downCodeRafIndex ==
                        compareCodeTableJson.Detailed.downgrades.length - 1
                          ? { display: 'none' }
                          : { display: 'block' },
                      ]"
                      class="hr-style-raf-change"
                    />
                  </span>
                </div>
              </td>

              <td>
                <div
                  class="right-alignment"
                  v-for="(downCodePre, downCodePreIndex) in compareCodeTableJson
                    .Detailed.downgrades"
                  :key="downCodePreIndex"
                >
                  <span v-if="downCodePre.new_condition.length > 1">
                    <p
                      v-for="len in downCodePre.new_condition.length - 1"
                      :key="len"
                      class="hidden-block"
                    >
                      --
                    </p>
                  </span>
                  <p>{{ downCodePre.premium | toCurrency }}</p>
                  <span
                    v-if="compareCodeTableJson.Detailed.downgrades.length >= 2"
                  >
                    <hr
                      v-if="downCodePre != null"
                      v-bind:style="[
                        downCodePreIndex ==
                        compareCodeTableJson.Detailed.downgrades.length - 1
                          ? { display: 'none' }
                          : { display: 'block' },
                      ]"
                      class="hr-style-pre-change"
                    />
                  </span>
                </div>
              </td>
            </tr>
            <tr v-if="compareCodeTableJson.Detailed.deletes.length != 0">
              <td class="left-alignment">Deletes</td>
              <td>
                <div
                  class="left-alignment"
                  v-for="(delBaseCodes, delBaseIndex) in compareCodeTableJson
                    .Detailed.deletes"
                  :key="delBaseIndex"
                >
                  <span v-if="delBaseCodes.new_condition.length > 1">
                    <p
                      v-for="len in delBaseCodes.new_condition.length - 1"
                      :key="len"
                      class="hidden-block"
                    >
                      --
                    </p>
                  </span>
                  <p
                    v-for="(delBaseConditions,
                    delBaseConIndex) in delBaseCodes.base_condition"
                    :key="delBaseConIndex"
                  >
                    {{ delBaseConditions }}
                  </p>
                  <span
                    v-if="compareCodeTableJson.Detailed.deletes.length >= 2"
                  >
                    <hr
                      v-if="delBaseCodes != null"
                      v-bind:style="[
                        delBaseIndex ==
                        compareCodeTableJson.Detailed.deletes.length - 1
                          ? { display: 'none' }
                          : { display: 'block' },
                      ]"
                      class="hr-style-base"
                    />
                  </span>
                </div>
              </td>

              <td>
                <div
                  class="left-alignment"
                  v-for="(delNewCodes, delNewIndex) in compareCodeTableJson
                    .Detailed.deletes"
                  :key="delNewIndex"
                >
                  <p
                    v-for="(delNewConditions,
                    delNewConIndex) in delNewCodes.new_condition"
                    :key="delNewConIndex"
                  >
                    {{ delNewConditions }}
                  </p>
                  <span
                    v-if="compareCodeTableJson.Detailed.deletes.length >= 2"
                  >
                    <hr
                      v-if="delNewConditions != null"
                      v-bind:style="[
                        delNewIndex ==
                        compareCodeTableJson.Detailed.deletes.length - 1
                          ? { display: 'none' }
                          : { display: 'block' },
                      ]"
                      class="hr-style-new-condition"
                    />
                  </span>
                </div>
              </td>

              <td>
                <div
                  class="right-alignment"
                  v-for="(deleteCodeRaf,
                  deleteCodeRafIndex) in compareCodeTableJson.Detailed.deletes"
                  :key="deleteCodeRafIndex"
                >
                  <span v-if="deleteCodeRaf.new_condition.length > 1">
                    <p
                      v-for="len in deleteCodeRaf.new_condition.length - 1"
                      :key="len"
                      class="hidden-block"
                    >
                      --
                    </p>
                  </span>
                  <p>{{ deleteCodeRaf.raf_change | roundOff }}</p>
                  <span
                    v-if="compareCodeTableJson.Detailed.deletes.length >= 2"
                  >
                    <hr
                      v-if="deleteCodeRaf != null"
                      v-bind:style="[
                        deleteCodeRafIndex ==
                        compareCodeTableJson.Detailed.deletes.length - 1
                          ? { display: 'none' }
                          : { display: 'block' },
                      ]"
                      class="hr-style-raf-change"
                    />
                  </span>
                </div>
              </td>

              <td>
                <div
                  class="right-alignment"
                  v-for="(deleteCodePre,
                  deleteCodePreIndex) in compareCodeTableJson.Detailed.deletes"
                  :key="deleteCodePreIndex"
                >
                  <span v-if="deleteCodePre.new_condition.length > 1">
                    <p
                      v-for="len in deleteCodePre.new_condition.length - 1"
                      :key="len"
                      class="hidden-block"
                    >
                      --
                    </p>
                  </span>
                  <p>{{ deleteCodePre.premium | toCurrency }}</p>
                  <span
                    v-if="compareCodeTableJson.Detailed.deletes.length >= 2"
                  >
                    <hr
                      v-if="deleteCodePre != null"
                      v-bind:style="[
                        deleteCodePreIndex ==
                        compareCodeTableJson.Detailed.deletes.length - 1
                          ? { display: 'none' }
                          : { display: 'block' },
                      ]"
                      class="hr-style-pre-change"
                    />
                  </span>
                </div>
              </td>
            </tr>
            <tr v-if="compareCodeTableJson.Detailed.no_change.length != 0">
              <td class="left-alignment">No Change</td>
              <td>
                <div
                  class="left-alignment"
                  v-for="(noChangeBase,
                  noChangeBaseIndex) in compareCodeTableJson.Detailed.no_change"
                  :key="noChangeBaseIndex"
                >
                  <span v-if="noChangeBase.new_condition.length > 1">
                    <p
                      v-for="len in noChangeBase.new_condition.length - 1"
                      :key="len"
                      class="hidden-block"
                    >
                      --
                    </p>
                  </span>
                  <p
                    v-for="(ncBaseConditions,
                    ncBaseConIndex) in noChangeBase.base_condition"
                    :key="ncBaseConIndex"
                  >
                    {{ ncBaseConditions }}
                  </p>
                  <span
                    v-if="compareCodeTableJson.Detailed.no_change.length >= 2"
                  >
                    <hr
                      v-if="noChangeBase != null"
                      v-bind:style="[
                        noChangeBaseIndex ==
                        compareCodeTableJson.Detailed.no_change.length - 1
                          ? { display: 'none' }
                          : { display: 'block' },
                      ]"
                      class="hr-style-base"
                    />
                  </span>
                </div>
              </td>

              <td>
                <div
                  class="left-alignment"
                  v-for="(noChangeNew, noChangeNewIndex) in compareCodeTableJson
                    .Detailed.no_change"
                  :key="noChangeNewIndex"
                >
                  <p
                    v-for="(ncNewConditions,
                    ncNewConIndex) in noChangeNew.new_condition"
                    :key="ncNewConIndex"
                  >
                    {{ ncNewConditions }}
                  </p>
                  <span
                    v-if="compareCodeTableJson.Detailed.no_change.length >= 2"
                  >
                    <hr
                      v-if="noChangeNew != null"
                      v-bind:style="[
                        noChangeNewIndex ==
                        compareCodeTableJson.Detailed.no_change.length - 1
                          ? { display: 'none' }
                          : { display: 'block' },
                      ]"
                      class="hr-style-new-condition"
                    />
                  </span>
                </div>
              </td>

              <td>
                <div
                  class="right-alignment"
                  v-for="(noChangeRaf, noChangeRafIndex) in compareCodeTableJson
                    .Detailed.no_change"
                  :key="noChangeRafIndex"
                >
                  <span v-if="noChangeRaf.new_condition.length > 1">
                    <p
                      v-for="len in noChangeRaf.new_condition.length - 1"
                      :key="len"
                      class="hidden-block"
                    >
                      --
                    </p>
                  </span>
                  <p>{{ noChangeRaf.raf_change | roundOff }}</p>
                  <span
                    v-if="compareCodeTableJson.Detailed.no_change.length >= 2"
                  >
                    <hr
                      v-if="noChangeRaf != null"
                      v-bind:style="[
                        noChangeRafIndex ==
                        compareCodeTableJson.Detailed.no_change.length - 1
                          ? { display: 'none' }
                          : { display: 'block' },
                      ]"
                      class="hr-style-raf-change"
                    />
                  </span>
                </div>
              </td>

              <td>
                <div
                  class="right-alignment"
                  v-for="(noChangePre, noChangePreIndex) in compareCodeTableJson
                    .Detailed.no_change"
                  :key="noChangePreIndex"
                >
                  <span v-if="noChangePre.new_condition.length > 1">
                    <p
                      v-for="len in noChangePre.new_condition.length - 1"
                      :key="len"
                      class="hidden-block"
                    >
                      --
                    </p>
                  </span>
                  <p>{{ noChangePre.premium | toCurrency }}</p>
                  <span
                    v-if="compareCodeTableJson.Detailed.no_change.length >= 2"
                  >
                    <hr
                      v-if="noChangePre != null"
                      v-bind:style="[
                        noChangePreIndex ==
                        compareCodeTableJson.Detailed.no_change.length - 1
                          ? { display: 'none' }
                          : { display: 'block' },
                      ]"
                      class="hr-style-pre-change"
                    />
                  </span>
                </div>
              </td>
            </tr>
            <tr>
              <td class="left-alignment">Total</td>
              <td colspan="2"></td>
              <td class="right-alignment">
                {{ compareCodeTableCalcDict.totalRafChange }}
              </td>
              <td class="right-alignment">
                {{ compareCodeTableCalcDict.totalPremium | toCurrency }}
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped src="../../../assets/css/comparetable.css"></style>
<script>
import store from '../../../store'
export default {
  props: {
    componentDataIndex: Number,
  },
  computed: {
    compareCodeTableJson: function() {
      return store.getters.getComponentDataByIndex(this.componentDataIndex)
        .responseData.data
    },
    compareCodeTableCalcDict: function() {
      return store.getters.getComponentDataByIndex(this.componentDataIndex)
        .compareCodecalculationDict
    },
  },
  data() {
    return {
      isDetailButtonVisible: true,
      isCloseButtonVisible: false,
    }
  },
}
</script>
