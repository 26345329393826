<template>
  <csvUpload />
</template>

<script>
import CSVUpload from '../common/CSVUpload.vue'

export default {
  components: {
    csvUpload: CSVUpload,
  },
  data() {
    return {
      file: '',
    }
  },
}
</script>
