import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/components/Views/Home.vue'
import HccReference from '@/components/Views/HccReferencePage.vue'
import PageNotFound from '@/components/Views/404.vue'
// import UnderConstruction from "@/components/Views/UnderConstruction.vue";
import Support from '@/components/Views/Support.vue'
import EULA from '@/components/Views/EULA.vue'
import Privacy from '@/components/Views/Privacy.vue'
import Upload from '@/components/Views/Upload.vue'
import BulkCalculation from '@/components/Views/BulkCalcutionPage.vue'

Vue.use(VueRouter)

const default_route = [
  {
    path: '/',
    component: Home,
  },
  {
    path: '/reference',
    component: HccReference,
  },
  {
    path: '/upload',
    component: Upload,
  },
  {
    path: '/upload/:id',
    component: BulkCalculation,
  },
  {
    path: '/privacy',
    component: Privacy,
  },
  {
    path: '/eula',
    component: EULA,
  },
  {
    path: '/support',
    component: Support,
  },
  {
    path: '*',
    component: PageNotFound,
  },
]

const sec_domain_route = [
  {
    path: '/',
    component: HccReference,
  },
  {
    path: '/privacy',
    component: Privacy,
  },
  {
    path: '/eula',
    component: EULA,
  },
  {
    path: '/support',
    component: Support,
  },
  {
    path: '*',
    component: PageNotFound,
  },
]

var routes = default_route

if (
  window.location.host == 'hccreference.com' ||
  window.location.host == 'www.hccreference.com'
) {
  routes = sec_domain_route
}

const router = new VueRouter({
  routes,
  mode: 'history',
})

export default router
