import { render, staticRenderFns } from "./Header.vue?vue&type=template&id=6c635efe"
import script from "./Header.vue?vue&type=script&lang=js"
export * from "./Header.vue?vue&type=script&lang=js"
import style0 from "../../assets/css/patchNotes.css?vue&type=style&index=0&prod&lang=css&external"
import style1 from "./Header.vue?vue&type=style&index=1&id=6c635efe&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CMenuButton: require('@chakra-ui/vue').CMenuButton, CMenuItem: require('@chakra-ui/vue').CMenuItem, CMenuList: require('@chakra-ui/vue').CMenuList, CMenu: require('@chakra-ui/vue').CMenu})
