<template>
  <div class="eula-container">
    <div class="eula-content">
      <div class="eula-container">
        <div class="row">
          <p class="head-content">
            THIS IS A LEGAL AGREEMENT BETWEEN YOU (“YOU” OR “CUSTOMER”) AND
            Episource, LLC, a California limited liability company having a
            principal place of business at 500 W 190th Street, Suite 400,
            Gardena, CA 90248 (“Company”) , STATING THE TERMS THAT GOVERN YOUR
            USE OF THE
            <b>Risk Adjustment Toolkit</b> (“SYSTEM”). PLEASE READ THIS
            AGREEMENT (“AGREEMENT”) BEFORE PRESSING THE "AGREE" BUTTON AND
            CHECKING THE BOX AT THE BOTTOM OF THIS PAGE. BY PRESSING "AGREE,"
            YOU ARE AGREEING TO BE BOUND BY THE TERMS OF THIS AGREEMENT. IF YOU
            DO NOT AGREE TO THE TERMS OF THIS AGREEMENT, PRESS "CANCEL" AND DO
            NOT PURCHASE, ORDER, DOWNLOAD, INSTALL OR USE THE SYSTEM.
          </p>
          <p class="head-content" style="margin-top:10px">
            This EULA permits you to use the System, which consists of the Excel
            Add-In (Risk Adjustment Toolkit) as described herein. This EULA must
            be acknowledged, agreed to and executed prior to the first use of
            the System and remains in effect thereafter for every use of the
            System. In addition to this EULA, our provision and your use of the
            System (including all web sites) is governed by the Business
            Associate Agreement (link) and the Privacy Policy (link). In the
            event of a conflict between this EULA and the Privacy Policy, the
            Privacy Policy shall prevail. In the event of a conflict between the
            EULA and/or the Privacy Policy and the Business Associate Agreement,
            the Business Associate Agreement shall prevail.
          </p>
          <p style="margin-top:10px">
            This Agreement applies to any updates or new versions of the System,
            unless the update includes separate terms.
          </p>
          <div style="margin-top:10px">
            <ol>
              <li>
                <u>Intellectual Property Rights:</u> All worldwide right, title,
                and interest in and to the System and the System’s underlying
                Intellectual Property Rights remain at all times with Company.
                “Intellectual Property Rights” means all United States and
                worldwide patents, trademarks, service marks, trade names, trade
                dress, logos, copyrights, artwork, characters, themes,
                backgrounds, rights of authorship, inventions, mask work rights,
                moral rights, patents, rights of inventorship, all applications,
                registrations and renewals in connection with any of the above,
                database rights, know-how, trade secrets, rights of publicity,
                privacy and/or defamation, rights under unfair competition and
                unfair trade practices laws, and all other intellectual and
                industrial property rights related thereto. All Intellectual
                Property Rights in the System, or any modifications thereto,
                shall be and remain sole and exclusive property of Company.
              </li>
              <li>
                <u>Restrictions: You may not</u>
                <ol type="a">
                  <li>
                    Reverse engineer, decompile, or disassemble any part of the
                    System;
                  </li>
                  <li>
                    Remove or obscure any copyright, trademark, or other
                    proprietary notices or labels that the System contains;
                  </li>
                  <li>
                    Copy, customize, modify, sublicense, rent, lease, lend,
                    sell, distribute, publicly perform, publicly display or
                    transfer any part of the System except as provided in this
                    Agreement, and you agree to prevent unauthorized copying of
                    the System;
                  </li>
                  <li>
                    Prepare or distribute any enhancements, improvements or
                    derivative works based upon the System;
                  </li>
                  <li>
                    Commercially exploit the System or any portion thereof or
                    transfer the System or this agreement to any third party;
                  </li>
                </ol>
              </li>
              <li>
                <u>Suspension or Termination:</u>
                Company may suspend or terminate your access to and use of the
                System and/or your Subscription at any time, immediately,
                automatically, and without warning, and at its sole discretion,
                for any of the following reasons:
                <ol type="a">
                  <li>
                    <u>EULA Violation:</u> Violation of any portion of the
                    current EULA;
                  </li>
                  <li>
                    <u>Repeated Misuse of Credentials:</u> If you or your
                    employees repeatedly misuse the Credentials; or
                  </li>
                  <li>
                    <u>FDA or Other Regulatory Demand:</u> If the FDA or other
                    regulatory body requests that use of the System be paused or
                    stopped.
                  </li>
                </ol>
              </li>
              Termination may include, but is not limited to, revocation of
              access to the System and termination of all related services. No
              compensation will be due to you in the event of termination.
              Company assumes no liability for costs incurred, data lost, or
              other expenses or damages that may result from termination.
              Company will not provide services to export data entered.
              Termination does not preclude Company from taking further legal
              action against you for intellectual property infringement or EULA
              violations.
              <li>
                <u>Technical Information:</u>Company has the right (but is not
                obligated) to electronically obtain system and machine
                information from your installation of the System or its
                applications on your computers, smartphones, or other devices at
                any time without notice to you. This information may include,
                but is not limited to, Internet location and routing
                information, meta-data regarding data transmission, archived
                deleted records, user names, and/or other technical information.
                Such information may be sent to Company by the System without
                you being notified. The information will not contain protected
                patient information. The information may be used for purposes
                including but not limited to EULA enforcement, monitoring
                program use, software development and technical support.
              </li>
              <li>
                <u>EULA Changes:</u> Company shall have the right to change
                and/or add to the EULA at any time and to change, discontinue or
                impose conditions on any function, feature and/or aspect of the
                System. This EULA shall be immediately and automatically revoked
                and replaced upon publication of a revised EULA on the Company’s
                website, whether or not you acknowledge receipt of the revised
                EULA.
              </li>
              <li>
                <u>No Warranties or Liabilities:</u> You expressly acknowledge
                and agree that the System runs on software that may contain
                errors or "bugs" that could cause unanticipated consequences
                through its use. You acknowledge and agree that use of the
                System is AT YOUR OWN RISK and that the System is provided "AS
                IS, AS AVAILABLE" without any warranties or conditions
                whatsoever. Company: (I) DISCLAIMS ANY EXPRESS OR IMPLIED
                WARRANTIES AS TO THE NONINFRINGEMENT, MERCHANTABILITY OR FITNESS
                FOR A PARTICULAR PURPOSE OF THE SYSTEM OR THAT THE OPERATION OF
                THE SYSTEM WILL BE UNINTERRUPTED OR ERROR-FREE; (II) SHALL IN NO
                EVENT BE LIABLE TO YOU OR ANYONE ELSE FOR LOSS, INJURY OR DAMAGE
                CAUSED IN WHOLE OR PART BY, OR FAILURES, DELAYS OR INTERRUPTIONS
                OF, THE SYSTEM; (III) SHALL IN NO EVENT BE LIABLE TO YOU OR
                ANYONE ELSE FOR ANY DIRECT, CONSEQUENTIAL, INCIDENTAL OR SPECIAL
                DAMAGES, INCLUDING BUT NOT LIMITED TO ANY LOST PROFITS OR
                TRADING LOSSES ARISING OUT OF OR RELATING TO THE USE OR
                INABILITY TO USE THE SYSTEM OR ANY DECISION MADE OR ACTION TAKEN
                BY YOU IN RELIANCE ON THE SYSTEM, EVEN IF ADVISED OF THE
                POSSIBILITY OF SUCH DAMAGES. THE ENTIRE RISK AS TO THE QUALITY
                AND PERFORMANCE OF THE SYSTEM IS WITH YOU AND SHOULD THE SYSTEM
                BE FOUND DEFECTIVE, YOU ASSUME THE ENTIRE COST OF SERVICING,
                REPAIR OR CORRECTION OF THE CONSEQUENCES OF SUCH DEFECTS. SOME
                JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF
                WARRANTIES OR INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE
                LIMITATIONS OR EXCLUSIONS MAY NOT APPLY TO YOU.
              </li>
              <li>
                <u>Indemnification.</u> You shall indemnify, and hold harmless
                Company and its officers, employees, agents, successors, and
                assigns (collectively, the “Indemnified Parties”), from any
                suits, losses, claims, demands, liabilities, costs and expenses
                (including attorney and accounting fees) that the Indemnified
                Parties may sustain or incur arising from (a) your violation of
                this Agreement or breach of any representation or warranty made
                by you hereunder; (b) your use of the System in any manner other
                than as intended, (c) patient care decisions, which at all times
                remain the responsibility of you and your staff; (d) your
                failure to comply with any applicable laws and regulations,
                including without limitation any copyright, property, or privacy
                right; or (e) your negligent act or omission, or willful
                misconduct.
              </li>
              <li>
                <u>Use Outside of North America:</u> Company makes no
                representation that the System is appropriate or available for
                use in locations outside of North America. If you use the System
                from outside of North America, you are solely responsible for
                compliance with all applicable laws, including without
                limitation data protection, export and import regulations of
                other countries.
              </li>
              <li>
                <u>Language; Translations.</u> In the event that the English
                language version of this Agreement is accompanied by any other
                version translated into any other language, such translated
                version is provided for convenience purposes only and the
                English language version shall control.
              </li>
              <li>
                <u>Authentication:</u> You agree to ensure that each person
                utilizing the System (you, other professionals, and staff
                employed by or working at your offices) under this EULA has been
                appropriately trained in use of the System. It is your sole
                responsibility to limit use and access to the System to trained
                professionals and staff.
              </li>
              <li>
                <u>Compliance with HIPAA and other Regulations:</u> This
                Agreement is governed by the Business Associate Agreement linked
                here (the “BAA”). For purposes of the BAA, you are the “Covered
                Entity” and Company is the “Business Associate.” In the event of
                a conflict between this Agreement and the BAA, the BAA shall
                prevail. You agree to obtain all necessary consents from your
                patients, including (a) their consent to have their data entered
                and stored in an encrypted database; (b) that their identity
                will not be disclosed to any third party; and (c) their data may
                be used for purposes unrelated to their care, including but not
                limited to research, scientific and clinical symposiums,
                meetings, publication(s), peer-to-peer consultation(s), and
                marketing.
              </li>
              <li>
                <u>Privacy, Generally.</u> Our gathering and use of information
                is detailed in our Privacy Policy, linked here.
              </li>
              <li>
                <u>Technical Support:</u> Technical Support provided by Company
                will be limited to technical aspects involved in the use of the
                System and related issues that arise, such as software licensing
                or guidance with regard to System errors. No information
                provided, whether generally or in the context of a specific
                record, shall be construed, inferred or implied by you to
                involve Company or its officers, shareholders, employees or
                agents in any aspect of the care of any patient. Company assumes
                no responsibility for patient care decisions, which at all times
                remain the responsibility of you and your staff.
              </li>
              <li>
                <u>No Other Services:</u> Nothing in this EULA shall be
                construed as obligating Company to provide services that extend
                beyond the provisions of this EULA.
              </li>
              <li>
                <u>Feedback:</u> You are not required to provide any feedback
                regarding the System. However, to the extent that you provide
                feedback to Company, and Company incorporates such feedback in
                the System, you hereby grant to Company a worldwide,
                non-exclusive, perpetual, irrevocable, royalty-free license,
                with the right to sublicense, to incorporate or otherwise
                utilize feedback as provided by you in the design of the System.
              </li>
              <li>
                <u>Relationship Between Parties:</u>
                <ol type="a">
                  <li>
                    Company, its officers, shareholders, independent
                    contractors, and agents, are independent business entities
                    from you and are not associated with business practices or
                    liabilities assumed by you or your affiliated business
                    entities in any way whatsoever.
                  </li>
                  <li>
                    Company and its officers, shareholders, independent
                    contractors, and agents assume no responsibility for any
                    federal, state, local, or other requirements to which the
                    you and your business affiliates may be bound to adhere.
                  </li>
                  <li>
                    This EULA does not constitute a joint venture between you,
                    your business affiliates and Company. This EULA does not
                    create a partnership, joint venture or other affiliation.
                    Neither party to this EULA has authority to commit the other
                    to any course of action not described in this EULA. Any
                    course of action, business decision, patent assertion,
                    copyright claim, response to a regulatory mandate, clinical
                    or regulatory oversight, contractual relationship, or other
                    venture undertaken by either party that is not explicitly
                    included in this agreement is undertaken at the sole
                    responsibility and liability of that party.
                  </li>
                </ol>
              </li>
              <li>
                <u>Reliance:</u> You acknowledge that Company relies on your
                agreement with this EULA for use of the System. You agree to be
                bound by all terms and conditions set forth in this EULA.
              </li>
              <li>
                <u>General:</u> This EULA constitutes the entire agreement
                between the you and Company with respect to the System and
                supersedes any other communication (including advertising). If
                any provision of this EULA is held unenforceable, that provision
                shall be enforced to the maximum extent permissible so as to
                give effect to the intent of this EULA, and the remainder of
                this EULA shall continue in full force and effect. This EULA
                shall be governed by the laws of the State of California, U.S.A.
                without reference to conflict of laws principles. Any claim,
                suit, action or proceeding hereunder must be brought in the
                appropriate state or federal courts located in the County of Los
                Angeles, California.
              </li>
              <li>
                <u>Government Restricted Rights:</u> If you are an agency,
                department, or other entity of the United States Government
                (“Government”), use, reproduction, release, modification, or
                disclosure of this product, or of any related documentation of
                any kind, including technical data, is restricted in accordance
                with Federal Acquisition Regulation (“FAR”) 12.212 for civilian
                agencies and Defense Federal Acquisition Regulation Supplement
                (“DFARS”) 227.7207 for military agencies. This product is
                commercial. The use of this product by any Government agency,
                department, or other entity of the Government is further
                restricted in accordance with the terms of this EULA, and any
                modification thereto. The Owner and Manufacturer is Company.
              </li>
              <li>
                <u>Processing:</u> You acknowledge that submission of this
                executed EULA to Company does not obligate Company to grant you
                use of the System, and that submission of the executed EULA does
                not constitute the entire enrollment process to use the System.
                You agree that all information submitted during the enrollment
                process is subject to verification by Company. Company is not
                bound by any timetable to process the EULA and makes no
                warrantees regarding the timeliness of enrollment procedures.
              </li>
              <li>
                <u>Signature:</u> I affirm that I have reviewed and agree to be
                bound by this EULA. By clicking "YES” and then “AGREE,” I affirm
                that I am a duly licensed medical professional and have the
                authority to bind myself and the medical practice listed below
                to all of the provisions of this EULA.
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Footer from '../common/MainFooter.vue'
export default {
  components: {
    Footer: Footer,
  },
}
</script>

<style scoped>
.container-fluid {
  width: 100%;
}
.heading-box {
  background: #008c50;
  font-size: 24px;
  font-weight: 400;
  color: white;
  padding: 1px 1px 1px 20px;
  text-align: center;
}
li {
  margin: 10px;
}
.eula-container {
  width: 100%;
  margin-top: 40px;
  margin-bottom: 40px;
}
.eula-content {
  width: 100%;
  padding: 3%;
}
.eula-container {
  height: auto;
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: column;
}
.head-content {
  width: 100%;
}
</style>
