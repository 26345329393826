export const scrollToBottom = (source, index) => {
  let dynamicId = `${source}-result-table-${index}`
  const div = document.getElementById(dynamicId)
  div.scrollIntoView({
    block: 'end',
    behavior: 'smooth',
  })
}

export const scrollToBottomContainer = (index) => {
  let dynamicClass = `profile-main-${index}`
  const div = document.getElementsByClassName(dynamicClass)[0]
  div.scrollIntoView({
    block: 'end',
    behavior: 'smooth',
  })
}

export const scrollToTop = () => {
  let dynamicClass = `app`
  const div = document.getElementById(dynamicClass)
  div.scrollIntoView({
    block: 'start',
    behavior: 'smooth',
  })
}
