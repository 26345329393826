<template>
  <div class="ocreComponent">
    <div class="orebox-2">
      <v-select
        :disabled="this.$store.state.orec_disabled"
        dense
        flat
        outlined
        :items="this.$store.state.orec_disabled ? null : items"
        :value="ore"
        @change="onChange"
        color="#0099d9"
        style="color: rgb(84, 84, 84) !important;"
      ></v-select>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    componentDataIndex: Number,
    ore: Number,
  },
  data() {
    return {
      items: [
        { text: 'Old Age', value: 0 },
        { text: 'Disability', value: 1 },
        { text: 'End Stage Renal Disease', value: 2 },
        { text: 'Both Disability and ESRD', value: 3 },
      ],
    }
  },
  methods: {
    onChange(value) {
      this.$emit('onOre', Number(value))
    },
  },
}
</script>
