<template>
  <div class="color-indicators">
    <div v-if="getAvailableTags()" class="text-left">
      <span class="dot-green"></span>
      <span class="label">Codes are valid in this DOS Year/Model</span>
    </div>
    <div v-if="hasNotFoundCodes()" class="text-left">
      <span class="dot-amber"></span>
      <span class="label"
        >Codes are not valid in this DOS Year/Model but are valid in another DOS
        Year/Model
      </span>
    </div>
    <div v-if="hasInvalidChips()" class="text-left">
      <span class="dot-red"></span>
      <span class="label">Codes are not valid in any DOS year/Model</span>
    </div>
  </div>
</template>

<style scoped src="../../assets/css/chipsIndicator.css"></style>

<script>
export default {
  props: {
    invalidChips: Array,
    tags: Array,
    notFoundCodes: Array,
  },
  data() {
    return {
      availableTags: [],
    }
  },
  methods: {
    getAvailableTags() {
      let newTagsList = []
      newTagsList =
        this.tags &&
        this.tags.filter(
          (tag) =>
            !this.invalidChips.includes(tag) &&
            !this.notFoundCodes.includes(tag)
        )
      if (newTagsList) {
        return newTagsList.length > 0
      }
      return
    },
    hasInvalidChips() {
      return this.invalidChips_.length > 0
    },
    hasNotFoundCodes() {
      return this.notFoundCodes_.length > 0 && this.tags && this.tags.length > 0
    },
  },
  computed: {
    invalidChips_() {
      return this.invalidChips
    },
    notFoundCodes_() {
      return this.notFoundCodes
    },
    hasTags() {
      return this.tags
    },
  },
}
</script>
