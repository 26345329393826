<template>
  <div class="dosyearmodel">
    <v-select
      class="dosyearmodel dymbox-2"
      dense
      flat
      outlined
      :items="items"
      :value="dosYearModel === 'v24_2022' ? 'v24_2022' : dosYearModel"
      color="#0099d9"
      style="color: #545454"
      @change="onChange"
    ></v-select>
  </div>
</template>

<script>
import dosYearData from '../../data/dosYearData.json'

export default {
  props: {
    componentDataIndex: Number,
    dosYearModel: String,
  },
  data() {
    return {
      items: dosYearData,
      mapping: '',
      // dosYearValue: this.dosYearModel,
    }
  },

  methods: {
    getDosValue(dosValue) {
      if (dosValue.indexOf('/') !== -1) {
        let start = dosValue.indexOf('/') + 1 // find first occurrence of / and treat it as a starting point
        let end = dosValue.length // get length of the dosValue and treat it as end point.

        let updatedDosValue = dosValue.slice(start, end)
        return updatedDosValue.trim() // return v22 or v23 or v24 respectively from the chosen value.
      }
      return dosValue
    },

    onChange(value) {
      this.$emit('onDosYear', this.getDosValue(value))
    },
  },
}
</script>
