<template>
  <v-app>
    <c-theme-provider>
      <div id="app">
        <headers />
        <router-view />
      </div>
    </c-theme-provider>
  </v-app>
</template>

<script>
import Header from './components/common/Header.vue'
export default {
  name: 'App',
  components: {
    headers: Header,
  },
}
</script>
