<template>
  <div>
    <div class="main pt-5 member-box">
      <div class="member-box-between">
        <div class="member-box-label condition-text">
          Conditions
          <v-icon
            color="black"
            class="p-1"
            size="12"
            dark
            v-tippy="{
              theme: 'epicc',
              arrow: true,
              content: tooltipDataForCondition,
            }"
            >fas fa-question-circle</v-icon
          >
        </div>
        <div class="box conditioninput">
          <div style="position: relative;">
            <codeinput
              ref="revalidateCodeInput"
              :tagList="memberProfileData.inputDict.memberProfileConditions"
              :tagstring="tag"
              searchBox="tag1"
              :componentDataIndex="componentDataIndex"
              :isClearTag="isClear"
              :riskModel="memberProfileData.inputDict.riskModel"
              :dosYearModel="memberProfileData.inputDict.dosYearModel"
              :isEmptyTagList="isEmptyTags"
              :commandType="memberProfileData.inputDict.commandType"
              v-on:getTag="updateTagList"
              v-on:removeTag="updateTagList"
              v-on:clearTagFlag="isClear = false"
              v-on:invalidChips="getInvalidChips"
              v-on:emptyTagsFlag="isEmptyTags = false"
            />
            <div
              v-if="this.$store.state.search_box == 'tag1'"
              class="member-search-suggestion"
              :class="[
                this.$store.state.search_member.length > 0 &&
                this.$store.state.search_box == 'tag1'
                  ? 'suggestion-box'
                  : '',
                this.$store.state.search_box,
                (this.$store.state.search_suggestion_loader &&
                  this.$store.state.search_box == 'tag1') == true
                  ? 'show-box'
                  : '',
              ]"
            >
              <v-progress-circular
                indeterminate
                color="red"
                size="32"
                class="member-search-loader"
                v-if="this.$store.state.search_suggestion_loader == true"
              ></v-progress-circular>
              <ul v-if="this.$store.state.search_box == 'tag1'">
                <li
                  v-for="item in this.$store.state.search_member"
                  :key="item"
                  @click="addTagFromList(item)"
                >
                  {{ item }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="member-box-between">
        <div class="member-box-label agelabel">
          Age:
          <v-icon
            color="black"
            class="p-1"
            size="12"
            dark
            v-tippy="{
              theme: 'epicc',
              arrow: true,
              content: tooltipDataForAge,
            }"
            >fas fa-question-circle</v-icon
          >
        </div>
        <div class="box ageinput">
          <ageField
            :age="memberProfileData.inputDict.age"
            :isAgeInvalid="isAgeInvalid"
            v-on:onAge="getAge"
            :disableAge="disableAge"
            v-on:validAge="isAgeInvalid = false"
          />
        </div>
        <div class="member-box-label member-box-sec-row">Gender:</div>
        <div class="box genderinput">
          <gender
            :gender="memberProfileData.inputDict.gender"
            v-on:onGender="getGender"
          />
        </div>
      </div>
      <div class="member-box-between member-box-mobile">
        <div class="member-box-label">DOS Year/Model:</div>
        <div class="box dRiskinput">
          <dosYearModel
            :dosYearModel="memberProfileData.inputDict.dosYearModel"
            v-on:onDosYear="getDosYear"
          />
        </div>
        <div class="member-box-label member-box-sec-row">
          Original reason for entitlement :
          <v-icon
            color="black"
            class="p-1"
            size="12"
            dark
            v-tippy="{
              theme: 'epicc',
              arrow: true,
              content: tooltipDataForOrec,
            }"
            >fas fa-question-circle</v-icon
          >
        </div>
        <div class="box oceinput">
          <oreComponent
            v-on:onOre="getOre"
            :ore="memberProfileData.inputDict.ore"
          />
        </div>
      </div>
      <div class="member-box-between risk-model-col">
        <div class="member-box-label">
          Risk Model:
          <v-icon
            color="black"
            class="p-1"
            size="12"
            dark
            v-tippy="{
              theme: 'epicc',
              arrow: true,
              content: tooltipDataForRiskModel,
            }"
            >fas fa-question-circle</v-icon
          >
        </div>
        <div class="box dosyearinput">
          <riskModelField
            :dosYear="memberProfileData.inputDict.dosYearModel"
            v-on:onRiskModel="getRiskModel"
            :riskModel="memberProfileData.inputDict.riskModel"
            :key="dosYear"
          />
        </div>
        <div class="member-box-label member-box-sec-row">
          Base Rate :
          <v-icon
            color="black"
            class="p-1"
            size="12"
            dark
            v-tippy="{
              theme: 'epicc',
              arrow: true,
              content: tooltipDataForBaserate,
            }"
            >fas fa-question-circle</v-icon
          >
        </div>
        <div class="box baserateinput">
          <baseRateInput
            :baseRate="memberProfileData.inputDict.baseRate"
            :isBaseRateInvalid="isBaseRateInvalid"
            v-on:onBaseRate="getBaseRate"
            v-on:validBaseRate="isBaseRateInvalid = false"
          />
        </div>
      </div>
      <div class="member-box-between">
        <div class="member-box-label agelabel">
          Date Of Birth:
          <v-icon
            color="black"
            class="p-1"
            size="12"
            dark
            v-tippy="{
              theme: 'epicc',
              arrow: true,
              content: tooltipDataForDob,
            }"
            >fas fa-question-circle</v-icon
          >
        </div>
        <div class="box calculationyear-input">
          <dobInput
            :calculationYear="memberProfileData.inputDict.calculationYear"
            :dateOfBirthValue="memberProfileData.inputDict.DOB"
            v-on:dateOfBirthUpdate="setDateOfBirth"
          />
        </div>
      </div>
      <div class="member-box-between">
        <div class="member-box-label agelabel">
          Plate:
          <v-icon
            color="black"
            class="p-1"
            size="12"
            dark
            v-tippy="{
              theme: 'epicc',
              arrow: true,
              content: tooltipDataForPlate,
            }"
            >fas fa-question-circle</v-icon
          >
        </div>
        <div class="box dosyearinput">
          <plateInput
            :plateValue="memberProfileData.inputDict.plate"
            v-on:updatePlateValue="setPlateValue"
          />
        </div>
      </div>

      <div class="member-box-between member-box-mobile">
        <div class="member-box-label agelabel">
          Calculation Year:
          <v-icon
            color="black"
            class="p-1"
            size="12"
            dark
            v-tippy="{
              theme: 'epicc',
              arrow: true,
              content: tooltipDataForCalculationYear,
            }"
            >fas fa-question-circle</v-icon
          >
        </div>
        <div class="box calculationyear-input">
          <calculationYearInput
            :error="dobErrorMessage"
            :dob="memberProfileData.inputDict.DOB"
            :calculationYear="memberProfileData.inputDict.calculationYear"
            v-on:calculationYearUpdate="setCalculationYear"
          />
        </div>
      </div>
      <div class="member-box-between risk-model-col">
        <div class="member-box-label platelabel">Enrollment Duration:</div>
        <div class="box enrollmentinput">
          <enrollmentInput
            :enrollmentDuration="memberProfileData.inputDict.enrollmentDuration"
            v-on:updateEnrollmentValue="setEnrollmentValue"
          />
        </div>
      </div>
      <div class="submitclear submitclear-member">
        <v-btn class="submit button" @click="submit">Submit</v-btn>
        <v-btn class="clear button" @click="clearResult">Clear</v-btn>
        <v-btn
          ref="addBtn"
          :class="[
            'add button ' +
              (componentDataIndex ==
                this.$store.state.componentData.length - 1 &&
              this.$store.state.componentData[componentDataIndex]
                .isSubmitButtonClicked
                ? 'showBtn'
                : ''),
          ]"
          @click="addRow"
          >Add new</v-btn
        >

        <v-btn
          v-if="componentDataIndex !== 0"
          class="close button"
          @click="deleteRow()"
        >
          <v-icon
            color="white"
            class="p-1"
            size="20"
            dark
            v-tippy="{
              theme: 'epicc',
              arrow: true,
              content: 'Close',
            }"
            >fas fa-trash</v-icon
          >
        </v-btn>
        <v-snackbar v-model="snackbar" class="snackbar">
          <div class="snackbar-text">
            {{ errorMessage }}
          </div>

          <v-btn
            class="snackbar-button"
            color="pink"
            text
            @click="snackbar = false"
            >Close</v-btn
          >
        </v-snackbar>
      </div>
      <div class="progress-bar">
        <v-progress-circular
          v-show="isWaitingForResult"
          color="light-blue"
          indeterminate
          rounded
          height="6"
        ></v-progress-circular>
      </div>
      <resultTable
        v-if="APIResponse.length != 0"
        :componentDataIndex="componentDataIndex"
      />
    </div>
  </div>
</template>
<style scoped src="../../../assets/css/member.css"></style>

<style>
.add {
  display: none;
}

.showBtn {
  display: inline;
  background-color: #00afe8 !important;
}
</style>

<script>
import AgeInput from '../../common/AgeInput.vue'
import RiskModel from '../../common/RiskModel.vue'
import DosYearModel from '../../common/DosYearModel.vue'
import Gender from '../../common/Gender.vue'
import OreComponent from '../../common/OreComponent.vue'
import BaseRateInput from '../../common/BaseRateInput.vue'
import store from '../../../store'
import MemberProfileResultTableVue from '../results/MemberProfileResultTable.vue'
import CodeInput from '../../common/CodeInput.vue'
import CalculationYearInput from '../../common/CalculationYearInput'
import DOBInput from '../../common/DOBInput'
import PlateInput from '../../common/PlateInput'
import EnrollmentInput from '../../common/EnrollmentInput'
import moment from 'moment'
import {
  scrollToBottom,
  scrollToBottomContainer,
} from '../../common/helperFunctions'
export default {
  props: {
    componentDataIndex: Number,
  },
  computed: {
    memberProfileData: function() {
      return store.getters.getComponentDataByIndex(this.componentDataIndex)
    },
    APIResponse: function() {
      return this.memberProfileData.responseData.data
    },
  },

  data() {
    return {
      tag: '',
      tagList: [],
      age: Number,
      calculationYear: Number,
      baseRate: Number,
      gender: String,
      ore: Number,
      riskModel: String,
      dosYear: String,
      plate: String,
      enrollmentDuration: String,
      DOB: String,
      tooltipDataForCondition:
        'Conditions eligible as per selected dos year model.',
      isClear: false,
      tooltipDataForBaserate: 'Base reimbursement premium for member',
      snackbar: false,
      tooltipDataForCalculationYear:
        '1st Jan of the input calculation year will be used along with DOB to calculate age',
      tooltipDataForRiskModel:
        'List of risk models eligible as per selected dos year model',
      tooltipDataForPlate:
        'Applicable only when the Commercial Model(s) are in use',
      tooltipDataForOrec:
        'Not applicable when the Commercial Model(s) are in use',
      tooltipDataForDob:
        'DOB will not be considered in any calculation(s) if age is provided',
      tooltipDataForAge: 'Acceptable age values are from 0 to 110',
      errorMessage: '',
      invalidChips: [],
      isEmptyTags: false,
      isAgeInvalid: false,
      isBaseRateInvalid: false,
      isCalculationYearInvalid: false,
      isWaitingForResult: false,
      HHS_DEFAULT_RISK_MODEL: 'ADULT',
      V05_DEFAULT_RISK_MODEL: 'D1',
      V_DEFAULT_RISK_MODEL: 'CN',
      V24_ESRD_DEFAULT_RISK_MODEL: 'C3',
      V21_ESRD_DEFAULT_RISK_MODEL: 'C1',
      PACE_V21_ESRD_DEFAULT_RISK_MODEL: 'PA',
      PACE_V_DEFAULT_RISK_MODEL: 'PK',
      PACE_V05_DEFAULT_RISK_MODEL: 'P7',
      disableAge: false,
      DOBMoment: String,
      dobErrorMessage: null,
    }
  },
  components: {
    codeinput: CodeInput,
    ageField: AgeInput,
    riskModelField: RiskModel,
    dosYearModel: DosYearModel,
    gender: Gender,
    oreComponent: OreComponent,
    baseRateInput: BaseRateInput,
    resultTable: MemberProfileResultTableVue,
    calculationYearInput: CalculationYearInput,
    dobInput: DOBInput,
    plateInput: PlateInput,
    enrollmentInput: EnrollmentInput,
  },
  methods: {
    updateTagList(newtagList) {
      this.tagList = newtagList
      store.dispatch('updateStateData', {
        index: this.componentDataIndex,
        data: { name: 'memberProfileConditions', value: this.tagList },
      })
    },
    getInvalidChips(chips) {
      this.invalidChips = chips
    },
    setFieldValue(value, fieldName) {
      let currentIndex = this.componentDataIndex
      let updatedData = {
        name: fieldName,
        value: value,
      }
      this.updateCommandInputValue(currentIndex, updatedData)
      let nextIndex = currentIndex + 1
      if (this.isNextPickCommand(nextIndex)) {
        this.updateCommandInputValue(nextIndex, updatedData)
      }
    },
    setEnrollmentValue(value) {
      this.enrollmentDuration = value
      this.setFieldValue(value, 'enrollmentDuration')
    },
    calculateAge(date, calculationYear) {
      let parseDate = new Date(date)
      let dobYear = parseDate.getFullYear() // get the year of date of birth
      let dobMonth = parseDate.getMonth() + 1 // get the month of DOB
      let dobDay = parseDate.getDate() // get the Date of DOB

      let currYear = new Date().getFullYear()
      let currMonth = new Date().getMonth() + 1
      let currDay = new Date().getDate()
      var dobDate = moment([dobYear, dobMonth, dobDay])
      /**
       * if difference between Dob and calculation year is 0
       * then substract DOB to current time
       * else substract DOB to 1st of january of the calculation year
       */
      var calculationYearDate = moment([calculationYear, '01', '01'])
      let diffYear = Math.abs(calculationYearDate.diff(dobDate, 'year', false))
      if (dobYear == calculationYear) {
        calculationYearDate = moment([currYear, currMonth, currDay])
      }
      let diffMonth = Math.abs(
        calculationYearDate.diff(dobDate, 'month', false)
      )

      /**
       * year greater than 1 should not have decimal values.
       * if year is equal to 0 then month should be returned having two decimal places.
       */
      if (diffYear > 0) {
        return Math.trunc(diffYear)
      } else if (diffMonth > 0) {
        return (diffMonth / 12).toFixed(2)
      } else return
    },
    setCalculatedAge(calculatedAge, calculationYear) {
      let yearInDob = new Date(this.DOBMoment).getFullYear()
      if (calculatedAge && yearInDob < calculationYear) {
        this.dobErrorMessage = null
        this.getAge(calculatedAge)
      } else if (yearInDob >= calculationYear) {
        this.dobErrorMessage =
          'Calculation year should be greater than DOB year.'
        this.getAge(0)
      }
    },
    setDateOfBirth(date, dateForMoment) {
      this.DOB = date
      this.DOBMoment = dateForMoment
      this.setFieldValue(date, 'DOB')
      let calculatedAge = this.calculateAge(
        dateForMoment,
        this.memberProfileData.inputDict.calculationYear
      )
      this.setCalculatedAge(
        calculatedAge,
        this.memberProfileData.inputDict.calculationYear
      )
    },
    setPlateValue(value) {
      this.plate = value
      this.setFieldValue(value, 'plate')
    },
    setCalculationYear(value) {
      this.calculationYear = value
      let calculatedAge = this.calculateAge(this.DOBMoment, value)
      this.setCalculatedAge(calculatedAge, value)
      this.setFieldValue(value, 'calculationYear')
    },
    getAge(value) {
      this.age = value
      this.setFieldValue(value, 'age')
    },
    getBaseRate(value) {
      this.baseRate = value
      let currentIndex = this.componentDataIndex
      let baseRateData = { name: 'baseRate', value: this.baseRate }
      this.updateCommandInputValue(currentIndex, baseRateData)
      let nextIndex = currentIndex + 1
      if (this.isNextPickCommand(nextIndex)) {
        this.updateCommandInputValue(nextIndex, baseRateData)
      }
    },
    getGender(value) {
      this.gender = value
      this.setFieldValue(value, 'gender')
    },
    setDefaultRiskModel(value) {
      var defaultRiskModelValue = ''
      if (store.getters.hasHhsData(value)) {
        defaultRiskModelValue = this.HHS_DEFAULT_RISK_MODEL
      } else if (store.getters.hasV05Data(value)) {
        defaultRiskModelValue = this.V05_DEFAULT_RISK_MODEL
      } else if (store.getters.hasv21ESRDData(value)) {
        defaultRiskModelValue = this.V21_ESRD_DEFAULT_RISK_MODEL
      } else if (store.getters.hasv24ESRDData(value)) {
        defaultRiskModelValue = this.V24_ESRD_DEFAULT_RISK_MODEL
      } else if (store.getters.hasPaceV21ESRDData(value)) {
        defaultRiskModelValue = this.PACE_V21_ESRD_DEFAULT_RISK_MODEL
      } else if (store.getters.hasPaceVData(value)) {
        defaultRiskModelValue = this.PACE_V_DEFAULT_RISK_MODEL
      } else if (store.getters.hasPaceV05Data(value)) {
        defaultRiskModelValue = this.PACE_V05_DEFAULT_RISK_MODEL
      } else {
        defaultRiskModelValue = this.V_DEFAULT_RISK_MODEL
      }
      this.getRiskModel(defaultRiskModelValue)
    },
    getDosYear(value) {
      this.dosYear = value
      this.setFieldValue(this.dosYear, 'dosYearModel')
      this.setDefaultRiskModel(this.dosYear)
      if (this.dosYear.startsWith('hhs')) {
        this.$store.state.orec_disabled = true
      } else {
        this.$store.state.orec_disabled = false
      }
      this.$refs.revalidateCodeInput.revalidateTags(this.dosYear)
    },
    getOre(value) {
      this.ore = value
      let currentIndex = this.componentDataIndex
      let oreData = { name: 'ore', value: this.ore }
      this.updateCommandInputValue(currentIndex, oreData)
      let nextIndex = currentIndex + 1
      if (this.isNextPickCommand(nextIndex)) {
        this.updateCommandInputValue(nextIndex, oreData)
      }
    },
    getRiskModel(value) {
      this.riskModel = value
      let currentIndex = this.componentDataIndex
      let riskModelData = { name: 'riskModel', value: this.riskModel }
      this.updateCommandInputValue(currentIndex, riskModelData)
      let nextIndex = currentIndex + 1
      if (this.isNextPickCommand(nextIndex)) {
        this.updateCommandInputValue(nextIndex, riskModelData)
      }
    },
    isNextPickCommand(nextIndex) {
      let nextComponentData = store.getters.getComponentDataByIndex(nextIndex)
      if (typeof nextComponentData != 'undefined') {
        return nextComponentData.inputDict.commandType == 'pick' ? true : false
      }
    },
    updateCommandInputValue(index, InputData) {
      store.dispatch('updateStateData', {
        index: index,
        data: InputData,
      })
    },
    isTagInvalid(tag) {
      return this.invalidChips.includes(tag) ? true : false
    },
    submit() {
      if (this.areAllTagsInvalid()) {
        this.errorMessage = 'All condition codes are invalid'
        this.snackbar = true
      } else if (!this.areInputsInvalid()) {
        this.isWaitingForResult = true
        store
          .dispatch('handleSubmitRequest', {
            index: this.componentDataIndex,
          })
          .then((this.isWaitingForResult = false))
      }
      setTimeout(() => scrollToBottom('member', this.componentDataIndex), 500)
    },
    addRow() {
      store.dispatch('addRowToComponentData', {
        index: this.componentDataIndex,
      })
      setTimeout(
        () => scrollToBottomContainer(this.componentDataIndex + 1),
        300
      )
    },
    areInputsInvalid() {
      // to validate whether coditions are empty
      // this.isEmptyTags = this.tagList.length == 0 ? true : false;
      this.isAgeInvalid = this.age < 0 || this.age > 110 ? true : false
      this.isBaseRateInvalid =
        this.baseRate < 0 || isNaN(this.baseRate) ? true : false
      return this.isAgeInvalid || this.isBaseRateInvalid ? true : false
    },
    areAllTagsInvalid() {
      return !this.areInputsInvalid() &&
        this.tagList.length != 0 &&
        !this.tagList.every(this.isTagInvalid)
        ? false
        : true
    },
    deleteRow() {
      store.dispatch('deleteRow', {
        index: this.componentDataIndex,
      })
    },
    clearResult() {
      this.isClear = true
      this.isEmptyTags = false
      this.isAgeInvalid = false
      this.isBaseRateInvalid = false
      this.tagList = []
      this.invalidChips = []
      this.isWaitingForResult = false
      store.dispatch('updateStateData', {
        index: this.componentDataIndex,
        data: { name: 'memberProfileConditions', value: this.tagList },
      })
      store.dispatch('clearResult', {
        index: this.componentDataIndex,
        commandType: 'member',
      })
      this.$refs.revalidateCodeInput.resetInvalidChips()
    },
    addTagFromList(item) {
      this.$root.$refs.CodeInput1.tag = item
      let keyevent = {
        key: 'Enter',
      }
      this.$root.$refs.CodeInput1.addTag(keyevent)
    },
  },
}
</script>

<style>
.snackbar > div > div {
  display: flex;
  align-content: center;
  justify-content: space-around;
}
.snackbar-text {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
